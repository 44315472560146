import * as React from 'react';
import '../css/Component/QuickAction.css';
import { useNavigate } from 'react-router-dom';
import apis from '../apis';
import { AuthProvider } from '../auth/AuthContext';

const countRequests = (requests, adminData) => {
  let count = 0;
  requests.forEach((request) => {
    if (request.verifiedBy === 'NA') {
      if (
        adminData?.role?.find((e) => {
          return e === 'Academic Admin' || e === 'DOAA';
        })
      ) {
        count++;
      }
    } else if (request.verifiedBy === 'academic') {
      if (
        adminData?.role?.find((e) => {
          return e === 'Finance Section';
        })
      ) {
        count++;
      }
    }
  });
  return count;
};

const QuickAction = () => {
  const navigate = useNavigate();

  function verificationPage() {
    navigate('/student/admin/verification');
  }
  const [requests, setRequests] = React.useState([]);
  const [totalRequests, setTotalRequests] = React.useState(0);
  const [show, setShow] = React.useState({
    paymentrecords: false,
    verification: false,
    refundrecords: false,
    upload: false,
    hostelmanagement: false,
    courseassign: false,
    verifyReviewStatus: false,
    promoteDemote: false,
  });
  const { adminData } = AuthProvider();

  React.useEffect(() => {
    if (adminData?.email === 'akprasad.cc@iiitbh.ac.in') {
      setShow((prev) => ({
        ...prev,
        paymentrecords: false,
        verification: false,
        refundrecords: false,
        upload: true,
        hostelmanagement: false,
        verifyReviewStatus: false,
        promoteDemote: false,
      }));
    } else if (
      adminData?.role?.find((e) => {
        return e === 'DOAA';
      })
    ) {
      setShow((prev) => ({
        ...prev,
        paymentrecords: true,
        verification: false,
        refundrecords: false,
        upload: true,
        hostelmanagement: true,
        courseassign: true,
        verifyReviewStatus: true,
        promoteDemote: true,
      }));
    } else if (
      adminData?.role?.find((e) => {
        return e === 'Finance Section';
      })
    ) {
      setShow((prev) => ({
        ...prev,
        paymentrecords: true,
        verification: true,
        refundrecords: true,
        upload: false,
        hostelmanagement: false,
        promoteDemote: false,
      }));
    } else if (
      adminData?.role?.find((e) => {
        return e === 'Academic Admin';
      }) ||
      adminData?.role?.find((e) => {
        return e === 'Academic Office';
      })
    ) {
      setShow((prev) => ({
        ...prev,
        paymentrecords: true,
        verification: true,
        refundrecords: true,
        upload: true,
        hostelmanagement: false,
        promoteDemote: false,
      }));
    } else if (
      adminData?.role?.find((e) => {
        return e === 'DOSA';
      })
    ) {
      setShow((prev) => ({
        ...prev,
        paymentrecords: false,
        verification: false,
        refundrecords: false,
        upload: false,
        hostelmanagement: true,
        promoteDemote: false,
      }));
    } else if (
      adminData?.role?.find((e) => {
        return e === 'HAB';
      })
    ) {
      setShow((prev) => ({
        ...prev,
        paymentrecords: false,
        verification: false,
        refundrecords: false,
        upload: false,
        hostelmanagement: true,
        promoteDemote: false,
      }));
    } else if (
      adminData?.role?.find((e) => {
        return e === 'Super Admin';
      })
    ) {
      setShow((prev) => ({
        ...prev,
        paymentrecords: true,
        verification: true,
        refundrecords: true,
        upload: true,
        hostelmanagement: true,
        courseassign: true,
        verifyReviewStatus: true,
        promoteDemote: true,
      }));
    } else if (
      adminData?.role?.find((e) => {
        return e === 'Warden';
      })
    ) {
      setShow((prev) => ({
        ...prev,
        paymentrecords: false,
        verification: false,
        refundrecords: false,
        upload: false,
        hostelmanagement: true,
        promoteDemote: false,
      }));
    }
    const fetchRequests = async () => {
      await apis
        .allReceipts(
          `${
            adminData?.role?.find((e) => {
              return e === 'Finance Section';
            })
              ? 'academic'
              : 'NA'
          }`,
          1,
          10
        )
        .then((data) => {
          const uniqueRequests = Array.from(new Set(data.data.map(r => r.id)))
        .map(id => data.data.find(r => r.id === id));
      
      setRequests(uniqueRequests);
      setTotalRequests(data.total);
        })
        .catch((err) => {
          alert(err);
        });
    };
    fetchRequests();
  }, [adminData]);

  function dataupload() {
    navigate('/student/admin/data-upload');
  }
  function OpenRefundDataPage() {
    navigate('/student/admin/refund-records');
  }
  function HostelManagement() {
    navigate('/student/admin/hostel');
  }
  function FacultyCourseManagement() {
    navigate('/student/admin/assign-course');
  }
  function ReviewVerification() {
    navigate('/student/admin/verify-review-status');
  }
  function paymentrecords() {
    navigate('/student/admin/paymentrecords');
  }
  function promoteDemotePage() {
    navigate('/student/admin/promote-students');
  }

  // const massReceiptGeneration = async () => {

  return (
    <>
      <div className="quick-action">
        <div className="title">
          <h2 className="">Top Actions: </h2>
        </div>
        <div className="files">
          {show.paymentrecords && (
            <div className="file" onClick={paymentrecords}>
              <div className="icon">
                <img src="/assets/fee-payment.svg" alt="img" />
              </div>
              <div className="filename">
                <h1>
                  Fee Payment
                  <br />
                  Records
                </h1>
              </div>
            </div>
          )}
          {show.verification && (
            <div className="file" onClick={verificationPage}>
              <div className="icon">
                <img src="/assets/verification.svg" alt="img" />
                <div className="notification">
                  <h2>{totalRequests}</h2>
                </div>
              </div>
              <div className="filename">
                <h1>
                  Payment
                  <br />
                  Verification
                </h1>
              </div>
            </div>
          )}
          {show.refundrecords && (
            <div className="file" onClick={OpenRefundDataPage}>
              <div className="icon">
                <img src="/assets/refund.svg" alt="img" />
              </div>
              <div className="filename">
                <h1>
                  Refund
                  <br />
                  Records
                </h1>
              </div>
            </div>
          )}
          {show.upload && (
            <div className="file" onClick={dataupload}>
              <div className="icon">
                <img src="/assets/upload-docs.svg" alt="img" />
              </div>
              <div className="filename">
                <h1>
                  Upload/Modify
                  <br />
                  Database
                </h1>
              </div>
            </div>
          )}
          {show.hostelmanagement && (
            <div className="file" onClick={HostelManagement}>
              <div className="icon">
                <img src="/assets/bulk-receipt.svg" alt="img" />
              </div>
              <div className="filename">
                <h1>
                  Hostel & Mess
                  <br />
                  Management
                </h1>
              </div>
            </div>
          )}
          {show.courseassign && (
            <div className="file" onClick={FacultyCourseManagement}>
              <div className="icon">
                <img src="/assets/CourseAssign.jpg" alt="img" />
              </div>
              <div className="filename">
                <h1>
                  Faculty Course
                  <br />
                  Assignment
                </h1>
              </div>
            </div>
          )}
          {show.verifyReviewStatus && (
            <div className="file" onClick={ReviewVerification}>
              <div className="icon">
                <img src="/assets/ReviewRating.jpeg" width={75} height={75} alt="img" />
              </div>
              <div className="filename">
                <h1>
                  Verify Review
                  <br />
                  Status
                </h1>
              </div>
            </div>
          )}
          {show.verifyReviewStatus && (
            <div className="file" onClick={promoteDemotePage}>
              <div className="icon">
                <img src="/assets/promote-demote.svg" width={75} height={75} alt="img" />
              </div>
              <div className="filename">
                <h1>
                  Promote/Demote
                  <br />
                  Students
                </h1>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default QuickAction;
