import * as React from 'react';
import AdminHeader from '../components/AdminHeader';
import '../css/Page/VerificationRequests.css';
import VerificationRequest from '../components/VerificationRequest';
import apis from '../apis';
import { AuthProvider } from '../auth/AuthContext';
import { useNavigate } from 'react-router-dom';

const countRequests = (requests, adminData) => {
  let count = 0;
  requests.forEach((request) => {
    if (request.verifiedBy === 'NA') {
      if (adminData?.role?.find((e) => e === 'Academic Admin' || e === 'DOAA')) {
        count++;
      }
    } else if (request.verifiedBy === 'academic') {
      if (adminData?.role?.find((e) => e === 'Finance Section')) {
        count++;
      }
    }
  });
  return count;
};

const VerificationRequests = () => {
  const [requests, setRequests] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(false);
  const [totalRequests, setTotalRequests] = React.useState(0);
  const limit = 10;

  const { adminData } = AuthProvider();
  const navigate = useNavigate();

  // const fetchRequests = async (newOffset) => {
  //   try {
  //     const data = await apis.allReceipts(
  //       `${adminData?.role?.find((e) => e === 'Finance Section') ? 'academic' : 'NA'}`,
  //       { limit, offset: newOffset }
  //     );
  //     setRequests((prevRequests) => [...prevRequests, ...data]);
  //     setOffset(newOffset + 1);
  //   } catch (err) {
  //     alert(err.message);
  //   }
  // };

  const fetchRequests = async (pageNum) => {
    setIsLoading(true);
    try {
      const verifierRole = adminData?.role?.find((e) => e === 'Finance Section')
        ? 'academic'
        : 'NA';
      const data = await apis.allReceipts(verifierRole, pageNum, limit);
      const uniqueRequests = Array.from(new Set(data.data.map((r) => r.id))).map((id) =>
        data.data.find((r) => r.id === id)
      );

      setRequests(uniqueRequests);
      setTotalPages(data.totalPages);
      setIsLoading(false);
      setTotalRequests(data.total);
    } catch (err) {
      alert(err.message);
      setIsLoading(false);
    }
  };

  const handleVerificationComplete = async (verifiedId) => {
    // Calculate the last page based on remaining items
    const remainingItems = totalRequests - 1;
    const newTotalPages = Math.ceil(remainingItems / limit);
    setTotalRequests(remainingItems);

    // Fetch additional item to maintain 10 items
    try {
      const verifierRole = adminData?.role?.find((e) => e === 'Finance Section')
        ? 'academic'
        : 'NA';
      const response = await apis.allReceipts(verifierRole, page, limit);

      setRequests((prev) => {
        // Remove verified request
        const filtered = prev.filter((req) => req.id !== verifiedId);

        // Add new unique request if available
        if (response.data.length > 0) {
          const newRequest = response.data[response.data.length - 1];
          if (!filtered.some((req) => req.id === newRequest.id)) {
            filtered.push(newRequest);
          }
        }

        // Ensure array has only unique items
        // const uniqueFiltered = Array.from(new Set(filtered.map(r => JSON.stringify(r))))
        //   .map(str => JSON.parse(str));

        return filtered;
      });

      setTotalPages(newTotalPages);
    } catch (err) {
      alert(err.message);
    }
  };

  React.useEffect(() => {
    if (
      adminData.id &&
      !adminData?.role?.find((e) => e === 'DOAA') &&
      !adminData?.role?.find((e) => e === 'Super Admin') &&
      !adminData?.role?.find((e) => e === 'Finance Section') &&
      !adminData?.role?.find((e) => e === 'Academic Admin')
    ) {
      alert('You are not authorized to access this page.');
      navigate('/student/admin');
    }

    fetchRequests(page);
  }, [adminData, page]);

  return (
    <>
      <AdminHeader />
      <div className="top-div">
        <div className="verification-requests">
          <div className="title">
            <h2>
              Verification Requests: <span>{totalRequests}</span>
            </h2>
          </div>

          {requests.map((request) => {
            return (
              <VerificationRequest
                key={request.id}
                request={request}
                onVerificationComplete={handleVerificationComplete}
              />
            );
          })}

          {!isLoading && requests.length === 0 && (
            <div className="no-requests">No pending requests</div>
          )}

          {totalPages > 1 && (
            <div className="pagination">
              <button disabled={page === 1} onClick={() => setPage((p) => p - 1)}>
                {`<<`}
              </button>
              <span>
                {page} of {totalPages}
              </span>
              <button disabled={page === totalPages} onClick={() => setPage((p) => p + 1)}>
                {`>>`}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default VerificationRequests;
