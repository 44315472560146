import React from 'react';
import '../css/Component/TermsPopUp.css';

const AdminUpdatePopUp = ({ open, onClose }) => {
  if (!open) return null;
  return (
    <>
      <div className="gateway-popup terms-popup">
        <div className="gateway-popup-title">
          <h2>Terms and Condition (Payments):</h2>
          <button onClick={onClose}>
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div className="gateway-popup-content DocAddPopup-content">
          <div className="terms-contents">
            <p>
              This online payment system is provided by IIITBH. IIITBH may update these terms from
              time to time and any changes will be effective immediately on being set out here.
              Please ensure you are aware of the current terms. The country of domicile for IIITBH
              is India.
            </p>
            <h4>Terms & Conditions</h4>
            <p>
              Please read these terms carefully before using the online payment facility. Using the
              online payment facility on this website indicates that you accept these terms. If you
              do not accept these terms do not use this facility.
            </p>
            <h4>All payments are subject to the following conditions:-</h4>
            <p>
              The description of services of match making are specific to your need, when you log in
              with your unique password. Normally payment is required in advance (i.e. before you
              commence your activity).
            </p>
            <ul>
              <h4>
                All Fees quoted are in Indian Rupees. The IIITBH reserves the right to change the
                fees at any time.
              </h4>
              <li>
                Your payment will normally reach the IIITBH account to which you are making a
                payment within two working days.
              </li>
              <li>
                We cannot accept liability for a payment not reaching the correct IIITBH account due
                to you quoting an incorrect account number or incorrect personal details. Neither
                can we accept liability if payment is refused or declined by the credit/debit card
                supplier for any reason.
              </li>
              <li>
                If the card supplier declines payment the IIITBH is under no obligation to bring
                this fact to your attention. You should check with your bank/credit/debit card
                supplier that payment has been deducted from your account.
              </li>
              <li>
                In no event will the IIITBH be liable for any damages whatsoever arising out of the
                use, inability to use, or the results of use of this site, any websites linked to
                this site, or the materials or information contained at any or all such sites,
                whether based on warranty, contract, tort or any other legal theory and whether or
                not advised of the possibility of such damages.
              </li>
            </ul>
            <h4>Refund Policy</h4>
            <ul>
              <li>
                If the Customer leaves the IIITBH before they complete their service period, there
                shall be no entitlement to a refund of paid service fees.
              </li>
              <li>
                Refunds, if applicable, at the discretion of the Management, will only be made to
                the debit/credit card used for the original transaction. For the avoidance of doubt
                nothing in this Policy shall require the IIITBH to refund the Fees (or part thereof)
                unless such Fees (or part thereof) have previously been paid.
              </li>
            </ul>
            <h4>Privacy Policy</h4>
            <p>
              This Privacy Policy applies to all of the products, services and websites offered by
              IIITBH. Sometimes, we may post product specific privacy notices or Help Centre
              materials to explain our products in more detail.
            </p>
            <p>
              If you have any questions about this Privacy Policy, please feel free to contact us
              through our website or write to us at student_portal@iiitbh.ac.in
            </p>
            <h4>Changes to our Privacy Policy</h4>
            <p>
              IIITBH reserves the entire right to modify/amend/remove this privacy statement anytime
              and without any reason. Nothing contained herein creates or is intended to create a
              contract/agreement between IIITBH and any user visiting the IIITBH website or
              providing identifying information of any kind.
            </p>
            <h4>DND Policy</h4>
            <p>
              If you wish to stop any further sms/email alerts/contacts from our side, all you need
              to do is to send an email:-student_portal@iiitbh.ac.in with your mobile numbers and
              you will be excluded from the alerts list.
            </p>
            <h4>Contact Details:</h4>
            <p>Email: student_portal@iiitbh.ac.in</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminUpdatePopUp;
