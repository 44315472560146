import React from 'react';
import '../css/Page/Documents.css';
import FormsDocs from '../components/FormsDocs';
import AdminHeader from '../components/AdminHeader';
import { useNavigate } from 'react-router-dom';
import apis from '../apis';
import { AuthProvider } from '../auth/AuthContext';

const DocsAdmin = () => {
  const { adminData } = AuthProvider();
  const navigate = useNavigate();
  const bufferToURL = (buffer) => {
    let byteArray = new Uint8Array(buffer);
    var blob = new Blob([byteArray], { type: 'application/pdf' });
    var url = URL.createObjectURL(blob);
    return url;
  };

  async function fetchData(type) {
    await apis
      .particularTypeFile(type)
      .then((data) => {
        if (data) {
          let url = data?.link ? data?.link : bufferToURL(data.files.data);
          window.open(url, '_blank');
        }
      })
      .catch((err) => {
        alert(err);
      });
  }

  function Bonafide() {
    navigate('/student/admin/documents-bonafide/');
  }

  return (
    <>
      <AdminHeader />
      <div className="top-div">
        <div className="latest">
          <div className="title">
            <h2>Top Documents: </h2>
          </div>
          <div className="files">
            <div className="file" onClick={() => fetchData('acadCal')}>
              <div className="icon">
                <img src="/assets/fee-structure.svg" alt="img" />
              </div>
              <div className="filename">
                <h1>
                  Academic
                  <br />
                  Calendar
                </h1>
              </div>
            </div>
            <div className="file" onClick={() => navigate('/student/admin/raise-complaint')}>
              <div className="icon">
                <img src="/assets/fee-structure.svg" alt="img" />
              </div>
              <div className="filename">
                <h1>
                  Raise
                  <br />
                  Complaints
                </h1>
              </div>
            </div>
            <div className="file" onClick={() => fetchData('feeStruct')}>
              <div className="icon">
                <img src="/assets/fee-structure.svg" alt="img" />
              </div>
              <div className="filename">
                <h1>
                  Institute
                  <br />
                  Fee structure
                </h1>
              </div>
            </div>
            {(adminData?.role?.find((e) => {
              return e === 'Academic Admin';
            }) ||
              adminData?.role?.find((e) => {
                return e === 'Super Admin';
              }) ||
              adminData?.role?.find((e) => {
                return e === "Student's Application Verifier";
              })) && (
              <div className="file" onClick={Bonafide}>
                <div className="icon">
                  <img src="/assets/fee-structure.svg" alt="img" />
                </div>
                <div className="filename">
                  <h1>
                    Student
                    <br />
                    Bonafide
                  </h1>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="bottom-div">
        <FormsDocs admin={true} />
      </div>
    </>
  );
};

export default DocsAdmin;
