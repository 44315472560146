import React from 'react';
import '../css/Component/GatewayPopUp.css';
import apis from '../apis';

const GatewayPopUp = ({ open, onClose, userData, types }) => {
  const [amount, setAmount] = React.useState(0);
  const ucoEPay = async () => {
    const data = {
      amount: amount,
      name: userData?.name,
      email: userData?.email,
      roll: userData?.applicationid ? userData?.applicationid : userData?.roll,
      contact: userData?.phone ? userData?.phone : userData?.contact,
      semester: userData?.semester ? userData?.semester : '1',
      type: types,
    };
    await apis
      .ucoEpayPortal(data)
      .then((result) => {
        window.location.href = result.paymentUrl;
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const sbiEPay = async () => {
    const data = {
      id: userData?.id,
      amount: amount,
      name: userData?.name,
      email: userData?.email,
      roll: userData?.applicationid ? userData?.applicationid : userData?.roll,
      contact: userData?.phone ? userData?.phone : userData?.contact,
      semester: userData?.semester ? userData?.semester : '1',
      type: types,
    };
    window.location.href = `https://admission.iiitbh.ac.in/api/gateway/sbi/?id=${data?.id}&amount=${data?.amount}&name=${data?.name}&email=${data?.email}&roll=${data?.roll}&contact=${data?.contact}&semester=${data?.semester}&type=${data?.type}`;
  };

  const canaraEpay = async () => {
    const data = {
      amount: amount,
      name: userData?.name,
      email: userData?.email,
      roll: userData?.applicationid ? userData?.applicationid : userData?.roll,
      contact: userData?.phone ? userData?.phone : userData?.contact,
      semester: userData?.semester ? userData?.semester : '1',
      type: types,
    };
    await apis
      .canaraEpayPortal(data)
      .then((result) => {
        window.location.href = result.url;
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  if (!open) return null;
  return (
    <>
      <div className="gateway-popup">
        <div className="gateway-popup-title">
          <h2>Amount & Gateway</h2>
          <button onClick={onClose}>
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div className="gateway-popup-content">
          <div className="amount-input-div">
            <div className="form-group popup-form-group">
              <label className="amount-label required">Enter Amount</label>
              <input
                type="number"
                min="1"
                max="1000000"
                step="500.00"
                name="payment_amount"
                id="payment_amount"
                placeholder="Enter Amount in INR"
                required
                onChange={(e) => setAmount(e.target.value)}
                value={amount}
              />
              <p>
                Enter amount (from 1 rupee to 2.5 lakh) you want to pay and choose a payment gateway
                from right side.
              </p>
            </div>
          </div>
          <div className="payment-method-options">
            {(types === 'institute' || types === 'due') && (
              <div className="payment-method" onClick={() => ucoEPay()}>
                <h3>
                  Get ePay: <span>by UCO Bank</span>
                </h3>
              </div>
            )}
            {types === 'mess' && (
              <div className="payment-method" onClick={() => canaraEpay()}>
                <h3>
                  Bill Desk: <span>by Canara Bank</span>
                </h3>
              </div>
            )}
            {types === 'hostel' && (
              <div className="payment-method" disabled onClick={() => sbiEPay()}>
                <h3>
                  SBI ePay: <span>by SBI Bank</span>
                </h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GatewayPopUp;
