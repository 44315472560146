import React from 'react';
import '../css/Component/FilterComponent.css';

const BonafiedFilters = ({ filterAttributes, handleFilterChange, resetFilters, setOpenFilter }) => {
  const typeOptions = [
    {
      name: 'Demand Letter',
      value: 'Demand',
    },
    {
      name: 'Bonafide',
      value: 'Bonafide',
    },
    {
      name: 'Fee Receipt',
      value: 'Receipt',
    },
  ];
  const sortByOptions = [
    {
      name: 'Most Recent',
      value: 'Most Recent',
    },
    {
      name: 'Oldest',
      value: 'Oldest',
    },
  ];

  return (
    <div className="filter-container">
      <div className="filter-heading9">
        <span>Filters:</span>{' '}
        <button className="generalFilterResetBtn" onClick={() => resetFilters()}>
          <span>Reset </span>
          <i className="fa-solid fa-refresh"></i>
        </button>
        <button className="generalFilterResetBtn" onClick={() => setOpenFilter(false)}>
          <i className="fa-solid fa-close"></i>
        </button>
      </div>
      <div className="filter-row">
        <div className="filter-group">
          <label>From:</label>
          <input
            type="date"
            name="startDate"
            value={filterAttributes.startDate}
            onChange={handleFilterChange}
          />
        </div>
        <div className="filter-group">
          <label>To:</label>
          <input
            type="date"
            name="endDate"
            value={filterAttributes.endDate}
            onChange={handleFilterChange}
          />
        </div>
      </div>

      <div className="filter-row">
        <div className="filter-group">
          <label>Status:</label>
          <select name="type" value={filterAttributes.type} onChange={handleFilterChange}>
            <option value="">Select Type</option>
            {typeOptions.map((type) => (
              <option key={type} value={type.value}>
                {type.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="filter-row">
        <div className="filter-group">
          <label>Roll starts with:</label>
          <input
            type="text"
            name="roll"
            value={filterAttributes.roll}
            onChange={handleFilterChange}
          />
        </div>
      </div>
      <div className="filter-row">
        <div className="filter-group">
          <label>Sort By:</label>
          <select name="sortBy" value={filterAttributes.sortBy} onChange={handleFilterChange}>
            {sortByOptions.map((option) => (
              <option key={option} value={option.value}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default BonafiedFilters;
