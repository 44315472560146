import React from 'react';
import { useParams } from 'react-router-dom';
import AdminHeader from '../components/AdminHeader';
import Apis from '../apis/apis';
import '../css/Page/HodViewPage.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CircularProgress from '@mui/material/CircularProgress';

const HodViewPage = () => {
  let { id } = useParams();
  const session = id.split('__')[0];
  const program = id.split('__')[1];
  const branch = id.split('__')[2];
  const semester = id.split('__')[3];

  const [courses, setCourses] = React.useState([]);
  const [studentData, setStudentData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const getHodViewFunc = async () => {
    const datas = {
      session,
      program,
      branch,
      semester,
    };

    setLoading(true);

    Promise.resolve(Apis.getHodView(datas))
      .then((data) => {
        setLoading(false);
        setCourses(data.courses);
        setStudentData(data.students);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  React.useEffect(() => {
    getHodViewFunc();
  }, [id]);

  return (
    <>
      <AdminHeader />
      <div className="hodMain">
        <div
          onClick={() => {
            window.history.back();
          }}
          className="backOption"
        >
          <ArrowBackIosIcon className="backArrow" fontSize="smaller" />
          <div className="">Back</div>
        </div>
        <div className="hodHeading">
          <p className="">
            {semester}
            {semester === '1' ? `st` : semester === '2' ? `nd` : semester === 3 ? `rd` : `th`}{' '}
            Semester Student Data
          </p>
          <button className="primary-btn">Forward</button>
        </div>
        <div className="table-container">
          <table className="hod-student-table">
            {!loading && courses.length > 0 && studentData.length > 0 ? (
              <>
                <thead className="hod-student-head">
                  <tr>
                    <th className="roll">Roll</th>
                    <th className="name">Name</th>
                    {courses.map((course, i) => {
                      return (
                        <th className="courses" key={i}>
                          {course.name} <br /> ({course.code})
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="hod-student-body">
                  {studentData.map((student, i) => {
                    return (
                      <tr key={i}>
                        <td className="roll">{student.roll}</td>
                        <td className="name">{student.name}</td>
                        {student.courses.map((course, j) => {
                          return (
                            <td className="courses" key={course.id}>
                              {course.total}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </>
            ) : (
              <div className="loadingContainer">
                <CircularProgress />
              </div>
            )}
          </table>
        </div>
      </div>
    </>
  );
};

export default HodViewPage;
