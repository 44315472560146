import FlipCountdown from '@rumess/react-flip-countdown';
import React, { useEffect, useState } from 'react';
import apis from '../apis';
import { AuthProvider } from '../auth/AuthContext';

function getSessionViaBatchAndSemester(batch, semester) {
  let year = parseInt(batch.slice(0, 4));
  let sessionYear = year + Math.floor((semester - 1) / 2);
  return sessionYear.toString() + '-' + (sessionYear + 1).toString().slice(2);
}

const MessApplication = ({ hostelStatus, payable }) => {
  const { userData } = AuthProvider();

  const [ws, setWs] = useState(null);
  const [timer, setTimer] = useState(null);
  const [type, setType] = useState('HOSTEL');
  const [messSwapData, setMessSwapData] = useState(null);
  const [choosenMess, setChoosenMess] = useState(hostelStatus?.mess);
  const [prefMess, setPrefMess] = useState('');
  const [lim1, setLim1] = useState(0);
  const [lim2, setLim2] = useState(0);
  const [loading, setLoading] = useState(false);

  const getMessSwapRequests = async (studentData) => {
    try {
      const paramsVal = {
        roll: studentData.roll,
        semester: studentData?.semester.toString(),
        session: getSessionViaBatchAndSemester(studentData.batch, studentData.semester),
      };

      const data = await apis.getSpecificMessSwapRequest(paramsVal);
      if (data.opted === false || data.currHostel === 'Not Allotted') {
        setTimer(null);
        setType('HOSTEL');
      }
      if (Object.keys(data).length > 0 && Object.keys(data).includes('id')) {
        setTimer(null);
        setType('HOSTEL');
      }

      if (timer) {
        if (
          (!timer.openFor.includes('All') && !timer.openFor.includes(userData.roll)) ||
          timer.closeFor.includes(userData.roll)
        ) {
          setTimer(null);
        }
      }

      setMessSwapData(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // const protocol = window.location.protocol === "https:" ? "wss://" : "ws://";
    // const hostname = window.location.hostname;
    // const port = process.env.WEBSOCKET_PORT || 9000;
    const url = `${process.env.REACT_APP_WEBSOCKET_URL}`;
    const socket = new WebSocket(url);
    setWs(socket);

    socket.onmessage = (event) => {
      console.log('Socket created');
      const data = JSON.parse(event.data);
      console.log('data from client side:- ', data);
      let schedularType = null;

      if (data !== null && (data.type === 'STOP_MESS_TIMER' || data.type === 'STOP_HOSTEL_TIMER')) {
        setTimer(null);
      }

      if (data !== null && data.data !== null) {
        schedularType = data.data?.type;
        setType(data.data?.type);
      }

      if (data === null) {
      }
      if (
        data.type === `${schedularType}_TIMER_UPDATE` ||
        data.type === `STOP_${schedularType}_TIMER` ||
        data.type === `SET_${schedularType}_TIMER` ||
        data.type === `UPDATE_${schedularType}_TIMER`
      ) {
        if (data.type === `STOP_${schedularType}_TIMER`) {
          setTimer(null);
        }

        console.log('Received data:', data.data);
        if (data.data) {
          if (data.data !== null) {
          }
        }

        setTimer(data.data);
        setLim1(data.data?.limit1);
        setLim2(data.data?.limit2);

        let timer = data.data;
        if (timer) {
          if (
            (!timer.openFor.includes('All') && !timer.openFor.includes(userData.roll)) ||
            timer.closeFor.includes(userData.roll)
          ) {
            setTimer(null);
          }
        }
      }
    };
  }, []);

  useEffect(() => {
    if (userData && Object.keys(userData).length > 0) {
      setChoosenMess(hostelStatus?.mess);
      getMessSwapRequests(userData);
    }
  }, [userData]);

  const applyMessSwap = async () => {
    setLoading(true);
    try {
      const data = {
        roll: userData.roll,
        email: userData.email,
        semester: userData.semester,
        requestedMess: choosenMess,
      };
      await apis.applyForMessSwap(data).then((res) => {
        alert(res);
        setLoading(false);
        getMessSwapRequests(userData);
        window.location.reload();
      });
    } catch (error) {
      console.error(error);
      alert(error);
      setLoading(false);
    }
  };

  async function applyMess() {
    if (payable > 0) {
      alert('Please clear your dues first');
      return;
    }
    if (prefMess === '') {
      alert('Please select preferred hostel');
      return;
    }
    await apis
      .applyForMess({
        mess: prefMess,
      })
      .then((result) => {
        alert('Mess application submitted successfully');
        setChoosenMess(prefMess);
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  return (
    <div className="upload-items">
      <div
        className="upload-title"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <h4>
          Mess Application:{' '}
          <span> To opt for mess facility at IIIT Bhagalpur for the next/upcoming semester</span>
        </h4>
        {timer && (
          <div className="" style={{ width: '100%', margin: '10px auto', flex: '1 1' }}>
            <FlipCountdown
              hideYear
              hideMonth={new Date(timer.endTime) - new Date() <= 30 * 24 * 60 * 60 * 1000}
              endAt={timer.endTime}
              onTimeUp={() => {
                alert("You can't apply for mess now");
              }}
              size={'small'}
              theme={'dark'}
            />
          </div>
        )}
      </div>

      <div className="hostelApplication">
        {type === 'HOSTEL' ? (
          timer ? (
            <div className="upload-buttons">
              <label htmlFor="prefMess">Preferred Mess Service:</label>
              <select
                id="prefMess"
                required
                value={prefMess}
                onChange={(e) => {
                  setPrefMess(e.target.value);
                }}
              >
                <option value="">Select Mess Service</option>
                {userData.gender === 'Male' ? (
                  <>
                    <option value="VSL_MESS">Sugandhim – Food & Catering (VCL)</option>
                    <option value="KBH_MESS">Sai Hospitality And Services (KBH/H1)</option>
                  </>
                ) : (
                  <>
                    <option value="VSL_MESS">Sugandhim – Food & Catering (VCL)</option>
                  </>
                )}
              </select>
              {timer ? (
                <button
                  id="submit"
                  type="submit"
                  disabled={hostelStatus?.status === 'Pending' && true}
                  onClick={() => applyMess()}
                >
                  Apply now
                </button>
              ) : (
                <button style={{ backgroundColor: 'red' }}>Time Out</button>
              )}
            </div>
          ) : hostelStatus?.status !== 'Not Applied' ? (
            <div className="upload-title">
              <h4 style={{ marginTop: '0px', color: '#5a6dff' }}>
                <span>
                  You have successfully completed mess application process, below is your status
                </span>
              </h4>
            </div>
          ) : (
            <div className="upload-buttons">
              <label htmlFor="prefMess">Preferred Mess Service:</label>
              <select id="prefMess" required disabled={true}>
                <option value="">Select Mess Service</option>
                {userData.gender === 'Male' ? (
                  <>
                    <option value="VSL_MESS">Sugandhim – Food & Catering (VCL)</option>
                    <option value="KBH_MESS">Sai Hospitality And Services (KBH/H1)</option>
                  </>
                ) : (
                  <>
                    <option value="VSL_MESS">Sugandhim – Food & Catering (VCL)</option>
                  </>
                )}
              </select>
              {timer ? (
                <button
                  id="submit"
                  type="submit"
                  disabled={hostelStatus?.status === 'Pending' && true}
                  // onClick={applyHostel}
                >
                  Apply now
                </button>
              ) : (
                <button style={{ backgroundColor: 'red' }}>Time Out</button>
              )}
            </div>
          )
        ) : (
          <div className="upload-buttons">
            <div className="">
              <label htmlFor="allotedMess">Alloted Mess: </label>
              <input
                type="text"
                id="allotedMess"
                name="allotedMess"
                value={
                  hostelStatus?.mess === 'VSL_MESS'
                    ? 'Sugandhim – Food & Catering (VCL)'
                    : 'Sai Hospitality And Services (KBH/H1)'
                }
                disabled={true}
                className="inputFieldOfAllotedMess"
              />
            </div>
            <div className="">
              <label htmlFor="swapMess">Swap Mess: </label>
              <select
                id="swapMess"
                required
                value={choosenMess}
                // disabled={!hostelApplication?.optHostel}
                onChange={(e) => {
                  setChoosenMess(e.target.value);
                }}
              >
                <option value="">Mess Swap Service</option>
                {userData.gender === 'Male' ? (
                  <>
                    {hostelStatus?.mess !== 'VSL_MESS' && (
                      <option value="VSL_MESS">Sugandhim – Food & Catering (VCL)</option>
                    )}
                    {hostelStatus?.mess !== 'KBH_MESS' && (
                      <option value="KBH_MESS">Sai Hospitality And Services (KBH/H1)</option>
                    )}
                  </>
                ) : (
                  <>
                    <option value="VSL_MESS">Sugandhim – Food & Catering (VCL)</option>
                  </>
                )}
              </select>
            </div>
            {timer ? (
              <button
                id="submit2"
                type="submit"
                disabled={loading ? true : false}
                // disabled={hostelStatus?.status === "Pending" && true}
                onClick={applyMessSwap}
                style={{
                  cursor: loading ? 'not-allowed' : 'pointer',
                }}
              >
                {loading ? 'Applying...' : 'Apply now'}
              </button>
            ) : (
              <button style={{ backgroundColor: 'red' }}>Time Out/Limit Reached</button>
            )}
          </div>
        )}
        <div id="hostel-status" className="request-details">
          <h4>
            Status: <span>{hostelStatus?.status}</span>
          </h4>
          <h4>
            Alloted Mess:{' '}
            <span>
              {hostelStatus?.mess === 'VSL_MESS'
                ? 'Sugandhim – Food & Catering (VCL)'
                : 'Sai Hospitality And Services (KBH/H1)'}
            </span>
          </h4>
          <h4>
            Requested Mess:{' '}
            {messSwapData ? (
              <span>{messSwapData?.requestedMess}</span>
            ) : (
              <span>
                {hostelStatus?.mess === 'VSL_MESS'
                  ? 'Sugandhim – Food & Catering (VCL)'
                  : 'Sai Hospitality And Services (KBH/H1)' || prefMess === 'VSL_MESS'
                  ? 'Sugandhim – Food & Catering (VCL)'
                  : 'Sai Hospitality And Services (KBH/H1)'}
              </span>
            )}
          </h4>
        </div>
      </div>
    </div>
  );
};

export default MessApplication;
