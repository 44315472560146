import React from 'react';
import '../css/Page/PasswordChange.css';
// import { Link } from "react-router-dom";
import { useForm } from 'react-hook-form';
import apis from '../apis';
import { useNavigate } from 'react-router-dom';

const ForgetPassword = () => {
  const { handleSubmit, register } = useForm();
  const navigate = useNavigate();

  const forgotPassword = async (data) => {
    if (data.newPassword !== data.confirmNewPassword) {
      alert("New Password and Confirm New Password doesn't match");
      return;
    }
    await apis
      .resetPassword(data)
      .then((res) => {
        navigate('/');
        alert('Password Changed Successfully');
      })
      .catch((err) => {
        alert(err.message);
      });
  };
  return (
    <>
      <div className="passwordChange">
        <div className="passwordChange-container">
          <div className="passwordChange-title">
            <h2>Forgot Password</h2>
          </div>
          <div className="passwordChange-content">
            <form onSubmit={handleSubmit(forgotPassword)}>
              <div className="form-group">
                <div className="form-group-element">
                  <h5>Email</h5>
                  <input type="email" required {...register('email', { required: true })} />
                </div>
                <div className="form-group-element">
                  <div className="form-group-element-title">
                    <h5>Email OTP:</h5>
                    {/* <Link to="#">Forgot Password?</Link> */}
                  </div>
                  <input type="password" required {...register('otp', { required: true })} />
                </div>
                <div className="form-group-element">
                  <h5>New Password</h5>
                  <input
                    type="password"
                    required
                    {...register('newPassword', { required: true })}
                  />
                </div>
                <div className="form-group-element">
                  <h5>Confirm New Password</h5>
                  <input
                    type="password"
                    required
                    {...register('confirmNewPassword', { required: true })}
                  />
                </div>
              </div>
              <button className="passwordChange-button primary" type="submit">
                Change Password
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
