import * as React from 'react';
import Header from '../components/Header';
import RegistrationSummary from '../components/RegistrationSummary';
import CourseRegistrationSection from '../components/CourseRegistrationSection';
import '../css/Page/Registration.css';
import { AuthProvider } from '../auth/AuthContext';
import apis from '../apis';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

const formatDate = (time) => {
  if (!time) return 'Not Available';
  return new Date(time).toLocaleDateString();
};

const progress = (cur, total) => {
  const percentage = cur / total;
  const parsed = Math.floor(percentage * 8);
  const done = '●';
  const left = '○';
  // console.log(parsed);
  // create a string of length 8 and fill done and left
  const str = done.repeat(parsed) + left.repeat(8 - parsed);
  return str;
};

function Registration() {
  const { userData } = AuthProvider();
  const navigate = useNavigate();
  const [phdData, setPhdData] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [showPage, setShowPage] = React.useState(false);

  const setShowPageFunc = async (roll, semester) => {
    if (roll && semester) {
      await apis.getOpenRegistrationStatus(roll, semester).then((data) => {
        setShowPage(data);
      });
    }
  };

  React.useEffect(() => {
    const fetchPhdData = async () => {
      setLoading(true);
      userData?.roll &&
        (await apis
          .getPHD(userData?.roll)
          .then((data) => {
            setLoading(false);
            setPhdData(data);
          })
          .catch((err) => {
            setLoading(false);
            alert(err.message);
          }));
    };
    fetchPhdData();
    if (userData) {
      setShowPageFunc(userData.roll, userData.semester);
    }
  }, [userData]);
  const [paymentData, setPaymentData] = React.useState({});

  React.useEffect(() => {
    const fetchPaymentData = async () => {
      setLoading(true);
      await apis
        .specificStudent({ roll: userData.roll, semester: userData?.semester })
        .then((data) => {
          setLoading(false);
          setPaymentData(data[0]);
        })
        .catch((err) => {
          setLoading(false);
          alert(err.message);
        });
    };
    fetchPaymentData();
  }, []);

  const registrationDone = async () => {
    if (paymentData?.paymentStatus !== 'Paid') {
      navigate('/student/feepayment');
      alert('Complete the payment Page');
      return;
    }
    await Promise.all([
      apis.submitCourses({
        roll: userData?.roll,
        semester: userData?.semester,
        batch: userData?.batch,
        program: userData?.course,
        branch: userData?.branch,
        faculty: phdData?.faculty,
      }),
      apis.updateStudent(userData.roll, { registrationStatus: 'Pending' }),
    ])
      .then((data) => {
        if (data) {
          window.location.reload();
          alert('Applied for Registration!!');
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  return (
    <>
      <Header />
      {loading ? (
        <div className="loadingContainer2">
          <CircularProgress size={60} thickness={5} />
        </div>
      ) : showPage ? (
        <>
          <div className="top-div">
            <RegistrationSummary />
          </div>
          {userData?.course !== 'Ph.D.' && (
            <div className="mid-div">
              <CourseRegistrationSection />
            </div>
          )}
          <div className="bottom-div">
            <div className="ExtraRegistration">
              <div className="paysection-div">
                <div className="paysection-title cv">
                  <h2>Advanced Registration</h2>
                  <h2>
                    For: <span> PhD Only</span>
                  </h2>
                </div>
                {userData?.course === 'Ph.D.' && (
                  <div className="content-div">
                    <div className="registration-steps">
                      <div className="step">
                        <div className="step-element">
                          <h3>1. Date of Admission</h3>
                        </div>
                        <div className="step-element">
                          {' '}
                          <h4>
                            Date: <span>{formatDate(phdData?.dateOfAdmission?.date)}</span>
                          </h4>
                        </div>
                        <div className="step-element">
                          {' '}
                          <h4>
                            Status:{' '}
                            <span>{phdData?.dateOfAdmission?.status ?? 'Not Available'}</span>
                          </h4>
                        </div>
                      </div>
                      <div className="step">
                        <div className="step-element">
                          <h3>2. last date of coursework examination</h3>
                        </div>
                        <div className="step-element">
                          {' '}
                          <h4>
                            Date: <span>{formatDate(phdData?.courseworkExam?.date)}</span>
                          </h4>
                        </div>
                        <div className="step-element">
                          {' '}
                          <h4>
                            Status:{' '}
                            <span>{phdData?.courseworkExam?.status ?? 'Not Available'}</span>
                          </h4>
                        </div>
                      </div>
                      <div className="step">
                        <div className="step-element">
                          <h3>3. Comprehensive Examination</h3>
                        </div>
                        <div className="step-element">
                          {' '}
                          <h4>
                            Date:{' '}
                            <span>{formatDate(phdData?.comprehensive?.date)} (Date Type)</span>
                          </h4>
                        </div>
                        <div className="step-element">
                          {' '}
                          <h4>
                            Status: <span>{phdData?.comprehensive?.status ?? 'Not Available'}</span>
                          </h4>
                        </div>
                        <div className="step-element">
                          {' '}
                          <h4>
                            Registration Status:{' '}
                            <span>
                              {phdData?.eligibleForRegistration?.option === 'comprehensive'
                                ? phdData?.eligibleForRegistration?.status === 'true'
                                  ? 'Eligible'
                                  : 'Not Eligible'
                                : 'Not Eligible'}
                            </span>
                          </h4>
                        </div>
                        {phdData?.eligibleForRegistration?.option === 'comprehensive' &&
                          phdData?.eligibleForRegistration?.status === 'true' && (
                            <div className="step-element">
                              {' '}
                              <button className="primary" onClick={registrationDone}>
                                Register
                              </button>
                            </div>
                          )}
                      </div>
                      <div className="step">
                        <div className="step-element">
                          <h3>4. State of the Art Seminar</h3>
                          <span>
                            {' '}
                            {phdData?.artSeminar?.progress}/8 :{' '}
                            {progress(phdData?.artSeminar?.progress, 8)}
                          </span>
                        </div>
                        <div className="step-element">
                          {' '}
                          <h4>
                            Date: <span>{formatDate(phdData?.artSeminar?.date)} (Date Type)</span>
                          </h4>
                        </div>
                        <div className="step-element">
                          {' '}
                          <h4>
                            Status: <span>{phdData?.artSeminar?.status ?? 'Not Available'}</span>
                          </h4>
                        </div>
                        <div className="step-element">
                          {' '}
                          <h4>
                            Registration Status:{' '}
                            <span>
                              {phdData?.eligibleForRegistration?.option === 'artSeminar'
                                ? phdData?.eligibleForRegistration?.status === 'true'
                                  ? 'Eligible'
                                  : 'Not Eligible'
                                : 'Not Eligible'}
                            </span>
                          </h4>
                        </div>
                        {phdData?.eligibleForRegistration?.option === 'artSeminar' &&
                          phdData?.eligibleForRegistration?.status === 'true' && (
                            <div className="step-element">
                              {' '}
                              <button className="primary" onClick={registrationDone}>
                                Register
                              </button>
                            </div>
                          )}
                      </div>
                      <div className="step">
                        <div className="step-element">
                          <h3>5. Progress Review Seminar</h3>
                          <span>
                            {phdData?.reviewSeminar?.progress}/8 :{' '}
                            {progress(phdData?.reviewSeminar?.progress, 8)}
                          </span>
                        </div>
                        <div className="step-element">
                          {' '}
                          <h4>
                            Registration Date:{' '}
                            <span>{formatDate(phdData?.reviewSeminar?.date)} (Date Type)</span>
                          </h4>
                        </div>
                        <div className="step-element">
                          {' '}
                          <h4>
                            Status: <span>{phdData?.reviewSeminar?.status ?? 'Not Available'}</span>
                          </h4>
                        </div>
                        <div className="step-element">
                          {' '}
                          <h4>
                            Registration Status:{' '}
                            <span>
                              {phdData?.eligibleForRegistration?.option === 'reviewSeminar'
                                ? phdData?.eligibleForRegistration?.status === 'true'
                                  ? 'Eligible'
                                  : 'Not Eligible'
                                : 'Not Eligible'}
                            </span>
                          </h4>
                        </div>
                        {phdData?.eligibleForRegistration?.option === 'reviewSeminar' &&
                          phdData?.eligibleForRegistration?.status === 'true' && (
                            <div className="step-element">
                              {' '}
                              <button className="primary" onClick={registrationDone}>
                                Register
                              </button>
                            </div>
                          )}
                      </div>
                      <div className="step">
                        <div className="step-element">
                          <h3>6. Synopsis Seminar</h3>
                        </div>
                        <div className="step-element">
                          {' '}
                          <h4>
                            Date:{' '}
                            <span>{formatDate(phdData?.synopsisSeminar?.date)} (Date Type)</span>
                          </h4>
                        </div>
                        <div className="step-element">
                          {' '}
                          <h4>
                            Status:{' '}
                            <span>{phdData?.synopsisSeminar?.status ?? 'Not Available'}</span>
                          </h4>
                        </div>
                        <div className="step-element">
                          {' '}
                          <h4>
                            Registration Status:{' '}
                            <span>
                              {phdData?.eligibleForRegistration?.option === 'synopsisSeminar'
                                ? phdData?.eligibleForRegistration?.status === 'true'
                                  ? 'Eligible'
                                  : 'Not Eligible'
                                : 'Not Eligible'}
                            </span>
                          </h4>
                        </div>
                        {phdData?.eligibleForRegistration?.option === 'synopsisSeminar' &&
                          phdData?.eligibleForRegistration?.status === 'true' && (
                            <div className="step-element">
                              {' '}
                              <button className="primary" onClick={registrationDone}>
                                Register
                              </button>
                            </div>
                          )}
                      </div>
                      <div className="step">
                        <div className="step-element">
                          <h3>7. Thesis Submission</h3>
                        </div>
                        <div className="step-element">
                          {' '}
                          <h4>
                            Date: <span>{formatDate(phdData?.thesisSubmission?.date)}</span>
                          </h4>
                        </div>
                        <div className="step-element">
                          {' '}
                          <h4>
                            Status:{' '}
                            <span>{phdData?.thesisSubmission?.status ?? 'Not Available'}</span>
                          </h4>
                        </div>
                      </div>
                      <div className="step">
                        <div className="step-element">
                          <h3>7. Date of Viva Voce</h3>
                        </div>
                        {console.log({ phdData })}
                        <div className="step-element">
                          {' '}
                          <h4>
                            Date: <span>{formatDate(phdData?.vivaVoce?.date)}</span>
                          </h4>
                        </div>
                        <div className="step-element">
                          {' '}
                          <h4>
                            Status: <span>{phdData?.vivaVoce?.status ?? 'Not Available'}</span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div
          className="loadingContainer2"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90vh',
            marginTop: '80px',
          }}
        >
          <h1>Registration is not open yet</h1>
        </div>
      )}
    </>
  );
}

export default Registration;
