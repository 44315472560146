import React from 'react';
import apis from '../apis';
import { AuthProvider } from '../auth/AuthContext';

const UploadSeatAllotmentLetter = ({
  open,
  onClose,
  canAllotSeat,
  applicationid,
  newStudent,
  documentVerificationStatus,
}) => {
  const { adminData } = AuthProvider();
  const [seatAllotmentLetter, setSeatAllotmentLetter] = React.useState(null);

  const [isAllVerified, setIsAllVerified] = React.useState(false);

  React.useEffect(() => {
    if (newStudent) {
      const checkStep2Verified =
        documentVerificationStatus.photo.status === 'Verified' &&
        documentVerificationStatus.signature.status === 'Verified' &&
        documentVerificationStatus.allotmentletter.status === 'Verified' &&
        documentVerificationStatus.aadhar.status === 'Verified' &&
        documentVerificationStatus.tenth.status === 'Verified' &&
        documentVerificationStatus.twelfth.status === 'Verified';
      const checkStep3Verified =
        documentVerificationStatus.medicalCertificate.status === 'Verified' &&
        documentVerificationStatus.provisionalDocVerificationLetter.status === 'Verified' &&
        documentVerificationStatus.application.status === 'Verified';
      setIsAllVerified(checkStep2Verified && checkStep3Verified);
    }
  }, [open, newStudent, documentVerificationStatus]);

  const allotSeatToStudent = async () => {
    if (isAllVerified) {
      const formData = new FormData();
      formData.append('file', seatAllotmentLetter);

      await apis
        .allotStudentSeat(applicationid, formData)
        .then((res) => {
          newStudent.seatAlloted = true;
          alert('Seat Allotted Successfully');
          onClose();
        })
        .catch((err) => {
          alert(err.message);
        });
    } else {
      alert('Please verify all documents before allotting seat');
    }
  };

  if (!open) return null;
  return (
    <>
      <div className="PersonalDetailsPopUp" style={{ width: '50%' }}>
        <div className="documents-popup-title">
          <h2>
            Allot Seat to: <span>{applicationid}</span>
          </h2>
          <button onClick={onClose}>
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div className="documents-popup-content">
          <div className="view-mode">
            <div className="form-container">
              <div
                className="form-field"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '20px',
                }}
              >
                <label htmlFor="seatAllotmentLetter">Upload Seat Allotment Letter</label>
                {isAllVerified ? (
                  <input
                    type="file"
                    onChange={(e) => {
                      setSeatAllotmentLetter(e.target.files[0]);
                    }}
                    id="seatAllotmentLetter"
                    accept="application/pdf,application/msword,"
                  />
                ) : (
                  <p>Please verify all documents before allotting seat</p>
                )}
              </div>
              <div className="form-field">
                <button
                  className="primary"
                  style={{
                    padding: '8px 20px',
                    borderRadius: '5px',
                    marginTop: '10px',
                  }}
                  onClick={() => allotSeatToStudent()}
                >
                  Allot Seat
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadSeatAllotmentLetter;
