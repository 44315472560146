import * as React from 'react';
import CourseStructureTable from './CourseStructureTable';
import '../css/Component/CourseDetailsPopUp.css';

const CourseDetailsPopUP = ({ open, onClose, user }) => {
  if (!open) return null;

  return (
    <>
      <div className="CourseRegistrationSection CourseDetailsPopUp">
        <div className="course-registration-section">
          <div className="course-popup-title">
            <h2>
              Course Details: <span>{user?.roll}</span>
            </h2>
            <button onClick={onClose}>
              <i class="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="course-popup-content">
            <div className="course-structure">
              <div className="course-structure-table">
                {user && (
                  <CourseStructureTable user={user} admin={true} />
                )}
              </div>
            </div>
            {/* <div className="choose-electives">
              <div className="choose-electives-title">
                <h3>Elective Subjects:</h3>
              </div>
              <div className="choose-preferences">
                <div className="preference">
                  <div className="preference-label">
                    <label htmlFor="preference-label-preference">
                      Elective I:
                    </label>
                  </div>
                  <div className="preference-input">
                    <select
                      name="preference-input-preference"
                      id="preference-input-preference"
                    >
                      <option value="1">Subject I</option>
                      <option value="2">Subject II</option>
                      <option value="3">Subject III</option>
                      <option value="3">Subject IV</option>
                      <option value="3">Subject V</option>
                    </select>
                  </div>
                </div>
                <div className="preference">
                  <div className="preference-label">
                    <label htmlFor="preference-label-preference">
                      Elective II:
                    </label>
                  </div>
                  <div className="preference-input">
                    <select
                      name="preference-input-preference"
                      id="preference-input-preference"
                    >
                      <option value="1">Subject I</option>
                      <option value="2">Subject II</option>
                      <option value="3">Subject III</option>
                      <option value="3">Subject IV</option>
                      <option value="3">Subject V</option>
                    </select>
                  </div>
                </div>
                <div className="preference">
                  <div className="preference-label">
                    <label htmlFor="preference-label-preference">
                      Elective III:
                    </label>
                  </div>
                  <div className="preference-input">
                    <select
                      name="preference-input-preference"
                      id="preference-input-preference"
                    >
                      <option value="1">Subject I</option>
                      <option value="2">Subject II</option>
                      <option value="3">Subject III</option>
                      <option value="3">Subject IV</option>
                      <option value="3">Subject V</option>
                    </select>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="apply-minor">
              <div className="apply-minor-title">
                <h3>
                  Other: <span>(Filled by student)</span>
                </h3>
              </div>
              <div className="apply-minor-inputs">
                <div className="enter-course-details">
                  <h4>CourseCode</h4>
                </div>
                <div className="enter-course-details">
                  <h4>Course Name</h4>
                </div>
                <div className="enter-course-details">
                  <h4>L</h4>
                  <h4>T</h4>
                  <h4>P</h4>
                  <h4>C</h4>
                </div>
                <div className="enter-course-details">
                  <h4>Type</h4>
                </div>
              </div>
            </div> */}
            {/* <div className="apply-minor">
              <div className="apply-minor-title">
                <h3>
                  <span>Enter details to asign course:</span>
                </h3>
              </div>
              <div className="apply-minor-inputs">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="enter-course-details">
                    <input
                      type="text"
                      name="apply-minor-input-input-minor"
                      id="apply-minor-input-input-minor"
                      placeholder="Course Code"
                    // value={courseData.code}
                    // onChange={(e) =>
                    //   setCourseData((e1) => {
                    //     return { ...e1, code: e.target.value };
                    //   })
                    // }
                    />
                  </div>
                  <div className="enter-course-details">
                    <input
                      type="text"
                      name="apply-minor-input-input-minor"
                      id="apply-minor-input-input-minor"
                      placeholder="Course Name"
                    // value={courseData.name}
                    // onChange={(e) =>
                    //   setCourseData((e1) => {
                    //     return { ...e1, name: e.target.value };
                    //   })
                    // }
                    />
                  </div>
                  <div className="enter-course-details">
                    <input
                      type="text"
                      name="apply-minor-input-input-minor"
                      id="apply-minor-input-input-minor"
                      placeholder="L"
                    // value={courseData.l}
                    // onChange={(e) =>
                    //   setCourseData((e1) => {
                    //     return { ...e1, l: e.target.value };
                    //   })
                    // }
                    />
                    <input
                      type="text"
                      name="apply-minor-input-input-minor"
                      id="apply-minor-input-input-minor"
                      placeholder="T"
                    // value={courseData.t}
                    // onChange={(e) =>
                    //   setCourseData((e1) => {
                    //     return { ...e1, t: e.target.value };
                    //   })
                    // }
                    />
                    <input
                      type="text"
                      name="apply-minor-input-input-minor"
                      id="apply-minor-input-input-minor"
                      placeholder="P"
                    // value={courseData.p}
                    // onChange={(e) =>
                    //   setCourseData((e1) => {
                    //     return { ...e1, p: e.target.value };
                    //   })
                    // }
                    />
                    <input
                      type="text"
                      name="apply-minor-input-input-minor"
                      id="apply-minor-input-input-minor"
                      placeholder="C"
                    // value={courseData.c}
                    // onChange={(e) =>
                    //   setCourseData((e1) => {
                    //     return { ...e1, c: e.target.value };
                    //   })
                    // }
                    />
                  </div>
                  <div className="enter-course-details">
                    <input
                      type="text"
                      name="apply-minor-input-input-minor"
                      id="apply-minor-input-input-minor"
                      placeholder="CourseType"
                    // value={courseData.type}
                    // onChange={(e) =>
                    //   setCourseData((e1) => {
                    //     return { ...e1, type: e.target.value };
                    //   })
                    // }
                    />
                  </div>
                </form>
              </div>
            </div> */}
          </div>
          {/* <div className="submit-registration">
            <p>
              To asign or update, fill data manually or leave it empty for
              rejection or other/minor section.
            </p>
            <button type="submit">Update Details</button>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default CourseDetailsPopUP;
