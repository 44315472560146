import React from 'react';
import '../css/Component/LatestDOC.css';
import apis from '../apis';
import { useNavigate } from 'react-router-dom';

const LatestDOC = () => {
  const navigate = useNavigate();
  const bufferToURL = (buffer) => {
    let byteArray = new Uint8Array(buffer);
    var blob = new Blob([byteArray], { type: 'application/pdf' });
    var url = URL.createObjectURL(blob);
    return url;
  };

  async function fetchData(type) {
    await apis
      .particularTypeFile(type)
      .then((data) => {
        if (data) {
          let url = data?.link ? data?.link : bufferToURL(data.files.data);
          window.open(url, '_blank');
        }
      })
      .catch((err) => {
        alert(err);
      });
  }

  function BonafideApply() {
    navigate('/student/bonafide-application');
  }

  return (
    <>
      <div className="latest">
        <div className="title">
          <h2>Top Documents: </h2>
        </div>
        <div className="files">
          <div className="file" onClick={() => fetchData('acadCal')}>
            <div className="icon">
              <img src="/assets/fee-structure.svg" alt="img" />
            </div>
            <div className="filename">
              <h1>
                Academic
                <br />
                Calendar
              </h1>
            </div>
          </div>
          <div className="file" onClick={() => navigate('/student/feedback-reviews')}>
            <div className="icon">
              <img src="/assets/fee-structure.svg" alt="img" />
            </div>
            <div className="filename">
              <h1>Complaints</h1>
            </div>
          </div>
          <div className="file" onClick={() => fetchData('feeStruct')}>
            <div className="icon">
              <img src="/assets/fee-structure.svg" alt="img" />
            </div>
            <div className="filename">
              <h1>
                Institute
                <br />
                Fee structure
              </h1>
            </div>
          </div>
          <div className="file" onClick={BonafideApply}>
            <div className="icon">
              <img src="/assets/fee-structure.svg" alt="img" />
            </div>
            <div className="filename">
              <h1>
                Document
                <br />
                Application Form
              </h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LatestDOC;
