import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';

import LogInPage from './pages/LogInPage';
import StudentPage from './pages/StudentPage';
import Registration from './pages/Registration';
import Documents from './pages/Documents';
import FeePayment from './pages/FeePayment';
import AdminPage from './pages/AdminPage';
import AuthContext from './auth/AuthContext';
import StudentProfileAV from './pages/StudentProfileAV';
import verificationPage from './pages/VerificationRequests';
import DataUpload from './pages/DataUpload';
import BulkGeneration from './pages/BulkGeneration';
import HostelManagement from './pages/HostelManagement';
import PaymentRecords from './pages/PaymentRecords';
import NewStudentRegistration from './pages/NewStudentRegistration';
import AdmissionPage from './pages/AdmissionPage';
import GatewayPopUp from './components/GatewayPopUp';
import PasswordChange from './pages/PasswordChange';
import Loader from './components/Loader';
import NewAdmissionProgress from './pages/NewAdmissionProgress';
import CourseVerification from './pages/CourseVerification';
import DocsAdmin from './pages/DocsAdmin';
import Bonafide from './pages/BonafideAsign';
import FeedbackReviews from './pages/FeedbackReviews';
import BonafideApplication from './pages/BonafideApply';
import ForgetPassword from './pages/ForgetPassword';
import Dashboard from './pages/Dashboard';
import ManageAdmins from './pages/ManageAdmins';
import AdminUpdatePopUp from './components/AdminUpdatePopUp';
import ButtonLoader from './components/ButtonLoader';
import Result from './pages/Result';
import StudentProgressAdmin from './pages/StudentProgressAdmin';
import FacultyCourseAssign from './pages/FacultyCourseAssign';
import MyCourses from './pages/MyCourses';
import UploadMarks from './pages/UploadMarks';
import HodViewPage from './pages/HodViewPage';
import StudentFeedback from './pages/StudentFeedback';
import Ratings from './pages/Ratings';
import AdminFeeback from './pages/AdminFeedback';
import AdminFeedbackPage from './pages/AdminFeedbackPage';
import FacultyCourseRatingDetails from './pages/FacultyCourseRatingDetails';
import BankDetailsPage from './pages/BankDetailsPage';
import StudentBankDetailAdmin from './pages/StudentBankDetailAdmin';
import NewStudentProfileAV from './pages/NewStudentProfileAV';
import NewStudentProfilePage from './pages/NewStudentProfilePage';
import RegistrationSlipPage from './pages/RegistrationSlipPage';
import ComplaintDashboard from './pages/ComplaintDashboard';
import RefundPage from './pages/RefundPage';
import StudentPromotionPage from './pages/StudentPromotionPage';
import RaiseComplaintAdmin from './pages/RaiseComplaintAdmin';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AuthContext>
          <Routes>
            <Route path="/" element={<Navigate to="/login" />}></Route>
            <Route path="/:user" Component={LogInPage}></Route>
            <Route path="/student" Component={StudentPage}></Route>
            <Route path="/student/admin/verify-review-status" Component={Ratings}></Route>
            <Route path="/student/register" Component={Registration}></Route>
            <Route path="/student/feedback" Component={StudentFeedback}></Route>
            <Route path="/student/bankdetails" Component={BankDetailsPage}></Route>
            <Route path="/student/documents" Component={Documents}></Route>
            <Route path="/student/feepayment" Component={FeePayment}></Route>
            <Route path="/student/result" Component={Result}></Route>
            <Route path="/student/admin" Component={AdminPage}></Route>
            <Route path="/newstudentregistration" Component={NewStudentRegistration}></Route>
            <Route path="/admissionpage" Component={AdmissionPage}></Route>
            <Route path="/student/admin/dashboard" Component={ComplaintDashboard}></Route>
            <Route path="/student/gateway" Component={AdminUpdatePopUp}></Route>
            <Route path="/student/admin/paymentrecords" Component={PaymentRecords}></Route>
            <Route path="/student/admin/studentprofile/:roll" Component={StudentProfileAV}></Route>
            <Route
              path="/student/admin/newprofile/:roll/:applicationid"
              Component={NewStudentProfilePage}
            ></Route>
            <Route
              path="/student/admin/newprofile/:roll/:applicationid/registrationslip"
              Component={RegistrationSlipPage}
            ></Route>
            <Route
              path="/student/admin/newstudentprofile/:applicationid"
              Component={NewStudentProfileAV}
            ></Route>
            <Route path="/student/admin/bulkgenerate" Component={BulkGeneration}></Route>
            <Route path="/student/admin/hostel" Component={HostelManagement}></Route>
            <Route path="/student/admin/assign-course" Component={FacultyCourseAssign}></Route>
            <Route path="/student/admin/my-courses" Component={MyCourses}></Route>
            <Route path="/student/admin/my-courses/:id" Component={UploadMarks}></Route>
            <Route path="/student/admin/my-courses/hodview/:id" Component={HodViewPage}></Route>

            <Route path="/student/admin/verification" Component={verificationPage}></Route>
            <Route path="/student/admin/data-upload" Component={DataUpload}></Route>
            <Route path="/student/admin/course-verification" Component={CourseVerification}></Route>
            <Route
              path="/student/admin/admission-progress"
              Component={NewAdmissionProgress}
            ></Route>
            <Route path="/student/admin/documents-admin" Component={DocsAdmin}></Route>
            <Route path="/student/admin/refund-records" Component={RefundPage}></Route>
            <Route path="/student/admin/studentprogress" Component={StudentProgressAdmin}></Route>
            <Route path="/student/admin/manage-admins" Component={ManageAdmins}></Route>
            <Route path="/student/admin/documents-bonafide/" Component={Bonafide}></Route>
            <Route path="/student/bonafide-application" Component={BonafideApplication}></Route>
            <Route path="/student/feedback-reviews" Component={FeedbackReviews}></Route>
            <Route path="/student/admin/raise-complaint" Component={RaiseComplaintAdmin}></Route>
            <Route path="/student/admin/feedback" Component={AdminFeedbackPage} />
            <Route path="/student/admin/promote-students" Component={StudentPromotionPage} />
            <Route path="/student/admin/complaintDashboard" Component={ComplaintDashboard}></Route>
            <Route
              path="/student/admin/feedback/:facultyId/:courseId/:session/:course/:semester/:branch"
              Component={FacultyCourseRatingDetails}
            />
            <Route path="/student/admin/student-bank-details" Component={StudentBankDetailAdmin} />
            <Route path="/student/gateway" Component={GatewayPopUp}></Route>
            <Route path="/password-change" Component={PasswordChange}></Route>
            <Route path="/forget-password" Component={ForgetPassword}></Route>
            <Route path="/loading..." Component={Loader}></Route>
            <Route path="/button-loading..." Component={ButtonLoader}></Route>
          </Routes>
        </AuthContext>
      </BrowserRouter>
    </div>
  );
}

export default App;
