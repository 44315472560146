import * as React from 'react';
import '../css/Component/StudentLogin.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import jwtVerify from '../auth/service';
import { AuthProvider } from '../auth/AuthContext';
import ButtonLoader from './ButtonLoader';

const AdminLogin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const { setAdminData } = AuthProvider();
  const [btnLoading, setBtnLoading] = React.useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setBtnLoading(true);
    if (email === '' || password === '') {
      alert('Please fill all the fields');
    } else {
      jwtVerify
        .adminLogin(email, password)
        .then((res) => {
          setAdminData(res);
          setBtnLoading(false);
          navigate('/student/admin');
        })
        .catch((err) => {
          alert(err.message);
        });
    }
  };

  return (
    <>
      <div className="login-div">
        <div className="login-header">
          <h2>
            Admin Login <i className="fa-solid fa-lock"></i>
          </h2>
          <Link to={'/'}>
            <i className="fa-solid fa-exchange"></i>
          </Link>
        </div>
        <div className="login-message">
          <p>! Only for official purposes</p>
        </div>
        <div className="login-form">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="login-input">
              <h3>Admin Email ID</h3>
              <input
                type="email"
                placeholder="Username"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
            <div className="login-input">
              <h3>Password</h3>
              <input
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </div>
            <div className="login-button">
              {btnLoading ? (
                <button type="submit">
                  <ButtonLoader />
                </button>
              ) : (
                <button type="submit">Login</button>
              )}
              <Link to={'/password-change'}>
                Forget/Change <span>Password</span>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AdminLogin;
