import React, { useEffect, useState } from 'react';
import apis from '../apis';
import AdminHeader from '../components/AdminHeader';
import '../css/Page/FacultyCourseRatingDetails.css';
import { useParams } from 'react-router-dom';
import RatingDetailTables from '../components/RatingDetailTables';

const FacultyCourseRatingDetails = () => {
  const { facultyId, courseId, session, course, semester, branch } = useParams();
  const [facultyCourseDetails, setFacultyCourseDetails] = useState();
  const [registeredStudentCount, setRegisteredStudentCount] = useState(0);
  const [totalRatingCount, setTotalRatingCount] = useState(0);

  useEffect(() => {
    const getFacultyCourseDetails = async () => {
      try {
        const queryParameters = {
          facultyId: facultyId,
          courseId: courseId,
          session: session,
          semester: semester,
          branch: branch,
        };
        await Promise.resolve(apis.getFacultyCourseByFacIdandCourseId(queryParameters)).then(
          (data) => {
            // console.log(data);
            setFacultyCourseDetails(data);
          }
        );
      } catch (error) {
        console.log('Error in getting faculty details');
      }
    };

    getFacultyCourseDetails();
  }, [facultyId, courseId]);

  useEffect(() => {
    const getRegisteredStudentCount = async () => {
      try {
        const queryParameters = {
          courseId: courseId,
          session: facultyCourseDetails?.facultyCourse?.Session,
          semester: facultyCourseDetails?.courseData?.semester,
        };

        await Promise.resolve(apis.specificCourseRegisteredStudents(queryParameters)).then(
          (data) => {
            setRegisteredStudentCount(data);
          }
        );
      } catch (error) {
        console.log('Error in getting registered student count');
      }
    };

    const getRatingCount = async () => {
      try {
        const queryParameters = {
          facultyId: facultyId,
          courseId: courseId,
          session: session,
          semester: semester,
          course: course,
          branch: branch?.replace(/&/g, '%26'),
        };

        await Promise.resolve(apis.specificCourseRatingsCount(queryParameters)).then((data) => {
          setTotalRatingCount(data);
        });
      } catch (error) {
        console.log('Error in getting rating count');
      }
    };

    if (facultyCourseDetails) {
      getRegisteredStudentCount();
      getRatingCount();
    }
  }, [facultyCourseDetails, courseId]);

  return (
    <div>
      <AdminHeader />
      <div className="reveiwDetailCont">
        <div className="reviewSidebar">
          <div className="facName">
            {facultyCourseDetails && facultyCourseDetails?.facultyCourse?.name}
          </div>
          <div className="courseName details7">
            Course Name: <br />{' '}
            <span>{facultyCourseDetails && facultyCourseDetails?.courseData?.name}</span>
          </div>
          <div className="courseCode details7">
            Course Code:{' '}
            <span>{facultyCourseDetails && facultyCourseDetails?.courseData?.code}</span>{' '}
          </div>
          <div className="courseSession details7">
            Session:{' '}
            <span>{facultyCourseDetails && facultyCourseDetails?.facultyCourse?.Session}</span>
          </div>
          <div className="courseSession details7">
            Semester:{' '}
            <span>{facultyCourseDetails && facultyCourseDetails?.facultyCourse?.Semester}</span>
          </div>
          <div className="courseSession details7">
            Course: <span>{facultyCourseDetails && facultyCourseDetails?.courseData?.course}</span>
          </div>
          <div className="courseSession details7">
            Branch: <span>{facultyCourseDetails && facultyCourseDetails?.courseData?.branch}</span>
          </div>
          <br />
          <br />
          <div className="details7">
            Total Students Registered for this Course: <span>{registeredStudentCount}</span>
          </div>
          <div className="details7">
            Total Review Submitted: <span>{totalRatingCount.rating}</span>
          </div>
        </div>
        <div className="reviewDashboard">
          <RatingDetailTables
            facultyRatingDetails={facultyCourseDetails && facultyCourseDetails?.facultyCourse}
            totalRatingCount={
              totalRatingCount && totalRatingCount.rating > 0 ? totalRatingCount.rating : 1
            }
          />
        </div>
      </div>
    </div>
  );
};

export default FacultyCourseRatingDetails;
