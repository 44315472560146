import React from 'react';
import '../css/Component/FeeReceiptGeneration.css';

const FeeReceiptGeneration = ({ update, student, applySemester, date, feeData, totalFees }) => {
  return (
    <div className="" style={{ backgroundColor: 'white' }}>
      <div className="bonafide-generated-head">
        <img src="/assets/heading-banner.jpg" alt="bonafide-header" />
      </div>
      <div className="horz-line"></div>
      <div className="bonafide-generated-contacts">
        <h4>
          <i className="fa-solid fa-location-dot"></i> IIIT Bhagalpur Campus, Sabour, Bhagalpur,
          Bihar - 813210
        </h4>
        <h4>
          <i class="fa-solid fa-envelope"></i> ar.academic@iiitbh.ac.in
        </h4>
      </div>
      <div className="horz-line"></div>
      <div className="reference-id">
        <strong>Ref No.: IIITBH/ACAD/Fee-decl/{update?.refNo}</strong>
        <strong>
          Date: {date?.getDate()}/{date?.getMonth() + 1}/{date?.getFullYear()}
        </strong>
      </div>
      <div className="bonafide-generated-heading">
        <h3>
          <u>Fee Receipt</u>
        </h3>
      </div>
      <div className="body-1">
        <p>
          This is to certify that Mr./Ms. <strong>{student?.name}</strong>, Institute Roll No:{' '}
          <strong>{student?.roll}</strong>, son/daughter of Mr.{' '}
          <strong> {student?.fathersname}</strong>, department of <strong>{student?.branch}</strong>{' '}
          (<strong>{student?.course}</strong>) has deposited the following amount for academic year
          <strong> {student?.batch}</strong>; {applySemester ? applySemester : student?.semester}{' '}
          semester.
        </p>
      </div>
      <div className="body-2">
        <table className="table">
          <tr className="tr">
            <th className="th">Sl. No.</th>
            <th className="th">
              Head of Fees ({applySemester ? applySemester : student?.semester}
              {(applySemester ? applySemester : student?.semester) === '1' ? (
                <sup>st</sup>
              ) : (applySemester ? applySemester : student?.semester) === '2' ? (
                <sup>nd</sup>
              ) : (applySemester ? applySemester : student?.semester) === '3' ? (
                <sup>rd</sup>
              ) : (
                <sup>th</sup>
              )}{' '}
              Semester)
            </th>
            <th className="th">Amount (in INR)</th>
          </tr>
          <tr className="tr">
            <td className="td">1</td>
            <td className="td">Tuition Fees</td>
            <td className="td">₹ {feeData?.tuitionfee}</td>
          </tr>
          <tr>
            <td className="td">2</td>
            <td className="td">Other Institute Fees</td>
            <td className="td">₹ {feeData?.otherfee}</td>
          </tr>
          <tr>
            <td colspan="2" className="td">
              Total
            </td>
            <td className="td">₹ {totalFees}</td>
          </tr>
        </table>
      </div>

      <div className="body-4">
        <p>
          <strong>
            Assistant Registrar
            <br /> (Academics)
          </strong>
        </p>
      </div>
    </div>
  );
};

export default FeeReceiptGeneration;
