import React from 'react';
import '../css/Component/StudentListBody.css';
import { Link } from 'react-router-dom';
// import apis from "../apis";
// import { useSearch } from "../contexts/SearchProvider";

const StudentListBody = ({ data, index }) => {
  // const [totalDue, setTotalDue] = React.useState(0);
  // const [pastDue, setPastDue] = React.useState(0);
  // const [feeDetails, setFeeDetails] = React.useState();
  // const [hostelDetails, setHostelDetails] = React.useState();
  // // const { download, setDownload } = useSearch();
  // const fetchFeeStructure = async () => {
  //   try {
  //     const response = await apis.feeStructure(
  //       0,
  //       data?.course,
  //       data?.semester,
  //       data?.batch
  //     );
  //     setFeeDetails(response);
  //   } catch (error) {
  //     alert(error.message);
  //   }
  // };
  // const fetchHostelDetails = async () => {
  //   try {
  //     const response = await apis.hostelStatusAdmin(data?.roll);
  //     setHostelDetails(response);
  //   } catch (error) {
  //     alert(error.message);
  //   }
  // };

  // React.useEffect(() => {
  //   fetchFeeStructure();
  //   fetchHostelDetails();
  // }, []);

  // React.useEffect(() => {
  //   const calculateTotalDue = async () => {
  //     let totalDue1 = 0;
  //     totalDue1 =
  //       Number(feeDetails?.tuitionfee) +
  //       Number(feeDetails?.otherfee) +
  //       Number(feeDetails?.admissionfee) +
  //       Number(feeDetails?.cautionmoney) +
  //       Number(feeDetails?.convocationfee) + Number(data?.instFine) + Number(data?.hostelFine) + Number(data?.messFine) - Number(data?.instAdjust) - Number(data?.hostelAdjust) - Number(data?.messAdjust);
  //     let pastDue1 = Number(data?.pastDue);
  //     if (hostelDetails?.hostel === "H1") {
  //       totalDue1 =
  //         totalDue1 + Number(feeDetails?.h1fee) + Number(feeDetails?.messfee);
  //     } else if (hostelDetails?.hostel === "H9") {
  //       totalDue1 =
  //         totalDue1 + Number(feeDetails?.h9fee) + Number(feeDetails?.messfee);
  //     } else if (hostelDetails?.hostel === "H7") {
  //       totalDue1 =
  //         totalDue1 + Number(feeDetails?.h7fee) + Number(feeDetails?.messfee);
  //     } else if (hostelDetails?.hostel === "H7") {
  //       totalDue1 =
  //         totalDue1 + Number(feeDetails?.h7fee) + Number(feeDetails?.messfee);
  //     } else if (hostelDetails?.hostel === "NBH") {
  //       totalDue1 =
  //         totalDue1 + Number(feeDetails?.nbhfee) + Number(feeDetails?.messfee);
  //     } else if (hostelDetails?.hostel === "GH") {
  //       totalDue1 =
  //         totalDue1 + Number(feeDetails?.ghfee) + Number(feeDetails?.messfee);
  //     }
  //     totalDue1 =
  //       totalDue1 -
  //       (Number(data?.currentSemesterPaid?.institute) +
  //         Number(data?.currentSemesterPaid?.hostel) +
  //         Number(data?.currentSemesterPaid?.mess)) +
  //       pastDue1 + Number(data?.currentSemesterPaidExtra);
  //     setTotalDue(totalDue1);
  //     setPastDue(pastDue1);
  //   };

  //   calculateTotalDue();
  // }, [feeDetails, hostelDetails, data]);

  return (
    <>
      <div className="student-list-body">
        <div className="header-element">
          <h3>{index}.</h3>
        </div>
        <div className="header-element">
          {/* <Link to={`/student/admin/studentprofile/${data.roll}?id=${data.id}`} target="_blank" rel="noopener noreferrer"> */}
          <Link
            to={`/student/admin/newprofile/${data.roll}/${data?.applicationid}?semester=${data.semester}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {data.roll}
          </Link>
        </div>
        <div className="header-element">
          {/* <Link to={`/student/admin/studentprofile/${data.roll}?id=${data.id}`} target="_blank" rel="noopener noreferrer"> */}
          <Link
            to={`/student/admin/newprofile/${data.roll}/${data?.applicationid}?semester=${data.semester}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {data.name}
          </Link>
        </div>
        <div className="header-element">
          <h3>{data.course}</h3>
        </div>
        <div className="header-element">
          <h3>{data.batch}</h3>
        </div>
        <div className="header-element">
          <h3>{data.semester}</h3>
        </div>
        <div className="header-element">
          <h3>{data.paymentStatusSplit.institute}</h3>
        </div>
        <div className="header-element">
          <h3>{data.hostel}</h3>
        </div>
        <div className="header-element">
          <h3>{data.paymentStatusSplit.hostel}</h3>
        </div>
        <div className="header-element">
          <h3>{data.paymentStatusSplit.mess}</h3>
        </div>
        <div className="header-element">
          {data.registrationStatus === 'Approved' ? (
            <h3>Eligible</h3>
          ) : (
            <h3>{data.registrationStatus}</h3>
          )}
        </div>
        <div className="header-element">
          <h3>{data.totalDue}</h3>
        </div>
      </div>
    </>
  );
};

export default StudentListBody;
