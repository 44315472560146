import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import './styles/Table.css';
import ContextMenu from '../../contexts/ContextMenu';
import ImportExportIcon from '@mui/icons-material/ImportExport';

const Table = ({ columns, data, loading, renderCell, renderHeader, className, bodyClassName }) => {
  const [sortedData, setSortedData] = useState(data);
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
  const [currentColumn, setCurrentColumn] = useState(null);

  useEffect(() => {
    setSortedData(data);
  }, [data]);

  const handleContextMenu = (event, column) => {
    if (
      !column.showContextMenu ||
      (event.target.tagName.toLowerCase() === 'input' && event.target.type === 'checkbox')
    )
      return;

    event.preventDefault();
    setContextMenuPosition({ x: event.pageX, y: event.pageY });
    setCurrentColumn(column);
    setContextMenuVisible(true);
  };

  const handleCloseContextMenu = () => {
    setContextMenuVisible(false);
  };

  const handleSort = (order) => {
    if (!currentColumn) return;

    const sorted = [...sortedData].sort((a, b) => {
      if (a[currentColumn.accessor] < b[currentColumn.accessor]) return order === 'asc' ? -1 : 1;
      if (a[currentColumn.accessor] > b[currentColumn.accessor]) return order === 'asc' ? 1 : -1;
      return 0;
    });

    setSortedData(sorted);
    handleCloseContextMenu();
  };

  const contextMenuOptions = [
    { label: 'Sort Ascending', onClick: () => handleSort('asc') },
    { label: 'Sort Descending', onClick: () => handleSort('desc') },
  ];

  if (loading) {
    return (
      <div className="loadingState">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="generalTableComponent">
      <table className={`generalTable ${className}`}>
        <thead className="generalTableHead">
          <tr>
            {columns.map((col) => (
              <th
                key={col.accessor}
                style={col.style}
                className="generalTableTH"
                onContextMenu={(event) => handleContextMenu(event, col)}
                title={col.showContextMenu ? `Right click to sort ${col.Header}` : ''}
              >
                <span>{renderHeader ? renderHeader(col) : col.Header}</span>
                <span style={{ fontSize: '12px', margin: '0 5px' }}>
                  {col.showContextMenu && <i class="fas fa-sort"></i>}
                </span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={bodyClassName}>
          {sortedData && sortedData.length === 0 ? (
            <tr>
              <td colSpan={columns.length} className="generalTableTD">
                No data available
              </td>
            </tr>
          ) : (
            sortedData &&
            sortedData.map((row, rowIndex) => (
              <tr key={rowIndex} className={row.className || ''}>
                {columns.map((col) => (
                  <td
                    key={col.accessor}
                    style={col.style}
                    className="generalTableTD"
                    onClick={col.onClick ? () => col.onClick(row) : undefined}
                  >
                    {renderCell ? renderCell(row[col.accessor], col, row) : row[col.accessor]}
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
      {contextMenuVisible && (
        <ContextMenu
          position={contextMenuPosition}
          options={contextMenuOptions}
          onClose={handleCloseContextMenu}
        />
      )}
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      accessor: PropTypes.string.isRequired,
      style: PropTypes.object,
      onClick: PropTypes.func,
      showContextMenu: PropTypes.bool, // Add PropTypes validation for showContextMenu
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  renderCell: PropTypes.func,
  renderHeader: PropTypes.func,
  className: PropTypes.string,
  bodyClassName: PropTypes.string,
};

Table.defaultProps = {
  loading: false,
  renderCell: null,
  renderHeader: null,
  className: '',
  bodyClassName: '',
};

export default Table;
