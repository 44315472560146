import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import '../css/Page/FeedbackReviews.css';
import apis from '../apis';
import ComplaintsElement from '../components/ComplaintsElement';
import { AuthProvider } from '../auth/AuthContext';
import Header from '../components/Header';

const FeedbackReviews = () => {
  const { register, handleSubmit } = useForm();
  const [submitted, setSubmitted] = useState(false);
  const [complaints, setComplaints] = useState();
  const [position, setPosition] = useState({
    floor: null,
    block: null,
    roomNumber: null,
  });
  const [dateTime, setDateTime] = useState({
    date: null,
    time: null,
  });

  const { userData } = AuthProvider();

  const submitFeedback = async (data1) => {
    if(data1?.location === null || data1?.location === ""){
      alert(`Please select the location of issue.`);
      return;
    }

    if(data1?.type === null || data1?.type === ""){
      alert(`Please select the type of issue.`);
      return;
    }

    if(data1?.subject === null || data1?.subject === ""){
      alert(`Please enter the subject.`);
      return;
    }

    const data = {
      name: userData?.name,
      email: userData?.email,
      location: data1?.location,
      type: data1?.type,
      position: position,
      subject: data1?.subject,
      description: data1?.description,
      availability: dateTime,
    };
    console.log(data);
    await apis
      .raiseComplaint(data)
      .then((res) => {
        //
        setSubmitted(true);
      })
      .catch((err) => {
        alert(err.message);
      });

    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const fetchAllComplaints = async () => {
      await apis
        .getAllComplaintsPublic()
        .then((res) => {
          setComplaints(res);
        })
        .catch((err) => {
          alert(err.message);
        });
    };
    fetchAllComplaints();
  }, []);

  const currentYear = new Date().getFullYear();
  const currentMonth = String(new Date().getMonth() + 1).padStart(2, '0');
  const currentDay = String(new Date().getDate()).padStart(2, '0');

  const handleChange = (e) => {
    let input = e.target.value.replace(/[^\d-]/g, '');
    const rawInput = input.replace(/-/g, '');

    let day = rawInput.substring(0, 2);
    let month = rawInput.substring(2, 4);
    let year = rawInput.substring(4, 8);

    if (input.length >= 2) day += '-';
    if (input.length >= 4) month += '-';

    if (day.replace('-', '') > 31) day = currentDay + '-';
    if (month.replace('-', '') > 12) month = currentMonth + '-';

    let formattedDate = day + (month || '') + (year || '');
    setDateTime({ ...dateTime, date: formattedDate });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace') {
      let input = dateTime.date;
      let cursorPos = e.target.selectionStart;

      if (cursorPos <= 2) {
        input = '';
      } else if (cursorPos <= 5) {
        input = input.slice(0, 2);
      } else if (cursorPos > 5) {
        input = input.slice(0, 5);
      }

      setDateTime({ ...dateTime, date: input });
      e.preventDefault();
    }
  };

  const handleBlur = () => {
    let input = dateTime.date.replace(/\D/g, '');
    let day = input.substring(0, 2);
    let month = input.substring(2, 4);
    let year = input.substring(4, 8);

    if (day > 31) day = currentDay;
    if (month > 12) month = currentMonth;
    if (year && (year < currentYear || year > currentYear + 4)) year = currentYear;

    let formattedDate = `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`;
    setDateTime({ ...dateTime, date: formattedDate });
  };

  const handleChange2 = (e) => {
    let input = e.target.value.replace(/[^0-9apm\-: ]/gi, '');
    const rawInput = input.replace(/[^0-9apm]/gi, '');

    const validHours = (hour) => parseInt(hour, 10) >= 1 && parseInt(hour, 10) <= 12;
    const validMinutes = (minute) => parseInt(minute, 10) >= 0 && parseInt(minute, 10) < 60;

    if (rawInput.length < 2) {
      input = rawInput;
    } else if (rawInput.length === 2) {
      const hour = rawInput.slice(0, 2);
      if (!validHours(hour)) {
        input = '12:';
      } else {
        input = rawInput.slice(0, 2) + ':';
      }
    } else if (rawInput.length < 4) {
      input = rawInput.slice(0, 2) + ':' + rawInput.slice(2, 4);
    } else if (rawInput.length === 4) {
      const minute = rawInput.slice(2, 4);
      if (!validMinutes(minute)) {
        input = rawInput.slice(0, 2) + ':00 ';
      } else {
        input = rawInput.slice(0, 2) + ':' + rawInput.slice(2, 4) + ' ';
      }
    } else if (rawInput.length < 6) {
      input =
        rawInput.slice(0, 2) +
        ':' +
        rawInput.slice(2, 4) +
        ' ' +
        rawInput.slice(4, 6).replace(/[^apm]/gi, '');
    } else if (rawInput.length === 6) {
      input =
        rawInput.slice(0, 2) +
        ':' +
        rawInput.slice(2, 4) +
        ' ' +
        rawInput.slice(4, 6).replace(/[^apm]/gi, '') +
        ' - ';
    } else if (rawInput.length < 8) {
      input =
        rawInput.slice(0, 2) +
        ':' +
        rawInput.slice(2, 4) +
        ' ' +
        rawInput.slice(4, 6).replace(/[^apm]/gi, '') +
        ' - ' +
        rawInput.slice(6, 8);
    } else if (rawInput.length === 8) {
      const hour = rawInput.slice(6, 8);
      if (!validHours(hour)) {
        input =
          rawInput.slice(0, 2) +
          ':' +
          rawInput.slice(2, 4) +
          ' ' +
          rawInput.slice(4, 6).replace(/[^apm]/gi, '') +
          ' - ' +
          '12:';
      } else {
        input =
          rawInput.slice(0, 2) +
          ':' +
          rawInput.slice(2, 4) +
          ' ' +
          rawInput.slice(4, 6).replace(/[^apm]/gi, '') +
          ' - ' +
          rawInput.slice(6, 8) +
          ':';
      }
    } else if (rawInput.length < 10) {
      input =
        rawInput.slice(0, 2) +
        ':' +
        rawInput.slice(2, 4) +
        ' ' +
        rawInput.slice(4, 6) +
        ' - ' +
        rawInput.slice(6, 8) +
        ':' +
        rawInput.slice(8, 10);
    } else if (rawInput.length === 10) {
      const minute = rawInput.slice(8, 10);
      if (!validMinutes(minute)) {
        input =
          rawInput.slice(0, 2) +
          ':' +
          rawInput.slice(2, 4) +
          ' ' +
          rawInput.slice(4, 6) +
          ' - ' +
          rawInput.slice(6, 8) +
          ':' +
          '00 ';
      } else {
        input =
          rawInput.slice(0, 2) +
          ':' +
          rawInput.slice(2, 4) +
          ' ' +
          rawInput.slice(4, 6) +
          ' - ' +
          rawInput.slice(6, 8) +
          ':' +
          rawInput.slice(8, 10) +
          ' ';
      }
    } else if (rawInput.length <= 12) {
      input =
        rawInput.slice(0, 2) +
        ':' +
        rawInput.slice(2, 4) +
        ' ' +
        rawInput.slice(4, 6) +
        ' - ' +
        rawInput.slice(6, 8) +
        ':' +
        rawInput.slice(8, 10) +
        ' ' +
        rawInput.slice(10, 12);
    }

    setDateTime({ ...dateTime, time: input });
  };

  const handleKeyDown2 = (e) => {
    if (e.key === 'Backspace') {
      let input = dateTime.time;
      let cursorPos = e.target.selectionStart;

      if (cursorPos <= 5) {
        input = '';
      } else if (cursorPos > 5 && cursorPos <= 8) {
        input = input.slice(0, 5);
      } else if (cursorPos > 8 && cursorPos <= 13) {
        input = input.slice(0, 8);
      } else if (cursorPos > 13) {
        input = input.slice(0, 13);
      }

      setDateTime({ ...dateTime, time: input });
      e.preventDefault();
    }
  };

  return (
    <>
      <Header />
      <div className="top-div">
        <div className="feedback-page-div">
          <div className="title cv">
            <div className="title-left-div">
              <h2>Complaints: </h2>
            </div>
          </div>
          <div className="FeedbackPage">
            {submitted && (
              <div className="success-message">
                <p>Thank you for your contribution, Team will look into it soon!</p>
              </div>
            )}
            <form onSubmit={handleSubmit(submitFeedback)}>
              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  value={userData?.name}
                  // {...register("name", { required: true })}
                  required
                  disabled
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  value={userData?.email}
                  // {...register("email", { required: true })}
                  required
                  disabled
                />
              </div>

              <div className="form-group">
                <label htmlFor="location">Select Location of problem:</label>
                <select id="location" {...register('location', { required: true })} required>
                  <option value="">Specify the location</option>
                  <option value="Academic">Academic Building</option>
                  <option value="Library">Central Library</option>
                  <option value="Administrative">Administrative Block</option>
                  <option value="Lecture">Lecture Hall</option>
                  <option value="KBH">Kautilya Boys Hostel</option>
                  <option value="KGH">Kadambini Girls Hostel</option>
                  <option value="H1">Hostel 1</option>
                  <option value="Faculty Residence">Faculty Residence</option>
                  <option value="Director Residence">Director Residence</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="type">Type of Issue:</label>
                <select
                  id="type"
                  {...register('type', { required: true })}
                >
                  <option value="">Specify the type</option>
                  <option value="Electrical">Electricity/Electrical Appliances</option>
                  <option value="Internet">Internet</option>
                  <option value="Civil">Civil</option>
                  <option value="Others">Others</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="position">Specify the position:</label>
                <div className="positionCont">
                  <div className="">
                    <label htmlFor="floor">Floor:</label>
                    <input
                      type="text"
                      id="floor"
                      placeholder="eg. Ground, 1st, 2nd, etc."
                      onChange={(e) => setPosition({ ...position, floor: e.target.value })}
                    />
                  </div>
                  <div className="">
                    <label htmlFor="block">Block:</label>
                    <input
                      type="text"
                      id="block"
                      placeholder="eg. A, B, C, etc."
                      onChange={(e) => setPosition({ ...position, block: e.target.value })}
                    />
                  </div>
                  <div className="">
                    <label htmlFor="roomNumber">Room No.:</label>
                    <input
                      type="text"
                      id="roomNumber"
                      placeholder="001"
                      onChange={(e) => setPosition({ ...position, roomNumber: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="subject">Subject:</label>
                <input
                  type="text"
                  id="subject"
                  {...register('subject', { required: true })}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="description">Description:</label>
                <textarea
                  id="description"
                  {...register('description', { required: true })}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="availability">
                  Availability:{' '}
                  <span
                    style={{
                      color: 'gray',
                      fontWeight: 'lighter',
                      fontSize: '14px',
                    }}
                  >
                    (Specify your avaliability date and time if the issue is related to hostel.)
                  </span>
                </label>
                <div className="positionCont">
                  <div className="">
                    <label htmlFor="date">Date:</label>
                    <input
                      type="text"
                      placeholder="dd-mm-yyyy"
                      id="date"
                      maxLength="10"
                      value={dateTime.date}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="">
                    <label htmlFor="time">Time:</label>
                    <input
                      type="text"
                      placeholder="hh:mm am/pm - hh:mm am/pm"
                      id="time"
                      maxLength="19"
                      value={dateTime.time}
                      onChange={handleChange2}
                      onKeyDown={handleKeyDown2}
                      // onBlur={handleBlur2}
                    />
                  </div>
                </div>
              </div>
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <div className="mid-div">
        <div className="complaints-div">
          <div className="title cv">
            <div className="title-left-div">
              <h2>Complaints History:</h2>
            </div>
          </div>
          <div className="complaints-body">
            <div className="complaints-body-header">
              <div className="complaints-header-item">
                <h4>Complainents Details</h4>
              </div>
              <div className="complaints-header-item">
                <h4>Area of Problem</h4>
              </div>
              <div className="complaints-header-item">
                <h4>Category</h4>
              </div>
              <div className="complaints-header-item">
                <h4>Message</h4>
              </div>
              {/* <div className="complaints-header-item">
                <h4>Status</h4>
              </div> */}
            </div>
            <div className="complaints-body-contents">
              {complaints?.map((complaint, index) => {
                return <ComplaintsElement key={index} complaint={complaint} admin={false} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeedbackReviews;
