import React, { useEffect, useState } from 'react';
import apis from '../apis';
import FlipCountdown from '@rumess/react-flip-countdown';

const GenerateTimer = ({ schedularType }) => {
  // const [ws, setWs] = useState(null);
  const [timer, setTimer] = useState(null);
  const [dateAndTime, setDateAndTime] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [limit1, setLimit1] = useState(0);
  const [limit2, setLimit2] = useState(0);
  const [allow, setAllow] = useState('');
  const [allowRolls, setAllowRolls] = useState([]);
  const [currSession, setCurrSession] = useState('');
  const [semesters, setSemesters] = useState([]);

  const getSessionListData = async () => {
    await apis.getSessionFilterList().then((data) => {
      setCurrSession(data[0]);
    });
  };

  useEffect(() => {
    const url = `${process.env.REACT_APP_WEBSOCKET_URL}`;
    console.log(url);
    const socket = new WebSocket(url);
    // setWs(socket);

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data === null) {
        setIsChecked(false);
      }
      if (
        data.type === `${schedularType}_TIMER_UPDATE` ||
        data.type === `STOP_${schedularType}_TIMER` ||
        data.type === `SET_${schedularType}_TIMER` ||
        data.type === `UPDATE_${schedularType}_TIMER`
      ) {
        if (data.type === `STOP_${schedularType}_TIMER`) {
          setIsChecked(false);
          setLimit1(0);
          setLimit2(0);
          setDateAndTime('');
        }

        if (data.data) {
          setIsChecked(true);
          if (data.data !== null) {
            let newDate = data.data.endTime;

            // add 5hrs 30min to the date
            const date = new Date(newDate);
            date.setHours(date.getHours() + 5);
            date.setMinutes(date.getMinutes() + 30);
            const freshDate = date.toISOString().split('.')[0].slice(0, -3);

            setDateAndTime(freshDate);

            setLimit1(Number(data.data.limit1));
            setLimit2(Number(data.data.limit2));
            if (!data.data.openFor.includes('All')) {
              setAllow('Specific');
              setAllowRolls(data.data.openFor.join(','));
            }
            if (data.data.closeFor.length > 0) {
              setAllow('Exclude');
              setAllowRolls(data.data.closeFor.join(','));
            }
          }
        }
        // console.log(data.data);
        setTimer(data.data);
      }
    };

    getSessionListData();

    // return () => {
    //   socket.close();
    // };
  }, []);

  const setTimerHandler = async () => {
    console.log(semesters);
    if (schedularType === 'MESS') {
      if (limit1 === 0 && limit2 === 0) {
        alert('Please enter mess swap count');
        return;
      }
      if (dateAndTime === '') {
        alert('Please enter end date & time');
        return;
      }
      if (allow === '') {
        alert('Please specify whom to allow');
        return;
      }
    } else if (schedularType === 'HOSTEL') {
      if (dateAndTime === '') {
        alert('Please enter end date & time');
        return;
      }
      if (allow === '') {
        alert('Please specify whom to allow');
        return;
      }
    }

    const endTime = new Date(dateAndTime).toISOString();

    let openForData = [];
    let closeForData = [];
    if (allow === 'Specific') {
      openForData = allowRolls.split(',').map((roll) => roll.trim());
    } else if (allow === 'Exclude') {
      closeForData = allowRolls.split(',').map((roll) => roll.trim());
      openForData = ['All'];
    } else if (allow === 'Specific Semester') {
      openForData = semesters;
    } else {
      openForData = ['All'];
    }

    await apis
      .setSchedule({
        endTime,
        limit1: Number(limit1),
        limit2: Number(limit2),
        totalCnt: Number(limit1) + Number(limit2),
        type: schedularType,
        openFor: openForData,
        closeFor: closeForData,
        session: currSession,
      })
      .then((data) => {
        alert(data);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const updateTimerHandler = async () => {
    const newendTime = new Date(dateAndTime).toISOString();
    let openForData = [];
    let closeForData = [];
    if (allow === 'Specific') {
      openForData = allowRolls.split(',').map((roll) => roll.trim());
    } else if (allow === 'Exclude') {
      closeForData = allowRolls.split(',').map((roll) => roll.trim());
      openForData = ['All'];
    } else {
      openForData = ['All'];
    }
    await apis
      .updateSchedule({
        endTime: newendTime,
        limit1: Number(limit1),
        limit2: Number(limit2),
        totalCnt: Number(limit1) + Number(limit2),
        type: schedularType,
        openFor: openForData,
        closeFor: closeForData,
        session: currSession,
      })
      .then((data) => {
        alert(data);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const stopTimerHandler = async () => {
    await apis
      .removeSchedule(schedularType)
      .then((data) => {
        alert(data);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const performOperation = (operation) => {
    if (operation === 'stop') {
      setIsChecked(false);
      if (timer || (timer && timer.isActive)) {
        stopTimerHandler();
      }
      setLimit1(0);
      setLimit2(0);
      setDateAndTime('');
    } else {
      setIsChecked(true);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <div className="headPart" style={{ width: '100%' }}>
        <p style={{ textAlign: 'center' }}>
          Open window for {schedularType === 'MESS' ? 'Mess Swap' : 'Hostel Allotment'}{' '}
        </p>
        <div
          className={`switch ${isChecked ? 'on' : 'off'}`}
          // onClick={handleCheckboxChange}
        >
          <span
            className="checkState"
            onClick={() => performOperation(isChecked ? 'stop' : 'start')}
          >
            {isChecked ? 'OPENED' : 'CLOSED'}
          </span>
          <div className="slider"></div>
        </div>
      </div>
      {timer && (
        <div className="" style={{ width: '100%', margin: '10px auto' }}>
          <FlipCountdown
            hideYear
            hideMonth={new Date(timer.endTime) - new Date() <= 30 * 24 * 60 * 60 * 1000}
            endAt={timer.endTime}
            // onTimeUp={() => stopTimerHandler()}
            size={'small'}
            theme={'dark'}
          />
        </div>
      )}
      {isChecked && (
        <div className="formPart">
          {(schedularType === 'MESS' || schedularType === 'HOSTEL') && (
            <div className="items7">
              <span>Total Students Count</span>
              <input
                type="number"
                name="student"
                id="student"
                value={
                  timer && timer.totalCnt > 0 ? timer.totalCnt : Number(limit1) + Number(limit2)
                }
                disabled
                className="studentCnt"
                style={{
                  cursor: 'not-allowed',
                }}
              />
            </div>
          )}
          {schedularType === 'MESS' && (
            <div className="items7">
              <span>
                Remaining Swap Count <br /> from KBH {' -> '} VCL
              </span>
              <input
                type="number"
                name="student"
                id="student"
                value={limit2}
                onChange={(e) => setLimit2(e.target.value)}
                className="studentCnt"
              />
            </div>
          )}
          {schedularType === 'HOSTEL' && (
            <div className="items7">
              <span>
                Remaining Seat Count for <br /> all Girls and First year boys
              </span>
              <input
                type="number"
                name="student"
                id="student"
                value={limit2}
                onChange={(e) => setLimit2(e.target.value)}
                className="studentCnt"
              />
            </div>
          )}
          {schedularType === 'MESS' && (
            <div className="items7">
              <span>
                Remaining Swap Count <br /> from VCL {' -> '} KBH
              </span>
              <input
                type="number"
                name="student"
                id="student"
                value={limit1}
                onChange={(e) => setLimit1(e.target.value)}
                className="studentCnt"
              />
            </div>
          )}
          {schedularType === 'HOSTEL' && (
            <div className="items7">
              <span>
                Remaining Seat Count for
                <br /> Remaining Students
              </span>
              <input
                type="number"
                name="student"
                id="student"
                value={limit1}
                onChange={(e) => setLimit1(e.target.value)}
                className="studentCnt"
              />
            </div>
          )}
          <div className="items7">
            <span>End Date & time</span>
            <input
              type="datetime-local"
              name="start"
              id="start"
              value={dateAndTime}
              onChange={(e) => {
                setDateAndTime(e.target.value);
                console.log(e.target.value);
              }}
            />
          </div>
          <div className="items7">
            <span>Allow</span>
            <select value={allow} onChange={(e) => setAllow(e.target.value)}>
              <option value="">Select</option>
              <option value="All">All</option>
              <option value="Specific">Specific</option>
              {schedularType === 'HOSTEL' && (
                <option value="Specific Semester">Specific Semester</option>
              )}
              <option value="Exclude">All Except</option>
            </select>
          </div>
          {allow === 'Specific' && (
            <textarea
              value={allowRolls}
              onChange={(e) => setAllowRolls(e.target.value)}
              placeholder="Enter Roll Numbers separated by comma"
              style={{ width: '100%', minHeight: '100px', maxHeight: '200px' }}
            />
          )}
          {allow === 'Exclude' && (
            <textarea
              value={allowRolls}
              onChange={(e) => setAllowRolls(e.target.value)}
              placeholder="Enter Roll Numbers separated by comma"
              style={{ width: '100%', minHeight: '100px', maxHeight: '200px' }}
            />
          )}
          {allow === 'Specific Semester' && (
            <div className="items7">
              <span>Choose the Semesters</span>
              <select
                multiple
                onChange={(e) => {
                  setSemesters(Array.from(e.target.selectedOptions, (option) => option.value));
                }}
              >
                <option value="1">1st</option>
                <option value="2">2nd</option>
                <option value="3">3rd</option>
                <option value="4">4th</option>
                <option value="5">5th</option>
                <option value="6">6th</option>
                <option value="7">7th</option>
                <option value="8">8th</option>
              </select>
            </div>
          )}
        </div>
      )}
      {isChecked && (
        <div className="buttonPart">
          {timer || (timer && timer.isActive) ? (
            <button onClick={updateTimerHandler} className="primary">
              Update
            </button>
          ) : (
            <button onClick={setTimerHandler} className="primary">
              Save
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default GenerateTimer;
