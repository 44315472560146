import * as React from 'react';
import AdminHeader from '../components/AdminHeader';
import QuickAction from '../components/QuickAction';
import SearchStudent from '../components/SearchStudent';
import StudentListBody from '../components/StudentListBody';
import '../css/Page/AdminPage.css';
import SearchProvider, { useSearch } from '../contexts/SearchProvider';
import apis from '../apis';
import OtherActions from '../components/OtherActions';
import Loader from '../components/Loader';
import { AuthProvider } from '../auth/AuthContext';

const StudentList = ({ page }) => {
  const { searchResult } = useSearch();
  const { setDownload } = useSearch();
  React.useEffect(() => {
    setDownload(searchResult);
  }, [searchResult]);

  return (
    <>
      {searchResult
        .filter((e, i) => (i >= page * 50 && i < page * 50 + 50 ? true : false))
        .map((e, index) => {
          return <StudentListBody key={e.id} data={e} index={index + page * 50 + 1} />;
        })}
    </>
  );
};

const ShowSearchTable = () => {
  const [page, setPage] = React.useState(-1);
  const { searchResult, searchLoading } = useSearch();

  const [loadingStatus, setLoadingStatus] = React.useState(true);

  React.useEffect(() => {
    if (searchResult.length > 0) {
      setPage(0);
      setLoadingStatus(false);
    }
  }, [searchResult]);
  return (
    <>
      <div className="mid-div">
        <SearchStudent />
      </div>
      <div className="table-div">
        <div className="student-list">
          <div className="student-list-header">
            <div className="header-element">
              <h3>S.N.</h3>
            </div>
            <div className="header-element">
              <h3>Roll Number</h3>
            </div>
            <div className="header-element">
              <h3>Name</h3>
            </div>
            <div className="header-element">
              <h3>Course</h3>
            </div>
            <div className="header-element">
              <h3>Batch</h3>
            </div>
            <div className="header-element">
              <h3>Semester</h3>
            </div>
            <div className="header-element">
              <h3>Inst. Fee</h3>
            </div>
            <div className="header-element">
              <h3>Hostel status</h3>
            </div>
            <div className="header-element">
              <h3>Host. Fee</h3>
            </div>
            <div className="header-element">
              <h3>Mess Fee</h3>
            </div>
            <div className="header-element">
              <h3>Registration</h3>
            </div>
            <div className="header-element">
              <h3>Total Due</h3>
            </div>
          </div>
          <div className="student-list-bodies">
            {loadingStatus || searchLoading ? <Loader /> : <StudentList page={page} />}
          </div>
          <div className="pagination">
            <button className="new_btn" onClick={() => setPage(page > 0 ? page - 1 : page)}>
              <i className="fa-solid fa-arrow-left"></i>
            </button>
            <div className="page-number">
              <p>
                {page + 1} / {Math.ceil(searchResult.length / 50)}
              </p>
            </div>
            <button
              className="new_btn"
              onClick={() => setPage(page + 1 < searchResult.length / 50 ? page + 1 : page)}
            >
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
      <DownloadCsv />
    </>
  );
};

const DownloadCsv = () => {
  const { download } = useSearch();
  async function downloadCsv() {
    const headers = [
      {
        label: 'Application Id',
        value: 'applicationid',
      },
      {
        label: 'Roll',
        value: 'roll',
      },
      {
        label: 'Name',
        value: 'name',
      },
      {
        label: 'Email',
        value: 'email',
      },
      {
        label: 'Course',
        value: 'course',
      },
      {
        label: 'Branch',
        value: 'branch',
      },
      {
        label: 'Semester',
        value: 'semester',
      },
      {
        label: 'Batch',
        value: 'batch',
      },
      {
        label: 'Institute Fee Status',
        value: 'paymentStatusSplit.institute',
      },
      {
        label: 'Hostel Fee Status',
        value: 'paymentStatusSplit.hostel',
      },
      {
        label: 'Mess Fee Status',
        value: 'paymentStatusSplit.mess',
      },
      {
        label: 'Overall Payment Status',
        value: 'paymentStatus',
      },
      {
        label: 'Registration Status',
        value: 'registrationStatus',
      },

      {
        label: 'Hostel',
        value: 'hostel',
      },
      {
        label: 'Opening Balance',
        value: 'pastDue',
      },
      {
        label: 'Institute Fee',
        value: 'instituteFees',
      },
      {
        label: 'Convocation Fee',
        value: 'convocationfee',
      },
      {
        label: 'Hostel Fee',
        value: 'hostelFees',
      },
      {
        label: 'Mess Fee',
        value: 'messFees',
      },
      {
        label: 'JOSAA/CSAB/CCMT Fee',
        value: 'alreadyPaid',
      },
      {
        label: 'Hostel Adjustment',
        value: 'hostelAdjust',
      },
      {
        label: 'Mess Adjustment',
        value: 'messAdjust',
      },
      {
        label: 'Fine Charged',
        value: 'totalFine',
      },
      {
        label: 'Fine Waived',
        value: 'instAdjust',
      },
      {
        label: 'Net Fine',
        value: 'netFine',
      },
      {
        label: 'Total Fee Payable',
        value: 'totalPayable',
      },
      {
        label: 'Payment Received(as per bank)',
        value: 'totalBankPaid',
      },
      {
        label: 'Refund Amount',
        value: 'refundAmount',
      },
      {
        label: 'Total Due',
        value: 'totalDue',
      },
      {
        label: 'Overall Remarks',
        value: '',
      },
    ];

    let downloadData = download;
    //remove the data where download.roll is null
    downloadData = downloadData.filter((e) => e.roll !== null);

    await apis
      .downloadCsv(downloadData, headers, 'feeDetails.csv')
      .then((data) => {
        let url = window.URL.createObjectURL(data);
        window.open(url, +'_blank');
        alert('Downloaded');
      })
      .catch((err) => {
        alert(err.message);
      });
  }
  return (
    <div className="result-footer">
      <div className="result-footer-title">
        <h2>Export Results</h2>
        <p>(Get an offline .csv file of above data)</p>
      </div>
      <div className="result-footer-button">
        <button onClick={() => downloadCsv()}>
          Start exporting now as .csv file <i className="fa-solid fa-circle-arrow-right"></i>
        </button>
      </div>
    </div>
  );
};

const AdminPage = () => {
  const { adminData } = AuthProvider();
  return (
    <>
      <AdminHeader />
      {adminData?.role !== 'Faculty Advisor' &&
        adminData?.role !== 'Faculty' &&
        adminData?.role !== 'HOD' && (
          <div className="top-div">
            <QuickAction />
          </div>
        )}
      <div className="mid-div">
        <OtherActions />
      </div>
      <SearchProvider>
        <ShowSearchTable />
      </SearchProvider>
    </>
  );
};

export default AdminPage;
