import * as React from 'react';
import { useState } from 'react';
import '../css/Component/PaySection.css';
import { AuthProvider } from '../auth/AuthContext';
import apis from '../apis';
import jwtVerify from '../auth/service';
import GatewayPopUp from './GatewayPopUp';
import { useNavigate } from 'react-router-dom';
import ButtonLoader from './ButtonLoader';
import MessApplication from './MessApplication';
import HostelApplication from './HostelApplication';

const PaySection = () => {
  const { userData } = AuthProvider();
  const [year, setYear] = React.useState('');
  const [semester, setSemester] = React.useState('');
  const [feeStructure, setFeeStructure] = React.useState({});
  const [totalFee, setTotalFee] = React.useState(0);
  const [otherTotalFee, setOtherTotalFee] = React.useState(0);
  const [fineDetails, setFineDetails] = React.useState();
  const [hostelApplyLoading, setHostelApplyLoading] = React.useState(false);
  const [receiptUpload, setReceiptUpload] = React.useState({
    file: null,
    amount: '',
    refNo: '',
    paymentDate: '',
  });
  const [hostelApplication, setHostelApplication] = React.useState({
    optHostel: false,
    prefHostel: '',
  });
  const [hostelStatus, setHostelStatus] = React.useState({
    status: 'Not Applied',
    allotedHostel: 'Not Alloted',
    canPay: false,
    room: 'NA',
  });
  const navigate = useNavigate();

  const allotHostelFee = () => {
    if (hostelStatus?.allotedHostel === 'H1') {
      return Number(feeStructure?.h1fee);
    } else if (hostelStatus?.allotedHostel === 'H9') {
      return Number(feeStructure?.h9fee);
    } else if (hostelStatus?.allotedHostel === 'H7') {
      return Number(feeStructure?.h7fee);
    } else if (hostelStatus?.allotedHostel === 'NBH') {
      return Number(feeStructure?.nbhfee);
    } else if (hostelStatus?.allotedHostel === 'GH') {
      return Number(feeStructure?.ghfee);
    } else if (hostelStatus?.allotedHostel === 'KBH') {
      return Number(feeStructure?.kbhfee);
    } else if (hostelStatus?.allotedHostel === 'KGH') {
      return Number(feeStructure?.kghfee);
    }
  };

  function convertDate(inputFormat) {
    function pad(s) {
      return s < 10 ? '0' + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/');
  }

  React.useEffect(() => {
    setYear(userData?.year);
    setSemester(userData?.semester);
    async function defaultFetch() {
      await jwtVerify.getUserData().then(async (result) => {
        await apis
          .specificStudent({ roll: result?.roll, semester: userData?.semester })
          .then(async (data) => {
            await apis
              .feeStructure(
                result?.year,
                result?.course,
                result?.semester,
                result?.batch,
                data[0]?.type
              )
              .then((result1) => {
                setFeeStructure(result1);
                let total =
                  Number(result1?.tuitionfee) +
                  Number(result1?.otherfee) +
                  Number(result1?.admissionfee) +
                  Number(result1?.cautionmoney) +
                  Number(result1?.convocationfee);
                setTotalFee(total);
                let otherTotal = Number(allotHostelFee());
                setOtherTotalFee(otherTotal);
              })
              .catch((err) => {
                alert(err);
              });
          })
          .catch((err) => {
            alert(err);
          });
      });
    }

    async function fetchHostelStatus() {
      await apis
        .hostelStatus()
        .then((result) => {
          setHostelStatus({
            status: result?.status,
            allotedHostel: result?.hostel,
            mess: result?.mess,
            canPay: result?.canPay,
            room: result?.room,
          });
        })
        .catch((err) => {
          alert(err.message);
        });
    }

    async function fetchFeeDetails() {
      userData?.roll &&
        (await apis
          .specificStudent({ roll: userData?.roll, semester: userData?.semester })
          .then((data) => {
            setFineDetails({
              instFine: data[0]?.instFine,
              hostelFine: data[0]?.hostelFine,
              messFine: data[0]?.messFine,
            });
          })
          .catch((err) => {
            alert(err.message);
          }));
    }

    fetchFeeDetails();
    fetchHostelStatus();
    defaultFetch();
  }, [userData]);

  // async function fetchData() {
  //   await apis
  //     .feeStructure(year, userData?.course, semester, userData?.batch)
  //     .then((data) => {
  //       setFeeStructure(data);
  //       let total =
  //         Number(data.tuitionfee) +
  //         Number(data.otherfee) +
  //         Number(data.admissionfee) +
  //         Number(data.cautionmoney);
  //       setTotalFee(total);
  //       let otherTotal =
  //         Number(allotHostelFee()) + Number(data.hostelcautionmoney);
  //       setOtherTotalFee(otherTotal);
  //     })
  //     .catch((err) => {
  //       alert(err);
  //     });
  // }

  async function applyHostel() {
    // if(allotHostelFee() +
    // Number(fineDetails?.hostelFine) -
    // paymentData?.currentSemesterPaid?.hostel > 0){
    //   alert("Please pay the hostel fee first");
    //   return;
    // }

    if (hostelApplication?.optHostel === '') {
      alert('Please select if you want hostel accomodation');
      return;
    }
    if (hostelApplication?.prefHostel === '') {
      alert('Please select preferred hostel');
      return;
    }
    setHostelApplyLoading(true);
    await apis
      .applyHostel({
        prefHostel: hostelApplication?.prefHostel,
      })
      .then((result) => {
        setHostelApplyLoading(false);
        alert('Hostel application submitted successfully');
        window.location.reload();
      })
      .catch((err) => {
        setHostelApplyLoading(false);
        alert(err);
      });
  }

  const [OpenBtnLoader, setBtnLoader] = useState(false);

  const submitReceipt = async () => {
    if (receiptUpload.file === null) {
      alert('Please select a file');
      return;
    }
    if (receiptUpload.amount === '') {
      alert('Please enter amount');
      return;
    }
    if (receiptUpload.refNo === '') {
      alert('Please enter reference number');
      return;
    }
    if (receiptUpload.paymentDate === '') {
      alert('Please enter payment date');
      return;
    }
    setBtnLoader(true);

    let formData = new FormData();
    formData.append('fee', receiptUpload.file);
    formData.append('roll', userData.roll);
    formData.append('semester', userData.semester ? userData.semester : semester);
    formData.append('amount', receiptUpload.amount);
    formData.append('refno', receiptUpload.refNo);
    formData.append('type', 'Offline');
    formData.append('date', convertDate(receiptUpload.paymentDate));
    formData.append('verifiedBy', 'NA');

    await apis
      .addReceipt(formData)
      .then((result) => {
        alert('Receipt uploaded successfully');
        setBtnLoader(false);
        navigate('/student/feepayment');
      })
      .catch((err) => {
        setBtnLoader(false);
        alert(err);
      });
  };

  React.useEffect(() => {
    if (year === '1') setSemester('1');
    else if (year === '2') setSemester('3');
    else if (year === '3') setSemester('5');
    else setSemester('7');
  }, [year]);

  const [openGateway, setOpenGateway] = useState({
    open: false,
    type: '',
  });

  // const { userData } = AuthProvider();
  const [paymentData, setPaymentData] = useState();
  // const [feeStructure, setFeeStructure] = useState();
  React.useEffect(() => {
    const fetchData = async () => {
      userData.roll &&
        apis
          .specificStudent({ roll: userData?.roll, semester: userData?.semester })
          // apis.feeStructure(
          //   userData?.year,
          //   userData?.course,
          // userData?.semester,
          //   userData?.batch
          // ),

          .then((data) => {
            setPaymentData(data[0]);

            // setFeeStructure(data[1]);
          })
          .catch((err) => {
            alert(err.message);
          });
    };
    fetchData();
  }, [userData]);

  return (
    <>
      <GatewayPopUp
        open={openGateway.open}
        types={openGateway.type}
        userData={userData}
        onClose={() => setOpenGateway(false)}
      />
      <div className="paysection">
        <div className="paysection-div">
          <div className="paysection-title cv">
            <h2>Fee Payment</h2>
            <h2>
              For: <span>{userData?.semester} Semester</span>
            </h2>
          </div>
          <div className="content-div">
            {/* <div className="filter-items">
              <div className="filter-item">
                <h4 htmlFor="year">Select Year:</h4>
                <select
                  name="year"
                  id="year"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                >
                  <option value="1">1st Year</option>
                  <option value="2">2nd Year</option>
                  <option value="3">3rd Year</option>
                  <option value="4">4th Year</option>
                </select>
              </div>
              <div className="filter-item">
                <h4 htmlFor="semester">Select Semester:</h4>

                <select
                  name="semester"
                  id="semester"
                  value={semester}
                  onChange={(e) => setSemester(e.target.value)}
                >
                  {year === "1" && <option value="1">1st</option>}
                  {year === "1" && <option value="2">2nd</option>}
                  {year === "2" && <option value="3">3rd</option>}
                  {year === "2" && <option value="4">4th</option>}
                  {year === "3" && <option value="5">5th</option>}
                  {year === "3" && <option value="6">6th</option>}
                  {year === "4" && <option value="7">7th</option>}
                  {year === "4" && <option value="8">8th</option>}
                </select>
              </div>
              <div className="filter-item btn-div">
                <button
                  id="search"
                  className="new_btn"
                  onClick={() => fetchData()}
                >
                  <span>Search</span>
                  <img src="/assets/search-icon.svg" alt="search-icon" />
                </button>
              </div>
            </div> */}
            <div className="result-items">
              <div className="fee-table">
                <div className="types">
                  <div className="type">
                    <div className="type-name">
                      <h4>Opening Balance</h4>
                    </div>
                    <div className="type-contents">
                      <div className="type-content">
                        <div className="content-count">
                          <h4>1.</h4>
                        </div>
                        <div className="content-name">
                          <h4>Opening Balance/Past Due</h4>
                        </div>
                        <div className="content-price">
                          <h4>
                            <span>₹</span> {paymentData?.pastDue}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="type">
                    <div className="type-name">
                      <h4>Institute Fee (Reccurning)</h4>
                    </div>
                    <div className="type-contents">
                      <div className="type-content">
                        <div className="content-count">
                          <h4>2.</h4>
                        </div>
                        <div className="content-name">
                          <h4>Tution Fee</h4>
                        </div>
                        <div className="content-price">
                          <h4>
                            <span>₹</span> {feeStructure?.tuitionfee}
                          </h4>
                        </div>
                      </div>
                      <div className="type-content">
                        <div className="content-count">
                          <h4>3.</h4>
                        </div>
                        <div className="content-name">
                          <h4>Other Institute Fee</h4>
                        </div>
                        <div className="content-price">
                          <h4>
                            <span>₹</span> {feeStructure?.otherfee}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="type">
                    <div className="type-name">
                      <h4>One Time Fee:</h4>
                    </div>
                    <div className="type-contents">
                      <div className="type-content">
                        <div className="content-count">
                          <h4>4.</h4>
                        </div>
                        <div className="content-name">
                          <h4>Admission Related Fee (Non-refundable)</h4>
                        </div>
                        <div className="content-price">
                          <h4>
                            <span>₹</span> {feeStructure?.admissionfee}
                          </h4>
                        </div>
                      </div>
                      <div className="type-content">
                        <div className="content-count">
                          <h4>5.</h4>
                        </div>
                        <div className="content-name">
                          <h4>Caution Money</h4>
                        </div>
                        <div className="content-price">
                          <h4>
                            <span>₹</span>{' '}
                            {hostelStatus?.allotedHostel === 'KBH' ||
                            hostelStatus?.allotedHostel === 'KGH'
                              ? feeStructure?.cautionmoney
                              : 0}
                          </h4>
                        </div>
                      </div>
                      <div className="type-content">
                        <div className="content-count">
                          <h4>6.</h4>
                        </div>
                        <div className="content-name">
                          <h4>Convocation Fee</h4>
                        </div>
                        <div className="content-price">
                          <h4>
                            <span>₹</span> {feeStructure?.convocationfee}
                          </h4>
                        </div>
                      </div>
                      <div className="type-content">
                        <div className="content-count">
                          <h4>7.</h4>
                        </div>
                        <div className="content-name">
                          <h4>Fine</h4>
                        </div>
                        <div className="content-price">
                          <h4>
                            <span>₹</span> {fineDetails?.instFine}
                          </h4>
                        </div>
                      </div>
                      {/* <div className="type-content">
                        <div className="content-count">
                          <h4>7.</h4>
                        </div>
                        <div className="content-name">
                          <h4>Adjusted Fee</h4>
                        </div>
                        <div className="content-price">
                          <h4>
                            <span>₹</span> {paymentData?.instAdjust}
                          </h4>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="type">
                    <div className="type-name total-title">
                      <h4>Total Institute Fee</h4>
                    </div>
                    <div className="type-contents type-total">
                      <div className="type-content">
                        <div className="content-price total-value">
                          <h4>
                            <span>₹</span>
                            {totalFee + Number(fineDetails?.instFine) + Number(paymentData?.pastDue)}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="payment-button">
                <div className="payment-title">
                  <h2>
                    Paid: <span>{`${paymentData?.currentSemesterPaid?.institute}`} </span>
                  </h2>
                  <h2>
                    Payable:{' '}
                    <span>{`${
                      totalFee +
                      Number(paymentData?.pastDue) +
                      Number(fineDetails?.instFine) -
                      Number(paymentData?.currentSemesterPaid?.institute)
                    }`}</span>
                  </h2>
                </div>
                <button
                  id="pay-now"
                  className="pay_btn"
                  onClick={() =>
                    setOpenGateway({
                      open: true,
                      type: 'institute',
                    })
                  }
                >
                  Proceed to Pay<i className="fa-solid fa-circle-right"></i>
                </button>
              </div>
            </div>

            <HostelApplication
              hostelStatus={hostelStatus}
              userData={userData}
              hostelApplication={hostelApplication}
              setHostelApplication={setHostelApplication}
              applyHostel={applyHostel}
            />
            <MessApplication
              hostelStatus={hostelStatus}
              userData={userData}
              payable={
                Number(feeStructure?.messfee) +
                Number(fineDetails?.messFine) -
                paymentData?.currentSemesterPaid?.mess
              }
              hostelApplyLoading={hostelApplyLoading}
            />
            {hostelStatus && hostelStatus.canPay && (
              <>
                <div className="result-items">
                  <div className="fee-table">
                    <div className="types">
                      <div className="type">
                        <div className="type-name">
                          <h4>Hostel Fee:</h4>
                        </div>
                        <div className="type-contents">
                          <div className="type-content">
                            <div className="content-count">
                              <h4>1.</h4>
                            </div>
                            <div className="content-name">
                              <h4>Hostel Fee</h4>
                            </div>
                            <div className="content-price">
                              <h4>
                                <span>₹</span> {allotHostelFee()}
                              </h4>
                            </div>
                          </div>
                          <div className="type-content">
                            <div className="content-count">
                              <h4>2.</h4>
                            </div>
                            <div className="content-name">
                              <h4>Fine</h4>
                            </div>
                            <div className="content-price">
                              <h4>
                                <span>₹</span> {fineDetails?.hostelFine}
                              </h4>
                            </div>
                          </div>
                          {/* <div className="type-content">
                            <div className="content-count">
                              <h4>3.</h4>
                            </div>
                            <div className="content-name">
                              <h4>Adjusted Fee</h4>
                            </div>
                            <div className="content-price">
                              <h4>
                                <span>₹</span> {paymentData?.hostelAdjust}
                              </h4>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="type">
                        <div className="type-name total-title">
                          <h4>Total (Hostel + fine)</h4>
                        </div>
                        <div className="type-contents type-total">
                          <div className="type-content">
                            <div className="content-price total-value">
                              <h4>
                                <span>₹</span>
                                {allotHostelFee() + Number(fineDetails?.hostelFine)}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="payment-button">
                    <div className="payment-title">
                      <h2>
                        Paid: <span>{`${paymentData?.currentSemesterPaid?.hostel}`} </span>
                      </h2>
                      <h2>
                        Payable:{' '}
                        <span>{`${
                          allotHostelFee() +
                          Number(fineDetails?.hostelFine) -
                          paymentData?.currentSemesterPaid?.hostel
                        }`}</span>
                      </h2>
                    </div>
                    <button
                      id="pay-now"
                      className="pay_btn"
                      onClick={() =>
                        setOpenGateway({
                          open: true,
                          type: 'hostel',
                        })
                      }
                    >
                      Proceed to Pay<i className="fa-solid fa-circle-right"></i>
                    </button>
                  </div>
                </div>
                <div className="result-items">
                  <div className="fee-table">
                    <div className="types">
                      <div className="type">
                        <div className="type-name">
                          <h4>Mess Fee:</h4>
                        </div>
                        <div className="type-contents">
                          <div className="type-content">
                            <div className="content-count">
                              <h4>1.</h4>
                            </div>
                            <div className="content-name">
                              <h4>Mess Fee</h4>
                            </div>
                            <div className="content-price">
                              <h4>
                                <span>₹</span> {feeStructure?.messfee}
                              </h4>
                            </div>
                          </div>

                          <div className="type-content">
                            <div className="content-count">
                              <h4>2.</h4>
                            </div>
                            <div className="content-name">
                              <h4>Fine</h4>
                            </div>
                            <div className="content-price">
                              <h4>
                                <span>₹</span> {fineDetails?.messFine}
                              </h4>
                            </div>
                          </div>
                          {/* <div className="type-content">
                            <div className="content-count">
                              <h4>3.</h4>
                            </div>
                            <div className="content-name">
                              <h4>Adjusted Fee</h4>
                            </div>
                            <div className="content-price">
                              <h4>
                                <span>₹</span> {paymentData?.messAdjust}
                              </h4>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="type">
                        <div className="type-name total-title">
                          <h4>Total (Mess + Fine)</h4>
                        </div>
                        <div className="type-contents type-total">
                          <div className="type-content">
                            <div className="content-price total-value">
                              <h4>
                                <span>₹</span>
                                {Number(feeStructure?.messfee) + Number(fineDetails?.messFine)}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="payment-button">
                    <div className="payment-title">
                      <h2>
                        Paid: <span>{`${paymentData?.currentSemesterPaid?.mess}`} </span>
                      </h2>
                      <h2>
                        Payable:{' '}
                        <span>{`${
                          Number(feeStructure?.messfee) +
                          Number(fineDetails?.messFine) -
                          paymentData?.currentSemesterPaid?.mess
                        }`}</span>
                      </h2>
                    </div>
                    <button
                      id="pay-now"
                      className="pay_btn"
                      onClick={() =>
                        setOpenGateway({
                          open: true,
                          type: 'mess',
                        })
                      }
                    >
                      Proceed to Pay<i className="fa-solid fa-circle-right"></i>
                    </button>
                  </div>
                </div>
              </>
            )}
            <div className="upload-items">
              <div className="upload-title">
                <h4>
                  Upload Reciept (pdf): <span>If you have already paid using Loan/Scholarship</span>
                </h4>
              </div>
              <div className="upload-buttons">
                <input
                  type="file"
                  accept="application/pdf"
                  value={receiptUpload?.file?.filename}
                  onChange={(e) => {
                    setReceiptUpload((r) => {
                      return { ...r, file: e.target.files[0] };
                    });
                  }}
                />
                <input
                  class="text-input"
                  type="number"
                  placeholder="Just amount in INR "
                  value={receiptUpload?.amount}
                  onChange={(e) => {
                    setReceiptUpload((r) => {
                      return { ...r, amount: e.target.value };
                    });
                  }}
                />
                <input
                  class="text-input"
                  type="text"
                  placeholder="Enter Transaction ID"
                  value={receiptUpload?.refNo}
                  onChange={(e) => {
                    setReceiptUpload((r) => {
                      return { ...r, refNo: e.target.value };
                    });
                  }}
                />
                <input
                  class="text-input"
                  type="date"
                  value={receiptUpload?.paymentDate}
                  onChange={(e) => {
                    setReceiptUpload((r) => {
                      return { ...r, paymentDate: e.target.value };
                    });
                  }}
                />
                {OpenBtnLoader === true ? (
                  <button
                    id="submit"
                    type="submit"
                    disabled={OpenBtnLoader}
                    style={{
                      cursor: OpenBtnLoader ? 'not-allowed' : 'pointer',
                    }}
                  >
                    <ButtonLoader />
                  </button>
                ) : (
                  <button
                    id="submit"
                    type="submit"
                    disabled={OpenBtnLoader}
                    style={{
                      cursor: OpenBtnLoader ? 'not-allowed' : 'pointer',
                    }}
                    onClick={() => submitReceipt()}
                  >
                    Submit now
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaySection;
