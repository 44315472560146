import React from 'react';

const HostelContext = React.createContext();

function HostelProvider({ children }) {
  const [selectedData, setSelectedData] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [newHostelData, setNewHostelData] = React.useState();
  return (
    <HostelContext.Provider
      value={{
        selectedData,
        setSelectedData,
        selectAll,
        setSelectAll,
        newHostelData,
        setNewHostelData,
      }}
    >
      {children}
    </HostelContext.Provider>
  );
}

export const useHostelContext = () => {
  return React.useContext(HostelContext);
};

export default HostelProvider;
