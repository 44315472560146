/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import '../css/Component/VerificationRequest.css';
import apis from '../apis';
import api from '../newRegistrationApis';
import { AuthProvider } from '../auth/AuthContext';

const blobToUrl = (receipt) => {
  const byteArray = new Uint8Array(receipt?.data);
  const blob = new Blob([byteArray], {
    type: `application/pdf`,
  });
  return URL.createObjectURL(blob);
};

const VerificationRequest = ({ request, onVerificationComplete  }) => {
  // const [studentName, setStudentName] = React.useState('');
  const [reason, setReason] = React.useState('');
  const [updatedAmount, setUpdatedAmount] = React.useState();
  const { adminData } = AuthProvider();
  // React.useEffect(() => {
  //   const fetchStudent = async () => {
  //     if (request?.applicationid) {
  //       request?.id &&
  //         (await api
  //           .getProfileAdmin(request?.applicationid)
  //           .then((data) => {
  //             setStudentName(data.name);
  //           })
  //           .catch((err) => {
  //             alert(err.message);
  //           }));
  //     } else {
  //       request?.id &&
  //         (await apis
  //           .profileName(request.roll)
  //           .then((data) => {
  //             setStudentName(data.name);
  //           })
  //           .catch((err) => {
  //             alert(err.message);
  //           }));
  //     }
  //   };
  //   fetchStudent();
  // }, [request]);

  const verifyRequest = async () => {
    await apis
      .verifyReceipt(
        request.id,
        `${adminData.role.find((e) => e === 'Academic Admin') ? 'academic' : 'finance'}`,
        adminData.name,
        request.roll,
        request.applicationid,
        request.semester,
        updatedAmount ?? request.amount,
        reason
      )
      .then(async(data) => {
        onVerificationComplete(request.id);
        alert('Request Approved');
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const rejectRequest = async () => {
    await apis
      .rejectReceipt(request.id, 'rejected', adminData.name, reason)
      .then((data) => {
        onVerificationComplete(request.id);
        alert('Request Rejected');
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const fetchReceipt = async () => {
    await apis
      .fetchReceipt(request.id)
      .then((data) => {
        const url = blobToUrl(data.data.receipt);
        window.open(url);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  return (
    <>
      <div className="verification-request">
        <div className="request-header">
          <div className="sender-info">
            <h3>
              Name: <span>{request?.name}</span>
            </h3>
            <h3>
              Roll/Application Id:{' '}
              <span>{request?.roll ? request?.roll : request?.applicationid}</span>
            </h3>
          </div>
          <div className="attachment">
            <a onClick={fetchReceipt} target="_blank" rel="noopener noreferrer">
              <i className="fa-solid fa-file-invoice"></i>{' '}
              {request?.roll ? request?.roll : request?.applicationid}
              _receipt.pdf
            </a>
          </div>
        </div>
        <div className="request-details">
          <h4>
            Amount: <span>{request?.amount}</span>
          </h4>
          <h4>
            Transaction ID: <span>{request?.refno}</span>
          </h4>
          <h4>
            Date: <span>{request.date}</span>
          </h4>
        </div>
        <div className="request-action">
          <div className="action-reason">
            <input
              className="text-input"
              type="text"
              value={reason}
              placeholder="Add Remarks/Reasons"
              onChange={(e) => setReason(e.target.value)}
            />
            <input
              className="text-input"
              type="text"
              placeholder="Update Txn Amount"
              value={updatedAmount}
              onChange={(e) => setUpdatedAmount(e.target.value)}
            />
          </div>
          <div className="action-buttons">
            <button className="primary" onClick={() => verifyRequest()}>
              <i className="fa-solid fa-check"></i>Approve
            </button>
            <button className="secondary" onClick={() => rejectRequest()}>
              <i className="fa-solid fa-xmark"></i>Reject
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerificationRequest;
