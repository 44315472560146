import * as React from 'react';
import apis from '../apis';

const CourseStructureTable = (props) => {
  const [courseStructure, setCourseStructure] = React.useState();
  const [extraCourses, setExtraCourses] = React.useState();
  const userData = props.user;
  let totalCredits = 0;
  let k = 0;

  React.useEffect(() => {
    const fetchCourseStructure = async () => {
      userData &&
        (await apis
          .getCourseStructure({
            semester: `${userData?.semester}`,
            branch: `${userData?.branch}`,
            course: `${userData?.course}`,
            courseType: `${userData?.type}`,
          })
          .then((data) => {
            setCourseStructure(data);
          })
          .catch((err) => alert(err)));
    };
    const fetchExtraCourses = async () => {
      if (props.admin !== true) {
        await apis
          .showExtraCourses({ semester: `${userData?.semester}` })
          .then((data) => {
            setExtraCourses(data);
          })
          .catch((err) => {
            alert(err.message);
          });
      } else {
        await apis
          .showExtraCoursesAdmin({ semester: `${userData?.semester}`, roll: userData?.roll })
          .then((data) => {
            setExtraCourses(data);
          })
          .catch((err) => {
            alert(err.message);
          });
      }
    };
    fetchCourseStructure();
    fetchExtraCourses();
  }, [userData]);
  return (
    <>
      <div className="course-table">
        <div className="course-table-header">
          <div className="course-table-header-item sr-no">
            <h4>S.N.</h4>
          </div>
          <div className="course-table-header-item c-code">
            <h4>Code</h4>
          </div>
          <div className="course-table-header-item c-name">
            <h4>Course Name</h4>
          </div>
          <div className="course-table-header-item points">
            <h4>L</h4>
          </div>
          <div className="course-table-header-item points">
            <h4>T</h4>
          </div>
          <div className="course-table-header-item points">
            <h4>P</h4>
          </div>
          <div className="course-table-header-item points">
            <h4>C</h4>
          </div>
          <div className="course-table-header-item c-type">
            <h4>Type</h4>
          </div>
        </div>
        <div className="course-table-body">
          {courseStructure?.map((e, i) => {
            totalCredits += e.c;
            k++;
            return (
              <div className="course-table-body-items" key={k}>
                <div className="course-table-body-item sr-no">
                  <h4>{k}.</h4>
                </div>
                <div className="course-table-body-item c-code">
                  <h4>{e.code}</h4>
                </div>
                <div className="course-table-body-item c-name">
                  <h4>{e.name}</h4>
                </div>
                <div className="course-table-body-item points">
                  <h4>{e.l}</h4>
                </div>
                <div className="course-table-body-item points">
                  <h4>{e.t}</h4>
                </div>
                <div className="course-table-body-item points">
                  <h4>{e.p}</h4>
                </div>
                <div className="course-table-body-item points">
                  <h4>{e.c}</h4>
                </div>
                <div className="course-table-body-item c-type">
                  <h4>{e.type}</h4>
                </div>
              </div>
            );
          })}
          {extraCourses?.map((e, i) => {
            totalCredits += e.c;
            k++;
            return (
              <div className="course-table-body-items" key={k}>
                <div className="course-table-body-item sr-no">
                  <h4>{k}.</h4>
                </div>
                <div className="course-table-body-item c-code">
                  <h4>{e.courseCode}</h4>
                </div>
                <div className="course-table-body-item c-name">
                  <h4>{e.courseName}</h4>
                </div>
                <div className="course-table-body-item points">
                  <h4>{e.l}</h4>
                </div>
                <div className="course-table-body-item points">
                  <h4>{e.t}</h4>
                </div>
                <div className="course-table-body-item points">
                  <h4>{e.p}</h4>
                </div>
                <div className="course-table-body-item points">
                  <h4>{e.c}</h4>
                </div>
                <div className="course-table-body-item c-type">
                  <h4>{e.courseType}</h4>
                </div>
              </div>
            );
          })}
        </div>
        <div className="course-table-footer">
          <div className="course-table-footer-item">
            <h4>Total Credits</h4>
          </div>
          <div className="course-table-footer-item">
            <h4>{totalCredits ?? 0}</h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseStructureTable;
