import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Component/ActionCenter.css';
import PaymentHistoryTable from './PaymentHistoryTable';

const ActionCenter = ({ roll, applicationid, studentData, semester }) => {
  const [viewPaymentStatus, setViewPaymentStatus] = React.useState(false);

  const togglePaymentStatus = () => {
    setViewPaymentStatus(!viewPaymentStatus);
  };

  return (
    <div className="actionCont">
      <div className="actionButton primary">
        {' '}
        <Link
          to={`/student/admin/newprofile/${roll}/${applicationid}/registrationslip?semester=${semester}`}
          target="_blank"
          style={{ color: 'white' }}
        >
          View Resigtration Slip
        </Link>{' '}
      </div>
      <div className="actionButton primary" onClick={() => togglePaymentStatus()}>
        {viewPaymentStatus ? 'Hide' : 'View'} Payment Status
      </div>

      {viewPaymentStatus && <PaymentHistoryTable admin={true} roll={roll} semester={semester} />}
    </div>
  );
};

export default ActionCenter;
