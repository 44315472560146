import React, { PureComponent, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

class CustomizedLabel extends PureComponent {
  render() {
    const { x, y, stroke, value } = this.props;

    return (
      <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
        {value}
      </text>
    );
  }
}

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
          {payload.value}
        </text>
      </g>
    );
  }
}
const LineChartComp = ({ R_A_AverageCnt, R_B_AverageCnt, R_C_AverageCnt }) => {
  const [datas, setDatas] = useState([]);

  const createDataArray = () => {
    const data = [];

    for (let i = 0; i < R_A_AverageCnt.length; i++) {
      data.push({
        name: `${
          i === 0 ? 'Excellent' : i === 1 ? 'Very Good' : i === 2 ? 'Good' : i === 3 ? 'Fair' : 'Poor'
        }`,
        ['Course Organisation']: R_A_AverageCnt[R_A_AverageCnt.length - i - 1],
        ['Presentation']: R_B_AverageCnt[R_A_AverageCnt.length - i - 1],
        ['Interaction and Report']: R_C_AverageCnt[R_A_AverageCnt.length - i - 1],
      });
    }

    setDatas(data);
  };

  React.useEffect(() => {
    if (R_A_AverageCnt && R_B_AverageCnt && R_C_AverageCnt) {
      createDataArray();
    }
  }, [R_A_AverageCnt, R_B_AverageCnt, R_C_AverageCnt, setDatas]);
  return (
    <ResponsiveContainer width="100%" height="100%">
      <div className="lineTitle">Category wise rating distribution</div>
      <LineChart
        width={500}
        height={300}
        data={datas}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" height={60} tick={<CustomizedAxisTick />} />
        <YAxis />
        <Tooltip />
        <Legend
          layout="vertical"
          height={1}
          // verticalAlign="top"
          // align="right"
          wrapperStyle={{
            top: 30,
            right: 30,
          }}
        />
        <Line
          type="monotone"
          dataKey="Course Organisation"
          stroke="#8884d8"
          label={<CustomizedLabel />}
        />
        <Line type="monotone" dataKey="Presentation" stroke="#82ca9d" label={<CustomizedLabel />} />
        <Line
          type="monotone"
          dataKey="Interaction and Report"
          stroke="#FF4500"
          label={<CustomizedLabel />}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartComp;
