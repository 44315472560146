import React, { useEffect, useMemo, useState } from 'react';
import AdminHeader from '../components/AdminHeader';
import Table from '../common/tables/Table';
import FilterComponent from '../components/FilterComponent';
import apis from '../apis';
import '../css/Page/ComplaintDashboard.css';
import { AuthProvider } from '../auth/AuthContext';

const statusOptions = [
  {
    name: 'Resolved',
    value: 'Resolved',
  },
  {
    name: 'In Review',
    value: 'In Review',
  },
  {
    name: 'Unresolved',
    value: 'Unresolved',
  },
];
const typeOptions = [
  {
    name: 'Electricity/Electrical Appliances',
    value: 'Electrical',
  },
  {
    name: 'Internet',
    value: 'Internet',
  },
  {
    name: 'Civil',
    value: 'Civil',
  },
  {
    name: 'Others',
    value: 'Others',
  },
];
const locationOptions = [
  {
    name: 'Academic Building',
    value: 'Academic',
  },
  {
    name: 'Administrative Building',
    value: 'Administrative',
  },
  {
    name: 'Central Library',
    value: 'Library',
  },
  {
    name: 'Lecture Hall',
    value: 'Lecture',
  },
  {
    name: 'Kautilya Boys Hostel',
    value: 'KBH',
  },
  {
    name: 'Kadambini Girls Hostel',
    value: 'KGH',
  },
  {
    name: 'Hostel 1',
    value: 'H1',
  },
  {
    name: 'Faculty Residence',
    value: 'Faculty Residence',
  },
  {
    name: 'Director Residence',
    value: 'Director Residence',
  },
];

const ComplaintDashboard = () => {
  const { adminData } = AuthProvider();
  const [allComplaints, setAllComplaints] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apis.getAllComplaints();
        setAllComplaints(response);
        setLoading(false);
      } catch (error) {
        console.log('Error: ', error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const [filterAttributes, setFilterAttributes] = useState({
    startDate: '',
    endDate: '',
    status: '',
    type: [],
    location: [],
    sortBy: 'Most Recent',
  });

  const handleFilterChange = (e) => {
    setFilterAttributes({
      ...filterAttributes,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    setFilterAttributes((prev) => ({
      ...prev,
      [name]: checked ? [...prev[name], value] : prev[name].filter((item) => item !== value),
    }));
  };

  const [filteredComplaints, setFilteredComplaints] = useState([]);

  useEffect(() => {
    let complaints = allComplaints;
    if (filterAttributes.startDate) {
      complaints = complaints.filter(
        (complaint) => new Date(complaint.createdAt) >= new Date(filterAttributes.startDate)
      );
    }
    if (filterAttributes.endDate) {
      complaints = complaints.filter(
        (complaint) => new Date(complaint.createdAt) <= new Date(filterAttributes.endDate)
      );
    }
    if (filterAttributes.status) {
      complaints = complaints.filter((complaint) => complaint.status === filterAttributes.status);
    }
    if (filterAttributes.type.length > 0) {
      complaints = complaints.filter((complaint) => filterAttributes.type.includes(complaint.type));
    }
    if (filterAttributes.location.length > 0) {
      complaints = complaints.filter((complaint) =>
        filterAttributes.location.includes(complaint.location)
      );
    }

    setFilteredComplaints(complaints);
  }, [filterAttributes, allComplaints]);

  const [sortedComplaints, setSortedComplaints] = useState([]);
  useEffect(() => {
    let sorted = [...filteredComplaints];
    if (filterAttributes.sortBy === 'Most Recent') {
      sorted.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    } else if (filterAttributes.sortBy === 'Oldest') {
      sorted.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    } else if (filterAttributes.sortBy === 'Upvotes') {
      sorted.sort((a, b) => b.upvotes - a.upvotes);
    }
    setSortedComplaints(sorted);
  }, [filteredComplaints, filterAttributes.sortBy]);

  const [updatedStatus, setUpdatedStatus] = useState('');

  const updateStatus = async () => {
    try {
      const data = {
        ids: selectedComplaints,
        status: updatedStatus,
      };

      const response = await apis.updateComplaintStatus(data);
      alert(response.message);
      if (response) {
        sortedComplaints.forEach((complaint) => {
          if (selectedComplaints.includes(complaint.id)) {
            complaint.status = updatedStatus;
          }
        });
        setSelectedComplaints([]);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  const resetFilters = () => {
    setFilterAttributes({
      startDate: '',
      endDate: '',
      status: '',
      type: [],
      location: [],
      sortBy: 'Most Recent',
    });
  };

  const columnData = [
    {
      Header: '',
      accessor: 'checkbox',
      style: { width: '50px' },
      showContextMenu: false,
    },
    {
      Header: 'Date',
      accessor: 'date',
      style: {
        width: '90px',
        minWidth: '90px',
        cursor: 'pointer',
        paddingLeft: '5px',
        paddingRight: '5px',
      },
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'Position',
      accessor: 'position',
      style: { width: '125px', minWidth: '125px', cursor: 'pointer' },
    },
    {
      Header: 'Subject',
      accessor: 'subject',
      style: { width: '180px', minWidth: '180px', cursor: 'pointer' },
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Avaliability Status',
      accessor: 'availability',
      style: { width: '150px', minWidth: '150px', cursor: 'pointer' },
    },
  ];

  const [selectedComplaints, setSelectedComplaints] = useState([]);

  const handleSelectComplaint = (e, id) => {
    if (e.target.checked) {
      setSelectedComplaints([...selectedComplaints, id]);
    } else {
      setSelectedComplaints(selectedComplaints.filter((complaintId) => complaintId !== id));
    }
  };

  const rowData = useMemo(() => {
    return sortedComplaints.map((complaint, index) => ({
      checkbox: (
        <input
          className="checkbox"
          type="checkbox"
          checked={selectedComplaints.includes(complaint.id)}
          onChange={(e) => handleSelectComplaint(e, complaint.id)}
        />
      ),
      date: complaint.createdAt,
      location: locationOptions.find((location) => location.value === complaint.location).name,
      type: typeOptions.find((type) => type.value === complaint.type)?.name,
      position: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: '100%',
          }}
        >
          <div style={{ textAlign: 'left' }}>{`Floor: ${complaint.position.floor}`}</div>
          <div style={{ textAlign: 'left' }}>{`Block: ${complaint.position.block}`}</div>
          <div style={{ textAlign: 'left' }}>{`Room No.: ${complaint.position.roomNumber}`}</div>
        </div>
      ),
      subject: (
        <ComplaintDetails
          id={complaint.id}
          name={complaint.name}
          email={complaint.email}
          location={locationOptions.find((location) => location.value === complaint.location).name}
          type={typeOptions.find((type) => type.value === complaint.type)?.name}
          subject={complaint.subject}
          description={complaint.description}
        />
      ),
      status: statusOptions.find((status) => status.value === complaint.status).name,
      availability: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: '100%',
          }}
        >
          <div style={{ textAlign: 'left' }}>{`Date: ${complaint.availability.date}`}</div>
          <div style={{ textAlign: 'left' }}>{`Time: ${complaint.availability.time}`}</div>
        </div>
      ),
      className:
        complaint.status === 'Resolved'
          ? 'resolved'
          : complaint.status === 'Unresolved'
          ? 'unresolved'
          : 'inreview',
    }));
  }, [sortedComplaints, selectedComplaints]);

  // const handleSelectAll = (e) => {
  //   if (e.target.checked) {
  //     setSelectedComplaints(filteredComplaints.map(complaint => complaint.id));
  //   } else {
  //     setSelectedComplaints([]);
  //   }
  // }

  return (
    <div>
      <AdminHeader />
      <div className="complaintPage">
        <div className="complaintHeading">Complaint Dashboard</div>
        <div className="complaintContainer">
          <div className="complaintRightPart">
            <Table columns={columnData} loading={loading} data={rowData} key={filterAttributes.sortBy} />
          </div>
          <div className="complaintLeftPart">
            <FilterComponent
              filterAttributes={filterAttributes}
              handleFilterChange={handleFilterChange}
              handleCheckboxChange={handleCheckboxChange}
              resetFilters={resetFilters}
            />
            <div className="complaintUpdateStatusCont">
              <div className="filter-heading9">Update Selected Status:</div>
              <div className="updateStatus">
                <span>Status: </span>
                <select
                  name="status"
                  value={updatedStatus}
                  onChange={(e) => setUpdatedStatus(e.target.value)}
                >
                  <option value="">Select Status</option>
                  {statusOptions.map((status) => (
                    <option key={status.value} value={status.value}>
                      {status.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="buttonCont9">
                <button
                  onClick={() => updateStatus()}
                  className="primary"
                  style={{
                    padding: '8px 25px',
                    fontSize: '16px',
                    borderRadius: '8px',
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComplaintDashboard;

const ComplaintDetails = ({ id, name, email, location, type, subject, description }) => {
  const [showDetails, setShowDetails] = useState(null);

  const onClose = () => {
    setShowDetails(null);
  };

  return (
    <div className="">
      {showDetails ? (
        <div className="complaintDetails">
          <div className="complaintDetailsHeading">
            {subject}
            <button onClick={onClose}>
              <i class="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="complaintDetailsContent">
            <div className="complaintTopPart">
              <div className="" style={{ textAlign: 'left' }}>
                <div>
                  <span style={{ fontWeight: 'bold' }}>Name: </span>
                  {name}
                </div>
                <div>
                  <span style={{ fontWeight: 'bold' }}>Email: </span>
                  {email}
                </div>
              </div>
              <div className="" style={{ textAlign: 'left' }}>
                <div>
                  <span style={{ fontWeight: 'bold' }}>Location: </span>
                  {location}
                </div>
                <div>
                  <span style={{ fontWeight: 'bold' }}>Type: </span>
                  {type}
                </div>
              </div>
            </div>
            <div style={{ marginTop: '20px' }}>
              <div style={{ fontWeight: 'bold', fontSize: '18px' }}>Description </div>
              <div className="complaintDisc">{description}</div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="subjectTitle" onClick={() => setShowDetails(id)}>
        {subject}
      </div>
    </div>
  );
};
