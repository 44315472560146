import React, { useEffect } from 'react';
import { useState } from 'react';
import '../css/Component/StudentListBody.css';
import PersonalDetailsPopUp from './PersonalDetailsPopUp';
import PersonalDocumentsPopUp from './PersonalDocumentsPopUp';
import OtherDocumentsPopUp from './OtherDocumentsPopUp';
import apis from '../apis';
import PaymentHistoryPopUp from './PaymentHistoryPopUp';
import UploadSeatAllotmentLetter from './UploadSeatAllotmentLetter';
import { AuthProvider } from '../auth/AuthContext';

const AdmissionListBody = ({ newStudent, type }) => {
  const { adminData } = AuthProvider();

  const [openPersonalDetails, setOpenPersonalDetails] = useState(false);
  const [openPersonalDocuments, setOpenPersonalDocuments] = useState(false);
  const [openOtherDocuments, setOpenOtherDocuments] = useState(false);
  const [openPaymentHistory, setOpenPaymentHistory] = useState(false);
  const [openSeatAllotmentLetter, setOpenSeatAllotmentLetter] = useState(false);
  const [loading, setLoading] = React.useState(true);
  const [verifiactionStatus, setVerificationStatus] = React.useState({
    personalFiles: false,
    otherFiles: false,
  });

  const [showButtons, setShowButtons] = React.useState({
    photo: false,
    signature: false,
    allotmentletter: false,
    aadhar: false,
    tenth: false,
    twelfth: false,
  });

  useEffect(() => {
    setLoading(true);
    if (newStudent) {
      const checkStep2Verified =
        newStudent.documentVerificationStatus.photo.status === 'Verified' &&
        newStudent.documentVerificationStatus.signature.status === 'Verified' &&
        newStudent.documentVerificationStatus.allotmentletter.status === 'Verified' &&
        newStudent.documentVerificationStatus.aadhar.status === 'Verified' &&
        newStudent.documentVerificationStatus.tenth.status === 'Verified' &&
        newStudent.documentVerificationStatus.twelfth.status === 'Verified';
      const checkStep3Verified =
        newStudent.documentVerificationStatus.medicalCertificate.status === 'Verified' &&
        newStudent.documentVerificationStatus.provisionalDocVerificationLetter.status ===
          'Verified' &&
        newStudent.documentVerificationStatus.application.status === 'Verified';
      setVerificationStatus({
        personalFiles: checkStep2Verified,
        otherFiles: checkStep3Verified,
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [newStudent]);

  const onClosePersonalDocuments = () => {
    setOpenPersonalDocuments(false);

    const checkStep2Verified =
      newStudent.documentVerificationStatus.photo.status === 'Verified' &&
      newStudent.documentVerificationStatus.signature.status === 'Verified' &&
      newStudent.documentVerificationStatus.allotmentletter.status === 'Verified' &&
      newStudent.documentVerificationStatus.aadhar.status === 'Verified' &&
      newStudent.documentVerificationStatus.tenth.status === 'Verified' &&
      newStudent.documentVerificationStatus.twelfth.status === 'Verified';
    setVerificationStatus({
      ...verifiactionStatus,
      personalFiles: checkStep2Verified,
    });
  };

  const onCloseOtherDocuments = () => {
    setOpenOtherDocuments(false);

    const checkStep3Verified =
      newStudent.documentVerificationStatus.medicalCertificate.status === 'Verified' &&
      newStudent.documentVerificationStatus.provisionalDocVerificationLetter.status ===
        'Verified' &&
      newStudent.documentVerificationStatus.application.status === 'Verified';
    setVerificationStatus({
      ...verifiactionStatus,
      otherFiles: checkStep3Verified,
    });
  };

  const openSeatAllotmentLetterFunc = () => {
    if (
      adminData?.role?.includes('JOSAA-CSAB-CCMT Seat Allotment') ||
      adminData?.role?.includes('Super Admin')
    ) {
      setOpenSeatAllotmentLetter(true);
      if (newStudent === null) {
        return;
      }
    } else {
      alert('You are not authorized to allot seat');
    }
  };

  return (
    <>
      {type === 'personalDetails' ? (
        loading ? (
          <div className="apLoading">Loading...</div>
        ) : (
          <div
            className={`ap ${
              newStudent?.personalDetailsFilled ? 'form-completed' : 'form-pending'
            }`}
          >
            <button onClick={() => setOpenPersonalDetails(true)}>
              {`${newStudent?.personalDetailsFilled ? 'See details' : 'Pending'}`}
            </button>
          </div>
        )
      ) : type === 'paymentHistory' ? (
        <div className={`ap`}>
          <button onClick={() => setOpenPaymentHistory(true)}>
            {newStudent?.fees?.paymentStatus ? newStudent?.fees?.paymentStatus : 'Pending'}
          </button>
        </div>
      ) : type === 'personalFiles' ? (
        loading ? (
          <div className="apLoading">Loading...</div>
        ) : (
          <div
            className={`ap ${
              newStudent?.personalFilesFilled
                ? verifiactionStatus.personalFiles
                  ? 'form-completed'
                  : 'form-unverified'
                : 'form-pending'
            }`}
          >
            <button onClick={() => setOpenPersonalDocuments(true)}>
              {`${newStudent?.personalFilesFilled ? 'See details' : 'Pending'}`}
            </button>
          </div>
        )
      ) : type === 'otherFiles' ? (
        loading ? (
          <div className="apLoading">Loading...</div>
        ) : (
          <div
            className={`ap ${
              newStudent?.otherFilesFilled
                ? verifiactionStatus.otherFiles
                  ? 'form-completed'
                  : 'form-unverified'
                : 'form-pending'
            }`}
          >
            <button onClick={() => setOpenOtherDocuments(true)}>{`${
              newStudent?.otherFilesFilled ? 'See details' : 'Pending'
            }`}</button>
          </div>
        )
      ) : type === 'allotSeat' ? (
        loading ? (
          <div className="apLoading">Loading...</div>
        ) : (
          <div
            className={`ap ${
              newStudent && newStudent.seatAlloted ? 'form-completed' : 'form-unverified'
            }`}
          >
            <button onClick={() => openSeatAllotmentLetterFunc()}>
              {newStudent && newStudent.seatAlloted ? 'Alloted' : 'Allot'}
            </button>
          </div>
        )
      ) : null}
      <PersonalDetailsPopUp
        open={openPersonalDetails}
        onClose={() => setOpenPersonalDetails(false)}
        userData={newStudent}
      />
      {newStudent && (
        <PersonalDocumentsPopUp
          open={openPersonalDocuments}
          onClose={() => onClosePersonalDocuments()}
          applicationid={newStudent?.applicationid}
          documentVerificationStatus={newStudent?.documentVerificationStatus}
          showButtons={showButtons}
          setShowButtons={setShowButtons}
        />
      )}
      {newStudent && (
        <OtherDocumentsPopUp
          open={openOtherDocuments}
          onClose={() => onCloseOtherDocuments()}
          applicationid={newStudent?.applicationid}
          documentVerificationStatus={newStudent?.documentVerificationStatus}
        />
      )}
      {newStudent && (
        <PaymentHistoryPopUp
          open={openPaymentHistory}
          onClose={() => setOpenPaymentHistory(false)}
          applicationid={newStudent?.applicationid}
        />
      )}
      {newStudent && (
        <UploadSeatAllotmentLetter
          open={openSeatAllotmentLetter}
          onClose={() => setOpenSeatAllotmentLetter(false)}
          canAllotSeat={verifiactionStatus.otherFiles && verifiactionStatus.personalFiles}
          applicationid={newStudent?.applicationid}
          newStudent={newStudent}
          documentVerificationStatus={newStudent?.documentVerificationStatus}
        />
      )}
    </>
  );
};

export default AdmissionListBody;
