import React, { useEffect } from 'react';
import '../css/Component/PersonalDetailsPopUp.css';
import { Link } from 'react-router-dom';
import apis from '../apis';
import { AuthProvider } from '../auth/AuthContext';
import { CircularProgress } from '@mui/material';

const arrayBufferToURL = (buffer, type) => {
  var data = new Uint8Array(buffer);
  var blob = new Blob([data], { type: type });
  var url = URL.createObjectURL(blob);
  return url;
};

const PersonalDocumentsPopUp = ({
  open,
  onClose,
  applicationid,
  documentVerificationStatus,
  showButtons,
  setShowButtons,
}) => {
  const { adminData } = AuthProvider();
  const [reject, setReject] = React.useState(null);
  const [rejectReason, setRejectReason] = React.useState({
    photo: '',
    signature: '',
    allotmentletter: '',
    aadhar: '',
    tenth: '',
    twelfth: '',
  });
  const [loading, setLoading] = React.useState(true);
  const [userDataFiles, setUserDataFiles] = React.useState();
  useEffect(() => {
    if (applicationid && open) {
      const fetchData = async () => {
        setLoading(true);
        await apis
          .fetchPersonalFiles(applicationid)
          .then((res) => {
            setUserDataFiles(res);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            alert(err.message);
          });
      };
      fetchData();
    }
  }, [open, applicationid]);

  useEffect(() => {
    if (userDataFiles) {
      setShowButtons({
        photo: userDataFiles.documentVerificationStatus.photo.status === 'Verified' ? false : true,
        signature:
          userDataFiles.documentVerificationStatus.signature.status === 'Verified' ? false : true,
        allotmentletter:
          userDataFiles.documentVerificationStatus.allotmentletter.status === 'Verified'
            ? false
            : true,
        aadhar:
          userDataFiles.documentVerificationStatus.aadhar.status === 'Verified' ? false : true,
        tenth: userDataFiles.documentVerificationStatus.tenth.status === 'Verified' ? false : true,
        twelfth:
          userDataFiles.documentVerificationStatus.twelfth.status === 'Verified' ? false : true,
      });

      setRejectReason({
        photo: userDataFiles.documentVerificationStatus.photo.comment,
        signature: userDataFiles.documentVerificationStatus.signature.comment,
        allotmentletter: userDataFiles.documentVerificationStatus.allotmentletter.comment,
        aadhar: userDataFiles.documentVerificationStatus.aadhar.comment,
        tenth: userDataFiles.documentVerificationStatus.tenth.comment,
        twelfth: userDataFiles.documentVerificationStatus.twelfth.comment,
      });
    }
  }, [userDataFiles]);

  const handleReject = (type) => {
    setReject(type);
  };

  const verifyDocument = async (docType) => {
    const data = {
      verified: 'Verified',
      doc: docType,
      comment: 'Document verified',
    };

    setShowButtons({ ...showButtons, [docType]: true });
    await apis
      .approveRejectDocuments(data, applicationid)
      .then((res) => {
        userDataFiles.documentVerificationStatus[docType].status = 'Verified';
        documentVerificationStatus[docType].status = 'Verified';
        alert(`${docType} verified successfully`);
        setShowButtons({ ...showButtons, [docType]: false });
      })
      .catch((err) => {
        setShowButtons({ ...showButtons, [docType]: false });
        console.log(err.message);
      });
  };

  const rejectDocument = async (docType) => {
    const data = {
      verified: 'Rejected',
      doc: docType,
      comment: rejectReason[docType] === '' ? null : rejectReason[docType],
    };

    if ((userDataFiles.documentVerificationStatus[docType].status = 'Verified')) {
      if (
        window.confirm(
          `The document ${docType} is already verified. Are you sure you want to reject it?`
        )
      ) {
        await apis
          .approveRejectDocuments(data, applicationid)
          .then((res) => {
            setShowButtons({ ...showButtons, [docType]: false });
            userDataFiles.documentVerificationStatus[docType].status = 'Rejected';
            documentVerificationStatus[docType].status = 'Rejected';
            userDataFiles.documentVerificationStatus[docType].comment = rejectReason[docType];
            setReject(null);
            alert(`${docType} rejected successfully with Reason: ${rejectReason[docType]}`);
          })
          .catch((err) => {
            setReject(false);
            console.log(err.message);
          });
      } else {
        return;
      }
    }
  };

  if (!open) return null;
  return (
    <>
      <div className="PersonalDetailsPopUp">
        {reject && (
          <div className="reject-form">
            <div className="reject-form-content" style={{ position: 'relative' }}>
              <h3>Reject Reason</h3>
              <button onClick={() => setReject(null)} className="closeReject">
                <i class="fa-solid fa-xmark"></i>
              </button>
              <textarea
                placeholder="Please enter a valid reason to reject this document"
                value={rejectReason[reject] === 'Document verified' ? '' : rejectReason[reject]}
                onChange={(e) => setRejectReason({ ...rejectReason, [reject]: e.target.value })}
              ></textarea>
              <button className="primary" onClick={() => rejectDocument(reject)}>
                Submit
              </button>
            </div>
          </div>
        )}
        <div className="documents-popup-title">
          <h2>
            Personal Documents: <span>{applicationid ?? 'Loading...'}</span>
          </h2>
          <button onClick={onClose}>
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
        {loading ? (
          <div
            className="documents-popup-content"
            style={{
              minHeight: '100px',
              height: '200px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {' '}
            <CircularProgress />{' '}
          </div>
        ) : (
          <div className="documents-popup-content">
            <div className="view-mode">
              <div className="form-container">
                <div className="form-groups-row">
                  <div className="form-group left-group docs-uploading">
                    <label htmlFor="passportPic" className="required">
                      Passport Picture (png/jpeg)
                    </label>
                    {userDataFiles?.photo && (
                      <Link
                        to={arrayBufferToURL(userDataFiles?.photo.data, 'image/png')}
                        style={{ marginTop: '5px' }}
                        target="_blank"
                      >{`${applicationid}_photo`}</Link>
                    )}
                    {adminData &&
                      (adminData.role.includes('Super Admin') ||
                        adminData.role.includes('Document Verifier')) && (
                        <div className="approve-reject">
                          <button
                            className="approve primary"
                            onClick={() => verifyDocument('photo')}
                          >
                            {documentVerificationStatus.photo.status === 'Verified' ||
                            documentVerificationStatus.photo.status === 'Rejected'
                              ? 'Re Approve'
                              : 'Approve'}
                          </button>
                          <button
                            className="reject secondary"
                            onClick={() => handleReject('photo')}
                          >
                            {documentVerificationStatus.photo.status === 'Rejected'
                              ? 'Rejected'
                              : 'Reject'}
                          </button>
                        </div>
                      )}
                  </div>
                  <div className="form-group right-group docs-uploading">
                    <label htmlFor="signature" className="required">
                      Signature (png/jpeg)
                    </label>
                    {userDataFiles?.signature && (
                      <Link
                        to={arrayBufferToURL(userDataFiles?.signature.data, 'image/png')}
                        style={{ marginTop: '5px' }}
                        target="_blank"
                      >{`${applicationid}_signature`}</Link>
                    )}
                    {adminData &&
                      (adminData.role.includes('Super Admin') ||
                        adminData.role.includes('Document Verifier')) && (
                        <div className="approve-reject">
                          <button
                            className="approve primary"
                            onClick={() => verifyDocument('signature')}
                          >
                            {documentVerificationStatus.signature.status === 'Verified' ||
                            documentVerificationStatus.signature.status === 'Rejected'
                              ? 'Re Approve'
                              : 'Approve'}
                          </button>
                          <button
                            className="reject secondary"
                            onClick={() => handleReject('signature')}
                          >
                            {documentVerificationStatus.signature.status === 'Rejected'
                              ? 'Rejected'
                              : 'Reject'}
                          </button>
                        </div>
                      )}
                  </div>
                </div>
                <div className="form-groups-row">
                  <div className="form-group left-group docs-uploading">
                    <label htmlFor="allotmentLetter" className="required">
                      Seat Allotment Letter (pdf)
                    </label>
                    {userDataFiles?.allotmentletter && (
                      <Link
                        to={arrayBufferToURL(
                          userDataFiles?.allotmentletter.data,
                          'application/pdf'
                        )}
                        style={{ marginTop: '5px' }}
                        target="_blank"
                      >{`${applicationid}_allotmentletter`}</Link>
                    )}
                    {adminData &&
                      (adminData.role.includes('Super Admin') ||
                        adminData.role.includes('Document Verifier')) && (
                        <div className="approve-reject">
                          <button
                            className="approve primary"
                            onClick={() => verifyDocument('allotmentletter')}
                          >
                            {documentVerificationStatus.allotmentletter.status === 'Verified' ||
                            documentVerificationStatus.allotmentletter.status === 'Rejected'
                              ? 'Re Approve'
                              : 'Approve'}
                          </button>
                          <button
                            className="reject secondary"
                            onClick={() => handleReject('allotmentletter')}
                          >
                            {documentVerificationStatus.allotmentletter.status === 'Rejected'
                              ? 'Rejected'
                              : 'Reject'}
                          </button>
                        </div>
                      )}
                  </div>
                  <div className="form-group right-group docs-uploading">
                    <label htmlFor="aadhaarFile" className="required">
                      Aadhaar Card (pdf/png/jpeg)
                    </label>
                    {userDataFiles?.aadhar && (
                      <Link
                        to={arrayBufferToURL(userDataFiles?.aadhar.data, 'application/pdf')}
                        style={{ marginTop: '5px' }}
                        target="_blank"
                      >{`${applicationid}_aadhar`}</Link>
                    )}
                    {adminData &&
                      (adminData.role.includes('Super Admin') ||
                        adminData.role.includes('Document Verifier')) && (
                        <div className="approve-reject">
                          <button
                            className="approve primary"
                            onClick={() => verifyDocument('aadhar')}
                          >
                            {documentVerificationStatus.aadhar.status === 'Verified' ||
                            documentVerificationStatus.aadhar.status === 'Rejected'
                              ? 'Re Approve'
                              : 'Approve'}
                          </button>
                          <button
                            className="reject secondary"
                            onClick={() => handleReject('aadhar')}
                          >
                            {documentVerificationStatus.aadhar.status === 'Rejected'
                              ? 'Rejected'
                              : 'Reject'}
                          </button>
                        </div>
                      )}
                  </div>
                </div>
                <div className="form-groups-row">
                  <div className="form-group left-group docs-uploading">
                    <label htmlFor="xthMarksheet" className="required">
                      Xth Marsheet
                    </label>
                    {userDataFiles?.tenth && (
                      <Link
                        to={arrayBufferToURL(userDataFiles?.tenth.data, 'application/pdf')}
                        style={{ marginTop: '5px' }}
                        target="_blank"
                      >{`${applicationid}_tenth`}</Link>
                    )}
                    {adminData &&
                      (adminData.role.includes('Super Admin') ||
                        adminData.role.includes('Document Verifier')) && (
                        <div className="approve-reject">
                          <button
                            className="approve primary"
                            onClick={() => verifyDocument('tenth')}
                          >
                            {documentVerificationStatus.tenth.status === 'Verified' ||
                            documentVerificationStatus.tenth.status === 'Rejected'
                              ? 'Re Approve'
                              : 'Approve'}
                          </button>
                          <button
                            className="reject secondary"
                            onClick={() => handleReject('tenth')}
                          >
                            {documentVerificationStatus.tenth.status === 'Rejected'
                              ? 'Rejected'
                              : 'Reject'}
                          </button>
                        </div>
                      )}
                  </div>
                  <div className="form-group right-group docs-uploading">
                    <label htmlFor="xiithMarsheet" className="required">
                      XIIth Marksheet (pdf)
                    </label>
                    {userDataFiles?.twelfth && (
                      <Link
                        to={arrayBufferToURL(userDataFiles?.twelfth.data, 'application/pdf')}
                        style={{ marginTop: '5px' }}
                        target="_blank"
                      >{`${applicationid}_twelfth`}</Link>
                    )}
                    {adminData &&
                      (adminData.role.includes('Super Admin') ||
                        adminData.role.includes('Document Verifier')) && (
                        <div className="approve-reject">
                          <button
                            className="approve primary"
                            onClick={() => verifyDocument('twelfth')}
                          >
                            {documentVerificationStatus.twelfth.status === 'Verified' ||
                            documentVerificationStatus.twelfth.status === 'Rejected'
                              ? 'Re Approve'
                              : 'Approve'}
                          </button>
                          <button
                            className="reject secondary"
                            onClick={() => handleReject('twelfth')}
                          >
                            {documentVerificationStatus.twelfth.status === 'Rejected'
                              ? 'Rejected'
                              : 'Reject'}
                          </button>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PersonalDocumentsPopUp;
