import React from 'react';
import PropTypes from 'prop-types';
import '../css/Component/RatingDetailTables.css';
import Table from '../common/tables/Table';

const RatingTableComponent = ({
  questionList,
  ratingCountMatrix,
  ratingPercentageMatrix,
  averageCount,
  averagePercent,
}) => {
  if (
    !questionList ||
    !ratingCountMatrix ||
    !ratingPercentageMatrix ||
    !averageCount ||
    !averagePercent
  ) {
    return <div>Loading...</div>;
  }

  const columns = [
    {
      Header: 'Questions',
      accessor: 'questions',
      style: { width: '40%', maxWidth: '50%' },
    },
    {
      Header: (
        <>
          Excellent <br /> {`(Count/%)`}
        </>
      ),
      accessor: 'excellent',
    },
    {
      Header: (
        <>
          Very Good <br /> {`(Count/%)`}
        </>
      ),
      accessor: 'veryGood',
    },
    {
      Header: (
        <>
          Good <br /> {`(Count/%)`}
        </>
      ),
      accessor: 'good',
    },
    {
      Header: (
        <>
          Fair <br /> {`(Count/%)`}
        </>
      ),
      accessor: 'fair',
    },
    {
      Header: (
        <>
          Poor <br /> {`(Count/%)`}
        </>
      ),
      accessor: 'poor',
    },
  ];

  const data = [
    ...questionList.map((question, index) => ({
      questions: question,
      excellent: (
        <>
          {ratingCountMatrix[index][4]} <br />{' '}
          {`(${Number(ratingPercentageMatrix[index][4]).toFixed(2)}%)`}
        </>
      ),
      veryGood: (
        <>
          {ratingCountMatrix[index][3]} <br />{' '}
          {`(${Number(ratingPercentageMatrix[index][3]).toFixed(2)}%)`}
        </>
      ),
      good: (
        <>
          {ratingCountMatrix[index][2]} <br />{' '}
          {`(${Number(ratingPercentageMatrix[index][2]).toFixed(2)}%)`}
        </>
      ),
      fair: (
        <>
          {ratingCountMatrix[index][1]} <br />{' '}
          {`(${Number(ratingPercentageMatrix[index][1]).toFixed(2)}%)`}
        </>
      ),
      poor: (
        <>
          {ratingCountMatrix[index][0]} <br />{' '}
          {`(${Number(ratingPercentageMatrix[index][0]).toFixed(2)}%)`}
        </>
      ),
    })),
    {
      questions: 'Average',
      excellent: (
        <>
          {averageCount[4]} <br /> {`(${Number(averagePercent[4]).toFixed(2)}%)`}
        </>
      ),
      veryGood: (
        <>
          {averageCount[3]} <br /> {`(${Number(averagePercent[3]).toFixed(2)}%)`}
        </>
      ),
      good: (
        <>
          {averageCount[2]} <br /> {`(${Number(averagePercent[2]).toFixed(2)}%)`}
        </>
      ),
      fair: (
        <>
          {averageCount[1]} <br /> {`(${Number(averagePercent[1]).toFixed(2)}%)`}
        </>
      ),
      poor: (
        <>
          {averageCount[0]} <br /> {`(${Number(averagePercent[0]).toFixed(2)}%)`}
        </>
      ),
    },
  ];

  return (
    <div className="ratingDetailTableCont">
      <Table
        columns={columns}
        data={data}
        loading={false}
        className="ratingDetailTable"
        bodyClassName="ratingDetailBody"
      />
    </div>
  );
};

RatingTableComponent.propTypes = {
  questionList: PropTypes.arrayOf(PropTypes.string).isRequired,
  ratingCountMatrix: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  ratingPercentageMatrix: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  averageCount: PropTypes.arrayOf(PropTypes.number).isRequired,
  averagePercent: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default RatingTableComponent;
