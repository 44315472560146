import React from 'react';
import apis from '../apis';

const ComplaintsElement = ({ complaint, admin }) => {
  const upvote = async () => {
    await apis
      .upvoteComplaint({ id: complaint?.id })
      .then((data) => {
        window.location.reload();
        alert('Upvoted');
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  return (
    <div className="complaints-child">
      <div className="complaints-child-item">
        <h4>
          <span>{complaint?.name}</span>
        </h4>
        <h4>
          <span>{complaint?.email}</span>
        </h4>
        {!admin && (
          <button className="primary upvote-btn" onClick={upvote}>
            + 1
          </button>
        )}
        <p>Upvoted by: {complaint?.upvotes}</p>
        <p>Date: {complaint?.date}</p>
      </div>
      <div className="complaints-child-item">
        <h4>{complaint?.location}</h4>
      </div>
      <div className="complaints-child-item">
        <h4>{complaint?.type}</h4>
      </div>
      <div className="complaints-child-item">
        <h4>{complaint?.description}</h4>
      </div>
      {/* <div className="complaints-child-item">
        <h4>Not taken Seriously</h4>
        <button className="primary mrk-apr-btn">Mark as resolved</button>
      </div> */}
    </div>
  );
};

export default ComplaintsElement;
