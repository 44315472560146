import * as React from 'react';
import '../css/Component/Highlights.css';
import { AuthProvider } from '../auth/AuthContext';
import apis from '../apis';

function Highlights() {
  const { userData } = AuthProvider();
  const [paymentData, setPaymentData] = React.useState();
  React.useEffect(() => {
    const getPaymentData = async () => {
      userData?.roll &&
        (await apis
          .specificStudent({ roll: userData.roll, semester: userData.semester })
          .then((data) => {
            setPaymentData(data[0]);
          })
          .catch((err) => {
            alert(err.message);
          }));
    };
    getPaymentData();
  }, [userData?.roll, userData?.semester]);

  const showRegistrationStatus = () => {
    if (paymentData?.registrationStatus === 'Not Registered') {
      return '/assets/NotDone-Registration.svg';
    } else if (paymentData?.registrationStatus === 'Registered') {
      return '/assets/Completed-Registration.svg';
    } else if (paymentData?.registrationStatus === 'Pending') {
      return '/assets/Pending-Registration.svg';
    } else if (paymentData?.registrationStatus === 'Approved') {
      return '/assets/Approved-Registration.svg';
    }
  };

  const showPaymentStatus = () => {
    if (paymentData?.paymentStatus === 'Paid') {
      return '/assets/Finished-Payment.svg';
    } else if (paymentData?.paymentStatus === 'Pending') {
      return '/assets/Pending-Payment.svg';
    } else {
      return '/assets/Due-Payment.svg';
    }
  };

  return (
    <div className="highlights-div">
      <div className="highlight-element-row">
        <div className="highlight-element">
          <div className="icon-section">
            <h1>{userData.semester}</h1>
          </div>
          <div className="title-section">
            <h3>Current Semester</h3>
          </div>
        </div>
        <div className="highlight-element">
          <div className="icon-section">
            {paymentData && <img src={`${showRegistrationStatus()}`} alt="img" />}
          </div>
          <div className="title-section">
            <h3>Registration Status</h3>
          </div>
        </div>
      </div>
      <div className="highlight-element-row">
        <div className="highlight-element">
          <div className="icon-section">
            {paymentData && <img src={`${showPaymentStatus()}`} alt="img" />}
          </div>
          <div className="title-section">
            <h3>Payment Status</h3>
          </div>
        </div>
        <div className="highlight-element">
          <div className="icon-section">
            <h1>{userData.cgpa}</h1>
          </div>
          <div className="title-section">
            <h3>Current CGPA</h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Highlights;
