import axios from 'axios';
import jwtVerify from '../auth/service';

// axios.defaults.baseURL = 'http://localhost:2000';
class Apis {
  particularTypeFile = (type) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/file/showfiles/${type}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((data) => {
          resolve(data.data.data[0]);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  allForms = () => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/file/allforms`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((data) => {
          resolve(data.data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  allDocuments = () => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/file/alldocuments`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((data) => {
          if (data.data.status === 0) {
            resolve(data.data.data);
          } else {
            reject(data.data.error);
          }
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  profile = (roll) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/profile/${roll}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((data) => {
          resolve(data.data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  profileDataNew = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/profile/profileData`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  profileName = (roll) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/profile/name/${roll}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((data) => {
          resolve(data.data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  newProfile = (roll, applicationid, semester) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/profile/complete/${roll}/${applicationid}?semester=${semester}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
      })
        .then((data) => {
          resolve(data.data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  feeStructure = (year, course, semester, batch, type) => {
    return new Promise(async (resolve, reject) => {
      // console.log(type)
      if (course && semester && batch) {
        await axios({
          url: `/api/fees/structure`,
          method: 'POST',
          data: {
            course,
            semester,
            batch,
            type: type.toLowerCase() === 'regular' ? 'REGULAR' : 'PART TIME',
          },
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then((data) => {
            resolve(data.data);
          })
          .catch((err) => {
            reject(err.response.data);
          });
      }
    });
  };

  updateBonafideSemester = (id, applySemester) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/bonafide/updateApplySemester/${id}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
        data: { applySemester },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  searchStudents = (search, signal) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/student/search`,
        method: 'POST',
        data: search,
        headers: {
          'Content-Type': 'application/json',
        },
        signal: signal, // Pass the abort signal to the request
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            console.log('Request canceled', err.message);
          } else {
            reject(err?.response?.data);
          }
        });
    });
  };

  filterStudents = (search) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/student/filter`,
        method: 'POST',
        data: search,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  specificStudent = (search) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/student/specific`,
        method: 'POST',
        data: search,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  specificStudentNew = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/student/specificNew`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': jwtVerify.getAccessToken('admin'),
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  specificStudent1 = (search, signal) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/student/roll-name`,
        method: 'POST',
        data: search,
        headers: {
          'Content-Type': 'application/json',
        },
        signal: signal,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            console.log('Request canceled', err.message);
          } else {
            reject(err?.response?.data);
          }
        });
    });
  };

  downloadCsv = (searchResult, headers, filename) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/student/downloadcsv`,
        method: 'POST',
        data: { data: searchResult, headers, filename },
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  downloadRemainingReviewList = (searchResult, headers) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/ratings/downloadRemaingList`,
        method: 'POST',
        data: { data: searchResult, headers },
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  downloadDebardStudentList = (searchResult, headers) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/student/downloadDebardList`,
        method: 'POST',
        data: { data: searchResult, headers },
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getRefundList = () => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/payments/refund-data`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
      })
        .then((data) => {
          resolve(data.data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  updateRefundList = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/payments/refund-verify`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data.message);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  updateRefundAmount = (id, amount) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/payments/update-refund-amount/${id}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
        data: { amount },
      })
        .then((data) => {
          resolve(data.data.message);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  updateRefundDate = (id, date) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/payments/update-refund-date/${id}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
        data: { date },
      })
        .then((data) => {
          resolve(data.data.message);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  updateRefundSemester = (id, semester) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/payments/update-refund-semester/${id}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
        data: { semester },
      })
        .then((data) => {
          resolve(data.data.message);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  removeRefundData = (id) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/payments/delete-refund/${id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
      })
        .then((data) => {
          resolve(data.data.message);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  addReceipt = (formData) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/payments/add-receipt`,
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data.error);
        });
    });
  };

  allReceipts = (verifier, page = 1, limit = 10) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/payments/all-receipts/${verifier}?page=${page}&limit=${limit}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${jwtVerify.getAccessToken('admin')}`,
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  verifyReceipt = (id, verifier, verifierName, roll, applicationid, semester, amount, reason) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/payments/verify-receipt/${id}/${verifier}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${jwtVerify.getAccessToken('admin')}`,
        },
        data: {
          verifierName,
          roll,
          applicationid,
          amount,
          semester,
          reason,
        },
      })
        .then((data) => {
          resolve(data.data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  rejectReceipt = (id, verifier, verifierName, reason) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/payments/reject-receipt/${id}/${verifier}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${jwtVerify.getAccessToken('admin')}`,
        },
        data: {
          verifierName,
          reason,
        },
      })
        .then((data) => {
          resolve(data.data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };
  paymentHistory = (search) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/payments/payment-history`,
        method: 'POST',
        data: search,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((data) => {
          resolve(data.data.data);
        })
        .catch((err) => {
          reject(err.response.data.error);
        });
    });
  };

  getSpecificSuccessPayments = (roll, semester) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/payments/successfull-payments/${roll}/${semester}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
      })
        .then((data) => {
          resolve(data.data.data);
        })
        .catch((err) => {
          reject(err.response.data.error);
        });
    });
  };

  uploadCourseStructure = (formData) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/_courses/add-course`,
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data.error);
        });
    });
  };

  getCourseStructure = (findFilter) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/_courses/get-course`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: findFilter,
      })
        .then((data) => {
          resolve(data.data.course);
        })
        .catch((err) => {
          reject(err.response.data.error);
        });
    });
  };

  getAllCoursesDetails = () => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/faculty/getAllCourses`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data.error);
        });
    });
  };

  uploadMainDatabase = (formData) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/profile/add-profile`,
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data.error);
        });
    });
  };

  uploadFeeStructure = (formData) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/fees/uploadstructure`,
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data.error);
        });
    });
  };

  uploadNewAllotmentDatabase = (formData) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: '/api/new/newregistration',
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  uploadRollandEmailData = (formData) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: '/api/new/add-email-roll',
        method: 'PATCH',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  uploadTransactionsData = (formData) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: '/api/payments/add-bulk-receipt',
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  updateStudent = (roll, data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/student/update-profile/${roll}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data.fee);
        })
        .catch((err) => {
          reject(err.response.data.error);
        });
    });
  };

  ucoEpayPortal = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/gateway/uco`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data.error);
        });
    });
  };

  sbiEpayPortal = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/gateway/sbi`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        params: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data.error);
        });
    });
  };

  canaraEpayPortal = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/gateway/canara`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  passwordChange = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/password`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  generateOTP = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/password/generate-otp`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  resetPassword = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/password/reset`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  fetchAllNewStudents = () => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/new/allnewstudents`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  fetchFilesStudent = (id) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/new/newstudentfiles/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  fetchPersonalFiles = (applicationid) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/new/newstudentfiles/personalFiles/${applicationid}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  fetchOtherFiles = (applicationid) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/new/newstudentfiles/otherFiles/${applicationid}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  addExtraCourse = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: '/api/courses/extra',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  showExtraCourses = (data) => {
    const { semester } = data;
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/courses/?semester=${semester}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('student'),
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  showExtraCoursesAdmin = (data) => {
    const { semester, roll } = data;
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/courses/admin?semester=${semester}&roll=${roll}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  submitCourses = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: '/api/courses/submit',
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('student'),
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  fetchVerifiableCourses = ({ semester, batch, program, page=1, limit=10 }) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/courses/forverification/?semester=${semester ?? ''}&batch=${batch ?? ''}&program=${program ?? ''}&page=${page}&limit=${limit}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getUserDataForCourseVerification = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/courses/getUserData`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  getStudentData = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/profile/stud-data`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  addComplaint = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/complaint/add`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  raiseComplaint = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/complaint/raiseComplaint`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getAllComplaints = () => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/complaint/admin/all`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getAllComplaintsPublic = () => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/complaint/all`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  upvoteComplaint = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/complaint/upvote`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  updateComplaintStatus = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/complaint/updateStatus`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  approveCourse = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/courses/verify/approve`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  skipApproveCourse = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/courses/skip-verify/approve`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  rejectCourse = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/courses/verify/reject`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  applyBonafide = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/bonafide/apply`,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: jwtVerify.getAccessToken('student'),
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  generateBonafide = (id, purpose) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/bonafide/generated/${id}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
        data: {
          purpose,
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  generateRandomBonafide = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/bonafide/generate-random`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getBonafide = (id) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/bonafide/application/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getBonafideApplications = () => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/bonafide/application`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getBonafideHistory = () => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/bonafide/history`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  applyHostel = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/hostel/apply`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('student'),
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  reApplyHostelSpecific = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/hostel/openForSpecificStudent`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('student'),
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  applyForMess = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/hostel/applyMess`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('student'),
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  hostelStatus = () => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/hostel/status`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('student') ?? jwtVerify.getAccessToken('admin'),
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  hostelStatusAdmin = (roll, semester) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/hostel/status/r/${roll}/?semester=${semester}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  hostelStatusAdminNew = (applicationid, semester) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/hostel/status/a/${applicationid}/?semester=${semester}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getAllHostelApplications = (currSession) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/hostel/admin/all?session=${currSession}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  approveHostelApplication = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/hostel/admin/t/approve`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  rejectHostelApplication = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/hostel/admin/t/reject`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  allotHostel = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/hostel/admin/allot`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  allotMess = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/hostel/admin/allotMess`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  allAdmins = () => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/auth/admin/all`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  addAdmin = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/auth/admin/signup`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  deleteAdmin = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/auth/admin/delete`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getFacultyById = (id) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/faculty/id/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getFacultyByFacultyId = (id) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/faculty/facultyId/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  updateFine = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/student/update-fine/${data.roll}/${data.semester}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  updateDiscount = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/student/update-discount/${data.roll}/${data.semester}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  uploadRefundData = (formData) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/payments/add-bulk-refund`,
        method: 'PATCH',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  uploadAdjustments = (formData) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/payments/add-bulk-adjustments`,
        method: 'PATCH',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  allAdjustments = () => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/payments/all-adjustments`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
      })
        .then((data) => {
          resolve(data.data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getSpecificAdjustments = (roll, semester) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/payments/adjustments/${roll}/${semester}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
      })
        .then((data) => {
          resolve(data.data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  updatePaymentStatus = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/student/update-payment-status`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('student'),
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  allotRoom = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/hostel/admin/room`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  allotRoomToStudents = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/hostel/admin/roomAllot`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getPHDData = (roll) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/courses/admin/phd/r/${roll}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getPHD = (roll) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/courses/phd/r/${roll}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('student'),
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getResult = (semester) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/result/${semester}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('student'),
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  uploadResult = (formData) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/result/admin/upload`,
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getAllPhdStudents = () => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/courses/admin/phd/all`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  finalVerification = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/courses/admin/r/${data.roll}/?semester=${data.semester}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  updatePHDCourse = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/courses/admin/phd/level/r/${data.roll}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getFaculties = () => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/faculty`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getUniqueFaculties = () => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/faculty/getUniqueFaculty`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  assignFaculties = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/faculty/allotStudent`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getFaculty = (id) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/faculty/id/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getFacultyCouresesById = (id) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/faculty/getFacultyCourses/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getFacultyCourseByFacIdandCourseId = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/faculty/getFacultyCourse/${data.facultyId}/${data.courseId}/${data.session}/${data.semester}/${data.branch}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  specificCourseRegisteredStudents = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/faculty/countRegisteredStudents/?courseId=${data.courseId}&session=${data.session}&semester=${data.semester}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  specificCourseRatingsCount = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/ratings/faculty-course-rating-count/?facultyId=${data.facultyId}&courseId=${data.courseId}&session=${data.session}&semester=${data.semester}&course=${data.course}&branch=${data.branch}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getStudentRatingDetails = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/ratings/get-student-ratings/?ratingSubmitted=${data.status}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getStudentFeesByHostel = (hostel, semester) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/hostel/admin/student/${hostel}/?semester=${semester}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  markForRegistration = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/courses/admin/markforregistration`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  fetchReceipt = (id) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/payments/receipt/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  updateAdmin = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/auth/admin/update`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtVerify.getAccessToken('admin'),
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  updatePersonalProfile = (data, roll) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/student/update-personal/${roll}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          // Authorization: jwtVerify.getAccessToken("student"),
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  myCourses = () => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/faculty/showMyCourses`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${jwtVerify.getAccessToken('admin')}`,
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  courseById = (id) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/_courses/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${jwtVerify.getAccessToken('admin')}`,
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  courseStudents = (id) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/faculty/getCourseStudents/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${jwtVerify.getAccessToken('admin')}`,
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  updateStudentMarks = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/faculty/updateMarks`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${jwtVerify.getAccessToken('admin')}`,
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  updateLevel = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/faculty/updateReviewLevel`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${jwtVerify.getAccessToken('admin')}`,
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getHodView = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/faculty/hodview`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${jwtVerify.getAccessToken('admin')}`,
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  promoteStudents = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/student/promoteStudents`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${jwtVerify.getAccessToken('admin')}`,
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  demoteStudents = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/student/demoteStudents`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${jwtVerify.getAccessToken('admin')}`,
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  updateRegistrationOpenStatus = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/student/allowRegistrationToSelected`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${jwtVerify.getAccessToken('admin')}`,
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getOpenRegistrationStatus = (roll, semester) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/student/getSpecificOpenRegistration/${roll}/${semester}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${jwtVerify.getAccessToken('student')}`,
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  assignFacultyCourse = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/faculty/takeCourse`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${jwtVerify.getAccessToken('admin')}`,
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  isReviewSubmitted = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/ratings/get-rating-status?student_roll=${data.student_roll}&semester=${data.semester}&session=${data.session}&course=${data.course}&branch=${data.branch}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${jwtVerify.getAccessToken('student')}`,
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data.ratingSumbitted);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  showFeedbackPage = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/ratings/show-rating-page?student_roll=${data.student_roll}&semester=${data.semester}&session=${data.session}&course=${data.course}&branch=${data.branch}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${jwtVerify.getAccessToken('student')}`,
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data.status);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  assignReviewPageToStudents = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/ratings/assign-rating`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${jwtVerify.getAccessToken('admin')}`,
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  updateRatingOfFaculty = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/ratings/update`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  updateRatingOfAtOnce = (data) => {
    // console.log(data);
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/ratings/updateAtOnce`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  updateUploadToDB = (id) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/ratings/updatePost/${id}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          // Authorization: `${jwtVerify.getAccessToken("student")}`,
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  studentReviewSubmit = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/ratings/submit-review`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${jwtVerify.getAccessToken('student')}`,
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  addDataToRatingsTable = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/ratings/addPost`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${jwtVerify.getAccessToken('student')}`,
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getAllRatingStatus = () => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/ratings/getAllPosts`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Authorization: `${jwtVerify.getAccessToken("admin")}`,
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getRatingDetails = async (queryData) => {
    return await axios({
      url: `/api/ratings/get-rating-details?session=${queryData.session}&season=${queryData.season}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${jwtVerify.getAccessToken('admin')}`,
      },
    })
      .then((data) => {
        return data.data;
      })
      .catch((err) => {
        // console.log(err.response);
        return err.response.data;
      });
  };

  getCurrentStudentLength = (queryData) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/student/getFilteredStudents?semester=${queryData?.semester}&branch=${queryData?.branch}&course=${queryData?.course}&batch=${queryData?.batch}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Authorization: `${jwtVerify.getAccessToken("admin")}`,
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getTotalStudentReviewCount = (queryData) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/ratings/total-rating-count?semester=${queryData?.semester}&branch=${queryData?.branch}&course=${queryData?.course}&session=${queryData?.session}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Authorization: `${jwtVerify.getAccessToken("admin")}`,
        },
      })
        .then((data) => {
          resolve(data.data.ratingCount);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getIsRatingAssigned = (queryData) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/ratings/isRatingAssigned?semester=${queryData?.semester}&branch=${queryData?.branch}&course=${queryData?.course}&session=${queryData?.session}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${jwtVerify.getAccessToken('admin')}`,
        },
      })
        .then((data) => {
          resolve(data.data.ratingCount);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getCoursesWithFaculty = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/faculty/getCourseFaculties?session=${data.session}&semester=${data.semester}&branch=${data.branch}&program=${data.program}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  generateEmailRoll = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/student/generateRollEmail`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getDebardList = (queryData) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/student/getDebarredStudents?semester=${queryData?.semester}&branch=${queryData?.branch}&course=${queryData?.course}&session=${queryData?.session}&season=${queryData?.season}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Authorization: `${jwtVerify.getAccessToken("admin")}`,
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getBatchFilterList = () => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/student/batchFilterList`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getBatchFilterListComplete = () => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/student/batchFilterListComplete`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getSessionFilterList = () => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/session/getSessionList`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  addBankDetails = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/student/addBankDetails`,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `${jwtVerify.getAccessToken('student')}`,
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getAllStudents = () => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/student/all`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${jwtVerify.getAccessToken('admin')}`,
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getAllStudentBankDetails = () => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/student/getAllBankDetails`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${jwtVerify.getAccessToken('admin')}`,
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getPassBookByRoll = (roll) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/student/passbook/${roll}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${jwtVerify.getAccessToken('admin')}`,
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  approveRejectDocuments = (data, applicationid) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/new/admin/verify-doc/${applicationid}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${jwtVerify.getAccessToken('admin')}`,
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  allotStudentSeat = (applicationid, formData) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/new/admin/upload-seat-allotment/${applicationid}`,
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `${jwtVerify.getAccessToken('admin')}`,
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getPIReportingStatus = (roll) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/new/admin/get-reporting-status/${roll}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${jwtVerify.getAccessToken('admin')}`,
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  updateProfilePic = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/profile/admin/update-profile-pic`,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `${jwtVerify.getAccessToken('admin')}`,
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  updateNewProfile = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/profile/admin/new-profile-update`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${jwtVerify.getAccessToken('admin')}`,
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  setSchedule = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/timer/setTimer`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Authorization: `${jwtVerify.getAccessToken("admin")}`,
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  updateSchedule = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/timer/updateTimer`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          // Authorization: `${jwtVerify.getAccessToken("admin")}`,
        },
        data: data,
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  removeSchedule = (type) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/timer/stopTimer?type=${type}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          // Authorization: `${jwtVerify.getAccessToken("admin")}`,
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getMessSwapResults = () => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/messSwap/getMessSwapRequests`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${jwtVerify.getAccessToken('admin')}`,
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  approveMessSwap = (id) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/messSwap/approveMessSwapRequest/${id}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${jwtVerify.getAccessToken('admin')}`,
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  rejectMessSwap = (id) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/messSwap/rejectMessSwapRequest/${id}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${jwtVerify.getAccessToken('admin')}`,
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getSpecificMessSwapRequest = (paramsVal) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/messSwap/getSpecificMessSwapRequest/?roll=${paramsVal.roll}&semester=${paramsVal.semester}&session=${paramsVal.session}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  applyForMessSwap = (data) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: `/api/messSwap/requestMessSwap`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      })
        .then((data) => {
          console.log(data.data);
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };
}

const instance = new Apis();

export default instance;
