import React, { useEffect } from 'react';
import apis from '../../apis';
import './styles/GeneralFilter1.css';

const MTechCourses = ['AI & DS', 'VLSI & ES', 'EV', 'Micro & Comm.', 'SPML'];

const BTechCourses = ['CSE', 'ECE', 'MAE', 'MNC'];

const PhDCourses = ['CSE', 'ECE', 'MAE'];

const GeneralFilter1 = ({
  filterHeading,
  filters,
  setFilterAttribute,
  filterAttribute,
  containerClass,
  titleClass,
}) => {
  const [batchList, setBatchList] = React.useState();
  const [sessionList, setSessionList] = React.useState();

  React.useEffect(() => {
    const fetchBatchList = async () => {
      await apis
        .getBatchFilterList()
        .then((data) => {
          setBatchList(data);
        })
        .catch((err) => {
          console.log(err.message);
        });
    };
    const generateSessionList = async () => {
      await apis.getSessionFilterList().then((data) => {
        setSessionList(data);
      });
    };
    fetchBatchList();
    generateSessionList();
  }, []);

  useEffect(() => {
    if (sessionList && sessionList.length > 0) {
      setFilterAttribute((prev) => ({ ...prev, session: sessionList[0] }));
    }
  }, [sessionList]);

  const handleFilterChange = (e, attribute) => {
    setFilterAttribute((prev) => ({ ...prev, [attribute]: e.target.value }));
  };

  const filterComponents = {
    roll: (
      <div className="filterItem generalRollInput">
        <p>Enter Roll Number:</p>
        <input
          type="text"
          placeholder="Roll Number"
          onChange={(e) => handleFilterChange(e, 'roll')}
        />
      </div>
    ),
    name: (
      <div className="filterItem generalRollInput">
        <p>Enter Name:</p>
        <input
          type="text"
          placeholder="Enter Student Name"
          onChange={(e) => handleFilterChange(e, 'name')}
        />
      </div>
    ),
    session: (
      <div className="filterItem">
        <p>Choose Session:</p>
        <select onChange={(e) => handleFilterChange(e, 'session')} value={filterAttribute.session}>
          <option value="">Session</option>
          {sessionList?.map((session, i) => (
            <>
              <option key={i} value={session}>
                {session}
              </option>
            </>
          ))}
        </select>
      </div>
    ),
    season: (
      <div className="filterItem">
        <p>Choose Season:</p>
        <select value={filterAttribute.season} onChange={(e) => handleFilterChange(e, 'season')}>
          <option value="">Season</option>
          <option value="Spring">Spring</option>
          <option value="Autumn">Autumn</option>
        </select>
      </div>
    ),
    course: (
      <div className="filterItem">
        <p>Select Program:</p>
        <select value={filterAttribute.course} onChange={(e) => handleFilterChange(e, 'course')}>
          <option value="">Program</option>
          <option value="B.TECH.">B.Tech</option>
          <option value="M.TECH.">M.Tech</option>
          <option value="Ph.D.">Ph.D.</option>
        </select>
      </div>
    ),
    batch: (
      <div className="filterItem">
        <p>Select Batch:</p>
        <select
          onChange={(e) => handleFilterChange(e, 'batch')}
          // value={search.batch}
        >
          <option value="">Select Batch</option>
          {filterAttribute.course ? (
            batchList[filterAttribute.course]?.map((batch) => (
              <option key={batch} value={batch}>
                {batch}
              </option>
            ))
          ) : (
            <option value="null">Select Course First</option>
          )}
        </select>
      </div>
    ),
    branch: (
      <div className="filterItem">
        <p>Select Branch:</p>
        <select onChange={(e) => handleFilterChange(e, 'branch')}>
          <option value="">Branch</option>
          {filterAttribute.course === 'B.TECH.'
            ? BTechCourses.map((cs, i) => (
                <option key={i} value={cs}>
                  {cs}
                </option>
              ))
            : filterAttribute.course === 'M.TECH.'
            ? MTechCourses.map((cs, i) => (
                <option key={i} value={cs}>
                  {cs}
                </option>
              ))
            : filterAttribute.course === 'Ph.D.'
            ? PhDCourses.map((cs, i) => (
                <option key={i} value={cs}>
                  {cs}
                </option>
              ))
            : null}
        </select>
      </div>
    ),
    semester: (
      <div className="filterItem">
        <p>Select Semester:</p>
        <select onChange={(e) => handleFilterChange(e, 'semester')}>
          <option value="">Semester</option>
          {filterAttribute.course === 'B.TECH.'
            ? filterAttribute.season === 'Spring'
              ? ['2', '4', '6', '8'].map((sem, i) => (
                  <option key={i} value={sem}>
                    {sem}
                    {sem === 1 ? 'st' : sem === 2 ? 'nd' : sem === 3 ? 'rd' : 'th'}
                  </option>
                ))
              : filterAttribute.season === 'Autumn'
              ? ['1', '3', '5', '7'].map((sem, i) => (
                  <option key={i} value={sem}>
                    {sem}
                    {sem === 1 ? 'st' : sem === 2 ? 'nd' : sem === 3 ? 'rd' : 'th'}
                  </option>
                ))
              : Array.from({ length: 8 }, (_, i) => i + 1).map((sem, i) => (
                  <option key={i} value={sem}>
                    {sem}
                    {sem === 1 ? 'st' : sem === 2 ? 'nd' : sem === 3 ? 'rd' : 'th'}
                  </option>
                ))
            : filterAttribute.course === 'M.TECH.'
            ? filterAttribute.season === 'Spring'
              ? ['2', '4', '6'].map((sem, i) => (
                  <option key={i} value={sem}>
                    {sem}
                    {sem === 1 ? 'st' : sem === 2 ? 'nd' : sem === 3 ? 'rd' : 'th'}
                  </option>
                ))
              : filterAttribute.season === 'Autumn'
              ? ['1', '3', '5'].map((sem, i) => (
                  <option key={i} value={sem}>
                    {sem}
                    {sem === 1 ? 'st' : sem === 2 ? 'nd' : sem === 3 ? 'rd' : 'th'}
                  </option>
                ))
              : Array.from({ length: 6 }, (_, i) => i + 1).map((sem, i) => (
                  <option key={i} value={sem}>
                    {sem}
                    {sem === 1 ? 'st' : sem === 2 ? 'nd' : sem === 3 ? 'rd' : 'th'}
                  </option>
                ))
            : filterAttribute.course === 'Ph.D.'
            ? ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'].map((sem, i) => (
                <option key={i} value={sem}>
                  {sem}th
                </option>
              ))
            : null}
        </select>
      </div>
    ),
    year: (
      <div className="filterItem">
        <p>Select Year:</p>
        <select onChange={(e) => handleFilterChange(e, 'year')}>
          <option value="">Year</option>
          {filterAttribute.course === 'B.TECH.'
            ? ['1', '2', '3', '4'].map((year, i) => (
                <option key={i} value={year}>
                  {year}
                  {year === '1' ? 'st' : year === '2' ? 'nd' : year === '3' ? 'rd' : 'th'}
                </option>
              ))
            : filterAttribute.course === 'M.TECH.'
            ? ['1', '2', '3'].map((year, i) => (
                <option key={i} value={year}>
                  {year}
                  {year === '1' ? 'st' : year === '2' ? 'nd' : year === '3' ? 'rd' : 'th'}
                </option>
              ))
            : filterAttribute.course === 'Ph.D.'
            ? ['1', '2', '3', '4', '5', '6'].map((year, i) => (
                <option key={i} value={year}>
                  {year}th
                </option>
              ))
            : null}
        </select>
      </div>
    ),
    status1: (
      <div className="filterItem">
        <p>Status:</p>
        <select onChange={(e) => handleFilterChange(e, 'status1')}>
          <option value="">Status</option>
          <option value="Available">Available</option>
          <option value="Not-Available">Not-Available</option>
        </select>
      </div>
    ),
    hostelStatus: (
      <div className="filterItem">
        <p>Status:</p>
        <select onChange={(e) => handleFilterChange(e, 'hostelStatus')}>
          <option value="">Status</option>
          <option value="Not Applied">Not Applied</option>
          <option value="Pending">Pending</option>
          <option value="Approved">Approved</option>
          <option value="Alloted">Alloted</option>
          <option value="Rejected">Rejected</option>
        </select>
      </div>
    ),
    gender: (
      <div className="filterItem">
        <p>Gender</p>
        <select onChange={(e) => handleFilterChange(e, 'gender')}>
          <option value="">Gender</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
        </select>
      </div>
    ),
    mess: (
      <div className="filterItem">
        <p>Mess:</p>
        <select onChange={(e) => handleFilterChange(e, 'mess')}>
          <option value="">Mess</option>
          <option
            value="KBH_MESS"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            <div>Sai Hospitality </div> <div>And Services (KBH/H1)</div>{' '}
          </option>
          <option
            value="VSL_MESS"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            <div>Sugandhim – Food </div> <div>& Catering (VCL)</div>{' '}
          </option>
          {/* <option value="VSL_MESS"><br/> </option> */}
        </select>
      </div>
    ),
    requestedMess: (
      <div className="filterItem">
        <p>Requested Mess:</p>
        <select onChange={(e) => handleFilterChange(e, 'requestedMess')}>
          <option value="">Requested Mess</option>
          <option value="KBH_MESS">
            Sai Hospitality And <br /> Services (KBH/H1)
          </option>
          <option value="VSL_MESS">
            Sugandhim – Food <br /> & Catering (VCL)
          </option>
        </select>
      </div>
    ),
    hostel: (
      <div className="filterItem">
        <p>Hostel:</p>
        <select onChange={(e) => handleFilterChange(e, 'hostel')}>
          <option value="">Hostel</option>
          <option value="H1">H1</option>
          <option value="H9">H9</option>
          <option value="H7">H7</option>
          <option value="NBH">NBH</option>
          <option value="GH">GH</option>
          <option value="KBH">KBH</option>
          <option value="KGH">KGH</option>
        </select>
      </div>
    ),
    previousHostel: (
      <div className="filterItem">
        <p>Previous Hostel:</p>
        <select onChange={(e) => handleFilterChange(e, 'previousHostel')}>
          <option value="">Previous Hostel</option>
          <option value="Not Alloted">Not Alloted</option>
          <option value="H1">H1</option>
          <option value="H9">H9</option>
          <option value="H7">H7</option>
          <option value="NBH">NBH</option>
          <option value="GH">GH</option>
          <option value="KBH">KBH</option>
          <option value="KGH">KGH</option>
        </select>
      </div>
    ),
  };

  const refreshFilter = () => {
    setFilterAttribute({
      name: '',
      roll: '',
      session: sessionList[0],
      season: '',
      course: '',
      branch: '',
      semester: '',
      year: '',
      status1: '',
      batch: '',
      gender: '',
      mess: '',
      hostel: '',
      previousHostel: '',
      hostelStatus: '',
    });
  };

  return (
    <div className={`generalFilterContainer ${containerClass}`}>
      <div className={`filterHeader ${titleClass}`}>
        <p>{filterHeading}:</p>
        <button className="generalFilterResetBtn" onClick={refreshFilter}>
          <span>Reset </span>
          <i className="fa-solid fa-refresh"></i>
        </button>
      </div>
      <div className="generalFilterSection">
        {filters.map((filterKey) => filterComponents[filterKey])}
      </div>
    </div>
  );
};

export default GeneralFilter1;
