import React, { useState } from 'react';
import '../css/Component/DocAddPopup.css';

const DocAddPopup = ({ open, onClose }) => {
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // handle form submission here
  };

  if (!open) return null;
  return (
    <>
      <div className="gateway-popup">
        <div className="gateway-popup-title">
          <h2>Add Document</h2>
          <button onClick={onClose}>
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div className="gateway-popup-content DocAddPopup-content">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="title">Title</label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="link">Link</label>
              <input type="text" id="link" value={link} onChange={(e) => setLink(e.target.value)} />
            </div>
            <button className="primary" type="submit">
              Add Document
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default DocAddPopup;
