import * as React from 'react';
import AdminHeader from '../components/AdminHeader';
import '../css/Page/StudentProgressAdmin.css';
import apis from '../apis';
import Loader from '../components/Loader';
import { AuthProvider } from '../auth/AuthContext';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

const arrayBufferToURL = (buffer, type) => {
  var data = new Uint8Array(buffer);
  var blob = new Blob([data], { type: type });
  var url = URL.createObjectURL(blob);
  return url;
};

const StudentProgressAdmin = () => {
  const [loadingStatus, setLoadingStatus] = React.useState(true);
  const [phdStudents, setPhdStudents] = React.useState();
  const [file, setFile] = React.useState();
  const [page, setPage] = React.useState(0);
  const [roll, setRoll] = React.useState();
  const [semFilter, setSemFilter] = React.useState(null);
  const [studentData, setStudentData] = React.useState();
  const [studentOtherData, setStudentOtherData] = React.useState();
  const [phdData, setPhdData] = React.useState();
  const [registrationStatus, setRegistrationStatus] = React.useState();
  const [phdStatus, setPhdStatus] = React.useState({
    option: '',
    status: '',
    date: new Date(),
  });
  const [phdRegistrationStatus, setPhdRegistrationStatus] = React.useState({
    option: '',
    status: null,
  });
  const [userDataFiles, setUserDataFiles] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [pireportingStatus, setPIReportingStatus] = React.useState('');
  const { adminData } = AuthProvider();

  const uploadResults = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);
    await apis
      .uploadResult(formData)
      .then((data) => {
        alert('Results uploaded successfully');
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const updateRegistrationStatus = async () => {
    try {
      if (!roll) {
        alert('Please enter a roll number');
        return;
      }
      if (pireportingStatus === 'Not Reported' && studentData?.semester === '1') {
        alert('Cannot update registration status until PI reporting is done');
        return;
      }
      roll &&
        studentData?.semester &&
        (await apis
          .finalVerification({
            roll: roll,
            registrationStatus: registrationStatus,
            semester: semFilter ? semFilter : studentData?.semester,
          })
          .then(async (data) => {
            alert('Registration Status updated successfully');
            setRegistrationStatus('');
            await fetchData();
          })
          .catch((err) => {
            throw err;
          }));
    } catch (err) {
      alert(err.message);
      const userConfirmed = window.confirm('Are you sure you want to skip this step?');

      if (userConfirmed) {
        await apis
          .skipApproveCourse({ id: roll, semester: semFilter ? semFilter : studentData?.semester })
          .then(async (data) => {
            await apis
              .finalVerification({
                roll: roll,
                registrationStatus: registrationStatus,
                semester: semFilter ? semFilter : studentData?.semester,
              })
              .then(async (data) => {
                alert('Registration Status updated successfully');
                setRegistrationStatus('');
                await fetchData();
              })
              .catch((err) => {
                alert(err.message);
              });
          })
          .catch((err) => {
            alert(err.message);
          });
      }
    }
  };

  React.useEffect(() => {
    const fetchAllPhdStudents = async () => {
      setLoadingStatus(true);
      await apis.getAllPhdStudents().then((res) => {
        setPhdStudents(res);
      });
      setLoadingStatus(false);
    };
    fetchAllPhdStudents();
  }, []);

  const fetchData = async () => {
    try {
      if (!roll) {
        alert('Please enter a roll number');
        return;
      }
      roll &&
        (await Promise.all([apis.getStudentData({ roll: roll, semester: semFilter })])
          .then(async (data) => {
            setStudentData(data[0].data);
            await Promise.all([
              apis.specificStudent1({ roll: roll, semester: data[0].data.semester }),
              apis.getPHDData(roll),
            ])
              .then((res) => {
                setStudentOtherData(res[0][0]);
                setPhdData(res[1]);
              })
              .catch((err) => {
                throw err;
              });
          })
          .catch((err) => {
            throw new Error(err);
          }));
      if (roll) {
        setLoading(true);
        await apis
          .getPIReportingStatus(roll)
          .then((res) => {
            setPIReportingStatus(res.seatAlloted ? 'Reported' : 'Not Reported');
            setUserDataFiles(res.seatAllotmentLetter);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err.message);
          });
      }
    } catch (err) {}
  };

  const updatePHDStatus = async () => {
    try {
      if (!roll) {
        alert('Please enter a roll no!!');
        return;
      }
      roll &&
        (await apis
          .updatePHDCourse({
            roll: roll,
            option: phdStatus?.option,
            status: phdStatus?.status,
            date: phdStatus?.date,
            progress: phdStatus.progress,
          })
          .then((data) => {
            alert('PHD Status Updated');
          })
          .catch((err) => {
            throw err;
          }));
    } catch (err) {
      alert(err.message);
    }
  };

  const markForRegistration = async () => {
    try {
      if (!roll) {
        alert('Please enter a roll no!!');
        return;
      }
      roll &&
        (await apis
          .markForRegistration({ roll: roll, status: phdRegistrationStatus })
          .then((data) => {
            alert('Marked for Registration');
          })
          .catch((err) => {
            throw err;
          }));
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <>
      <AdminHeader />
      {adminData?.role?.find((e) => {
        return e === 'Academic Admin' || e === 'DOAA' || e === 'Super Admin';
      }) && (
        <div className="top-div">
          <div className="Individual-data-update-div">
            <div className="Individual-data-update-contents">
              <div className="Individual-data-update-details">
                <h2>Search Individual Student:</h2>
                <p>Search for a student and update his/her data (if required).</p>
              </div>
              <div className="Individual-search">
                <input
                  type="text"
                  id="search"
                  placeholder="Enter Student Roll Number"
                  value={roll}
                  onChange={(e) => setRoll(e.target.value)}
                  required
                />
                <input
                  type="text"
                  id="search"
                  placeholder="Semester"
                  value={semFilter}
                  onChange={(e) => setSemFilter(e.target.value)}
                />
                <button className="primary" onClick={fetchData}>
                  Search
                </button>
              </div>
            </div>
            <div className="Individual-search-results">
              <div className="Individual-search-results-contents">
                <h4>
                  Name: <span>{studentData?.name}</span>
                </h4>
                <h4>
                  Roll: <span>{studentData?.roll}</span>
                </h4>
                <h4>
                  Program: <span>{studentData?.course}</span>
                </h4>
                <h4>
                  Semester: <span>{studentData?.semester}</span>
                </h4>
              </div>
              <div className="Individual-search-results-contents">
                <h4>
                  Current CGPA: <span>{studentData?.cgpa}</span>
                </h4>
                <h4>
                  Active Backlog: <span>{studentData?.activeBacklog}</span>
                </h4>
                <h4>
                  Payment Status: <span>{studentOtherData?.paymentStatus}</span>
                </h4>
                <h4>
                  Registration Status: <span>{studentOtherData?.registrationStatus}</span>
                </h4>
              </div>
              <div
                className="Individual-search-results-contents"
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  gap: '10px',
                }}
              >
                <span
                  style={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                    padding: '10px',
                    paddingTop: '0px',
                  }}
                >
                  PI Reporting Status:{' '}
                </span>{' '}
                {loading ? (
                  <span>
                    <CircularProgress size={12} /> Loading...
                  </span>
                ) : (
                  <span>
                    {pireportingStatus === 'Reported' ? (
                      <Link
                        to={arrayBufferToURL(userDataFiles?.data, 'application/pdf')}
                        style={{ marginTop: '5px', textDecoration: 'underline', color: '#5a6dff' }}
                        target="_blank"
                      >{`Reported`}</Link>
                    ) : (
                      pireportingStatus
                    )}
                  </span>
                )}{' '}
              </div>
            </div>
            <div className="Individual-data-update-buttons">
              <div className="add-other-data">
                <div className="add-other-data-element">
                  <h3>Update Registration Status:</h3>
                  <select
                    id="registration-status"
                    value={registrationStatus}
                    onChange={(e) => setRegistrationStatus(e.target.value)}
                  >
                    <option selected disabled value={''}>
                      Select Status
                    </option>
                    <option value={'Registered'}>Registration Completed</option>
                    <option value={'Rejected'}>Registration Rejected</option>
                  </select>
                </div>
                <button className="primary" onClick={() => updateRegistrationStatus()}>
                  Update Status
                </button>
              </div>
            </div>
            {studentData?.course === 'Ph.D.' && (
              <>
                <div className="Individual-search-results">
                  <div className="Individual-search-results-contents">
                    <h4>
                      Date of Admission:{' '}
                      <span>
                        {phdData?.dateOfAddmission
                          ? new Date(phdData?.dateOfAddmission).toLocaleDateString('en-GB')
                          : ''}
                      </span>
                    </h4>
                    <h4>
                      Last date of coursework examination:{' '}
                      <span>
                        {phdData?.lastDateOfCourseWork
                          ? new Date(phdData?.lastDateOfCourseWork).toLocaleDateString('en-GB')
                          : ''}
                      </span>
                    </h4>
                  </div>
                  <div className="Individual-search-results-contents borderTop">
                    <h4>
                      Comprehensive Examination: <br />
                      <span>
                        {phdData?.comprehensive.status}
                        <br /> ({new Date(phdData?.comprehensive?.date).toLocaleDateString('en-GB')}
                        )(Ending Date)
                      </span>
                      <br /> (
                      {phdData?.eligibleForRegistration?.option === 'comprehensive' &&
                      phdData?.eligibleForRegistration?.status === 'true'
                        ? 'Eligible for Registration'
                        : 'Not Eligible for Registration'}
                      )
                    </h4>
                    <h4>
                      State of the Art Seminar: <br />
                      <span>
                        {phdData?.artSeminar.status}
                        <br /> ({new Date(phdData?.artSeminar?.date).toLocaleDateString('en-GB')}
                        )(Ending Date)
                      </span>
                      <br /> (
                      {phdData?.eligibleForRegistration?.option === 'artSeminar' &&
                      phdData?.eligibleForRegistration?.status === 'true'
                        ? 'Eligible for Registration'
                        : 'Not Eligible for Registration'}
                      )
                    </h4>
                    <h4>
                      Progress Review Seminar: <br />
                      <span>
                        {phdData?.reviewSeminar.status}
                        <br /> ({new Date(phdData?.reviewSeminar?.date).toLocaleDateString('en-GB')}
                        )(Ending Date)
                      </span>
                      <br /> (
                      {phdData?.eligibleForRegistration?.option === 'reviewSeminar' &&
                      phdData?.eligibleForRegistration?.status === 'true'
                        ? 'Eligible for Registration'
                        : 'Not Eligible for Registration'}
                      )
                    </h4>
                    <h4>
                      Synopsis Seminar: <br />
                      <span>
                        {phdData?.synopsisSeminar.status}
                        <br /> (
                        {new Date(phdData?.synopsisSeminar?.date).toLocaleDateString('en-GB')}
                        )(Ending Date)
                      </span>
                      <br /> (
                      {phdData?.eligibleForRegistration?.option === 'synopsisSeminar' &&
                      phdData?.eligibleForRegistration?.status === 'true'
                        ? 'Eligible for Registration'
                        : 'Not Eligible for Registration'}
                      )
                    </h4>
                  </div>
                  <div className="Individual-search-results-contents">
                    <h4>
                      Thesis submission date:{' '}
                      <span>
                        {phdData?.ThesisSubmissionDate
                          ? new Date(phdData?.ThesisSubmissionDate).toLocaleDateString('en-GB')
                          : ''}
                      </span>
                    </h4>
                    <h4>
                      Date of viva voce:{' '}
                      <span>
                        {phdData?.dateOfViva
                          ? new Date(phdData?.dateOfViva).toLocaleDateString('en-GB')
                          : ''}
                      </span>
                    </h4>
                  </div>
                </div>
                <div className="Individual-data-update-buttons">
                  <div className="add-other-data">
                    <div className="add-other-data-element">
                      <h3>Choose Option:</h3>
                      <select
                        id="registration-status"
                        value={phdStatus?.option}
                        onChange={(e) =>
                          setPhdStatus((e1) => {
                            return { ...e1, option: e.target.value };
                          })
                        }
                      >
                        <option selected disabled value={''}>
                          Select Type
                        </option>
                        <option value={'dateOfAdmission'}>1. Date of Admission</option>
                        <option value={'courseworkExam'}>2. coursework examination</option>
                        <option value={'comprehensive'}>3. Comprehensive Examination</option>
                        <option value={'artSeminar'}>4. State of the Art Seminar</option>
                        <option value={'reviewSeminar'}>5. Progress Review Seminar</option>
                        <option value={'synopsisSeminar'}>6. Synopsis Seminar</option>
                        <option value={'thesisSubmission'}>7. Thesis submission date</option>
                        <option value={'vivaVoce'}>8. Date of viva voce</option>
                      </select>
                    </div>
                    <div className="add-other-data-element">
                      <h3>Update Status:</h3>
                      <select
                        id="phd-status"
                        value={phdStatus?.status}
                        onChange={(e) => {
                          setPhdStatus((e1) => {
                            return { ...e1, status: e.target.value };
                          });
                        }}
                      >
                        <option selected disabled value={''}>
                          Select Status
                        </option>
                        <option value={'Completed'}>Completed</option>
                        <option value={'Eligible'}>Eligible</option>
                        <option value={'Pending'}>Pending</option>
                        <option value={'Not Eligible'}>Not Eligible</option>
                      </select>
                    </div>
                    <div className="add-other-data-element">
                      <h3>Update Ending Date</h3>
                      <input
                        class="text-input"
                        type="date"
                        value={new Date(phdStatus?.date).toISOString().split('T')[0]}
                        onChange={(e) => {
                          setPhdStatus((e1) => {
                            return { ...e1, date: new Date(e.target.value) };
                          });
                        }}
                        id="comprehensive-examination-date"
                      />
                      {/* date type */}
                      {/* <select id="date-type">
                                            <option selected disabled value={""}>Date Type</option>
                                            <option value={"Starting Date"}>Starting Date</option>
                                            <option value={"Ending Date"}>Ending Date</option>
                                            <option value={"Completion Date"}>Completition Date</option>
                                        </select> */}
                    </div>
                    {['artSeminar', 'reviewSeminar'].includes(phdStatus.option) && (
                      <div className="add-other-data-element">
                        <h3>Choose Progress:</h3>
                        <select
                          id="progress-status"
                          value={phdStatus?.progress ?? ''}
                          onChange={(e) =>
                            setPhdStatus((e1) => {
                              return { ...e1, progress: e.target.value };
                            })
                          }
                        >
                          <option selected disabled value={''}>
                            Select Type
                          </option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                          <option value={6}>6</option>
                          <option value={7}>7</option>
                          <option value={8}>8</option>
                        </select>
                      </div>
                    )}
                    <button className="primary" onClick={updatePHDStatus}>
                      Update Details
                    </button>
                  </div>
                  <div className="add-other-data">
                    <div className="add-other-data-element">
                      <h3>Choose Option:</h3>
                      <select
                        id="registration-status"
                        value={phdRegistrationStatus?.option}
                        onChange={(e) =>
                          setPhdRegistrationStatus((e1) => {
                            return { ...e1, option: e.target.value };
                          })
                        }
                      >
                        <option selected disabled value={''}>
                          Select Type
                        </option>
                        <option value={'comprehensive'}>Comprehensive Examination</option>
                        <option value={'artSeminar'}>State of the Art Seminar</option>
                        <option value={'reviewSeminar'}>Progress Review Seminar</option>
                        <option value={'synopsisSeminar'}>Synopsis Seminar</option>
                      </select>

                      <div className="add-other-data-element">
                        <h3>Registration status:</h3>
                        <select
                          id="program-registration-status"
                          value={phdRegistrationStatus?.status}
                          onChange={(e) =>
                            setPhdRegistrationStatus((e1) => {
                              return { ...e1, status: e.target.value };
                            })
                          }
                        >
                          <option selected disabled value={null}>
                            Select Status
                          </option>
                          <option value={true}>Eligible for Registration Process</option>
                          <option value={false}>Not Eligible</option>
                        </select>
                      </div>
                    </div>
                    <button className="primary" onClick={markForRegistration}>
                      Update registration Status
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      <div className="mid-div">
        <div className="payHistory-section">
          <div className="payHistory-div">
            <div className="title-div cv">
              <div className="title-left-div">
                <h2>Students Record:</h2>
              </div>
            </div>
            <div className="content-div">
              <div className="result-items history">
                <div className="result-heading">
                  <div className="result-heading-item index-column">
                    <h4>S.No</h4>
                  </div>
                  <div className="result-heading-item">
                    <h4>Roll</h4>
                  </div>
                  <div className="result-heading-item">
                    <h4>Name</h4>
                  </div>
                  <div className="result-heading-item">
                    <h4>Program</h4>
                  </div>
                  <div className="result-heading-item">
                    <h4>Batch</h4>
                  </div>
                  <div className="result-heading-item">
                    <h4>Branch</h4>
                  </div>
                  <div className="result-heading-item">
                    <h4>Registration Status</h4>
                  </div>
                  <div className="result-heading-item TID">
                    <h4>Phd Status</h4>
                  </div>
                </div>
                {loadingStatus ? (
                  <Loader />
                ) : (
                  phdStudents
                    ?.filter((e, i) =>
                      e.faculty === adminData?.name && i >= page * 30 && i < page * 30 + 30
                        ? true
                        : false
                    )
                    .map((item, index) => {
                      return (
                        <div className="result-contents">
                          <div className="result-content">
                            <div className="result-content-item index-column">
                              <h4>{0 + page * 30 + 1}</h4>
                            </div>
                            <div className="result-content-item">
                              <h4>{item?.studentId}</h4>
                            </div>
                            <div className="result-content-item">
                              <h4>{item?.name}</h4>
                            </div>
                            <div className="result-content-item">
                              <h4>{item?.program}</h4>
                            </div>
                            <div className="result-content-item">
                              <h4>{item?.batch}</h4>
                            </div>
                            <div className="result-content-item">
                              <h4>{item?.branch}</h4>
                            </div>
                            <div className="result-content-item">
                              <h4>
                                {item?.registrationStatus === 'Approved'
                                  ? 'Eligible'
                                  : item?.registrationStatus}
                              </h4>
                            </div>
                            <div className="result-content-item TID">
                              <h4>Phd Status</h4>
                            </div>
                          </div>
                        </div>
                      );
                    })
                )}
                <div className="pagination">
                  <button className="new_btn" onClick={() => setPage(page > 0 ? page - 1 : page)}>
                    <i className="fa-solid fa-arrow-left"></i>
                  </button>
                  <div className="page-number">
                    <p>
                      {page + 1} / {Math.ceil(0 / 30)}
                    </p>
                  </div>
                  <button
                    className="new_btn"
                    onClick={() => setPage(page + 1 < 0 / 30 ? page + 1 : page)}
                  >
                    <i className="fa-solid fa-arrow-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {adminData?.role?.find((e) => {
        return e === 'DOAA' || e === 'Super Admin';
      }) && (
        <div className="bottom-div">
          <div className="data-upload-div">
            <div className="data-upload-contents">
              <div className="data-upload-details">
                <h2>Upload Results</h2>
                <p>
                  Use this to upload results, It is only for viewing purpose of the students
                  <br /> (
                  <Link
                    to="https://docs.google.com/spreadsheets/d/1DTjrHuELFs91Vs64PiOjg54pzlXd3VGmys7UG6Ot8MY/edit?usp=drive_link"
                    target="_blank"
                  >
                    {' '}
                    Download format{' '}
                  </Link>
                  ) only
                </p>
              </div>
              <div className="data-upload-buttons">
                <form style={{ width: '100%' }} onSubmit={uploadResults}>
                  <input
                    className="required"
                    type="file"
                    id="csv-file"
                    placeholder="Choose .csv file"
                    accept=".csv"
                    value={file?.filename}
                    onChange={(e) => setFile(e.target.files[0])}
                    required
                  />
                  <button className="primary" type="submit">
                    Upload .csv file
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StudentProgressAdmin;
