import React from 'react';
import { AuthProvider } from '../auth/AuthContext';
import Table from '../common/tables/Table.js';
import apis from '../apis';
import '../css/Component/PaymentHistoryTable.css';

const PaymentHistoryTable = ({ admin, roll, semester }) => {
  const [paymentHistory, setPaymentHistory] = React.useState([]);
  const { userData } = AuthProvider();

  React.useEffect(() => {
    const fetchPaymentHistory = async () => {
      (admin ? roll : userData?.roll) &&
        (await apis
          .paymentHistory({
            roll: admin ? roll : userData?.roll,
            semester: admin ? semester : userData?.semester,
          })
          .then((data) => {
            setPaymentHistory(data);
          })
          .catch((err) => {
            alert(err);
          }));
    };
    fetchPaymentHistory();
  }, [userData?.roll, roll, userData?.semester, admin, semester]);

  const columnData = [
    {
      Header: 'S.No.',
      accessor: 'sno',
    },
    {
      Header: 'Date',
      accessor: 'date',
    },
    {
      Header: 'Roll',
      accessor: 'roll',
    },
    {
      Header: 'Amount',
      accessor: 'amount',
    },
    {
      Header: 'Mode',
      accessor: 'mode',
      style: { textAlign: 'center', width: '25%' },
    },
    {
      Header: 'Transaction ID',
      accessor: 'refno',
      style: { textAlign: 'center', width: '25%' },
    },
    {
      Header: 'Status/Verified by',
      accessor: 'status',
      style: { textAlign: 'center', width: '25%' },
    },
    {
      Header: 'Approve/Reject Reason',
      accessor: 'reason',
      style: { textAlign: 'center', width: '25%' },
    },
    {
      Header: 'Download Receipt',
      accessor: 'download',
    },
  ];

  //remove the roll and s.no. column if not admin
  if (!admin) {
    columnData.splice(2, 1);
    columnData.splice(0, 1);
  }

  const statusShow = (status) => {
    if (status === 'Unverified') {
      return 'Pending';
    } else {
      return status;
    }
  };

  const rowData = paymentHistory?.map((payment, index) => {
    console.log(payment);
    return {
      sno: index + 1,
      date: payment.date,
      roll: payment.roll,
      amount: payment.amount,
      mode: payment.type,
      refno: payment.refno,
      status: `${statusShow(payment.status)} ${payment.reason ? `(${payment.reason})` : ''}`,
      reason: payment.reason ? payment.reason : 'Not Specified',
      download:
        payment.type === 'Offline' ? (
          <div
            className="result-content-item"
            style={{ cursor: 'pointer' }}
            onClick={() => downloadReceipt(payment.id)}
          >
            <h4>Download</h4>
          </div>
        ) : (
          <div className="result-content-item">
            <h4>N/A</h4>
          </div>
        ),
      className:
        payment?.type?.includes('Refund') && payment.verifiedBy === 'registrar'
          ? 'RefundMark'
          : payment?.type?.includes('Online')
          ? payment.status === 'SUCCESS'
            ? 'SuccessMark'
            : payment.status === 'FAILED' || payment.status === 'ABORT'
            ? 'FailedMark'
            : 'PendingMark'
          : payment.status.includes('Rejected')
          ? 'FailedMark'
          : payment.status.includes('Step-2/2') || payment.status.includes('Verified By')
          ? 'SuccessMark'
          : 'PendingMark',
    };
  });

  const downloadReceipt = async (id) => {
    await apis
      .fetchReceipt(id)
      .then((data) => {
        let byteArray = new Uint8Array(data.data.receipt.data);
        let blob = new Blob([byteArray], { type: 'application/pdf' });
        let url = window.URL.createObjectURL(blob);
        window.open(url, '_blank');
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  return (
    <div className="paymentTableCont">
      <Table columns={columnData} data={rowData} />
    </div>
  );
};

export default PaymentHistoryTable;
