import React, { useEffect, useState } from 'react';
import AdminHeader from '../components/AdminHeader';
import html2canvas from 'html2canvas';
import CourseTable from '../components/CourseTable';
import FeeTable from '../components/FeeTable';
import bannerIcon from '../assets/heading-banner.jpg';
import apis from '../apis';
import jsPDF from 'jspdf';
import { useParams } from 'react-router-dom';
import { AuthProvider } from '../auth/AuthContext';

const arrayBufferToBase64 = (buffer) => {
  var binary = '';
  var bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => (binary += String.fromCharCode(b)));
  return window.btoa(binary);
};

export const StudentReport = React.forwardRef(({ studentData, admin }, ref) => {
  return (
    <div className="report" ref={ref} style={{ border: '2px solid #000' }}>
      <div className="report-header">
        <img src={bannerIcon} alt="banner-icon" />
      </div>
      <div className="report-title">
        <h3>
          Course Registration ({admin ? 'Student' : 'Admin'} Copy): {studentData?.session})
        </h3>
      </div>
      <div className="report-profile-details">
        <div className="ProfileSummary">
          <div className="profile-summary-item left-text">
            <h4>
              Name: <span>{studentData?.name}</span>
            </h4>
            <h4>
              Program: <span>{studentData?.course}</span>
            </h4>
            <h4>
              Branch: <span>{studentData?.branch}</span>
            </h4>
            <h4>
              Type: <span>{studentData?.type}</span>
            </h4>
          </div>
          <div className="profile-summary-item right-text">
            <h4>
              Roll: <span>{studentData?.roll}</span>
            </h4>
            <h4>
              Batch: <span>{studentData?.batch}</span>
            </h4>
            <h4>
              Semester: <span>{studentData?.semester}</span>
            </h4>
            <h4>
              Active Backlog: <span>{studentData?.activeBacklog}</span>
            </h4>
          </div>
        </div>
        <div className="profile-picture">
          <img
            src={`data:image/jpg;base64,${arrayBufferToBase64(studentData?.picture?.data).toString(
              'base64'
            )}`}
            alt="profile-pic"
          />
        </div>
      </div>
      <div className="course-table-section">
        {studentData && (
          <CourseTable user={studentData} semester={studentData?.semester} admin={true} />
        )}
      </div>
      <div className="fee-table-section">{studentData && <FeeTable student={studentData} />}</div>
      <div className="verification-indicator">
        <div className="indicator-div">
          <div className="indicator-item left-text">
            <h4>
              {/* {console.log(studentFees?.paymentStatus)} */}
              Payment Status: <span>{studentData?.paymentStatus}</span>
            </h4>
          </div>
          <div className="indicator-item right-text">
            <h4>
              Registration Status:{' '}
              <span>
                {studentData?.registrationStatus === 'Approved'
                  ? 'Eligible for Physical registration'
                  : studentData?.registrationStatus}
              </span>
            </h4>
          </div>
        </div>
      </div>
      <div className="space-for-sign">
        <div className="sign-div">
          <div className="sign-item left-sign-text">
            <div className="space-unit"></div>
            <h4>Faculty Advisor</h4>
          </div>
          <div className="sign-item">
            <div className="space-unit"></div>
            <h4>Hostel Warden</h4>
          </div>
          <div className="sign-item right-sign-text">
            <div className="space-unit"></div>
            {admin ? <h4>F/I Academic UG/PG</h4> : <h4>Student's Sign</h4>}
          </div>
        </div>
      </div>
    </div>
  );
});

const RegistrationSlipPage = () => {
  const [feeStructure, setFeeStructure] = React.useState();
  const { adminData } = AuthProvider();

  const { roll, applicationid } = useParams();
  const semester = new URLSearchParams(window.location.search).get('semester');

  const [studentData, setStudentData] = useState();

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const response = await apis.newProfile(roll, applicationid, semester);
        console.log(response);
        setStudentData(response);
      } catch (error) {
        alert(error.message);
      }
    };
    fetchStudentData();
  }, [roll, applicationid, semester]);

  const printRef = React.useRef();
  const printRef1 = React.useRef();
  const print = async () => {
    const canvas = await html2canvas(printRef.current);
    const canvas1 = await html2canvas(printRef1.current);
    const imgData = canvas.toDataURL('image/png');
    const imgData1 = canvas1.toDataURL('image/png');
    const pdf = new jsPDF('landscape');
    pdf.addImage(imgData1, 'JPEG', 2, 2, 148, 205, 'adminCopy', 'SLOW');
    pdf.addImage(imgData, 'JPEG', 152, 2, 142, 205, 'studentCopy', 'SLOW');
    pdf.save(`${roll}_receipt.pdf`);
  };

  const fetchFeeStructure = React.useCallback(async () => {
    studentData?.type &&
      (await apis
        .feeStructure(
          studentData?.year,
          studentData?.course,
          studentData?.semester,
          studentData?.batch,
          studentData?.type
        )
        .then((data) => {
          setFeeStructure(data);
        })
        .catch((err) => {
          alert(err.message);
        }));
  }, [studentData]);

  React.useEffect(() => {
    fetchFeeStructure();
  }, [fetchFeeStructure]);

  return (
    <div>
      <AdminHeader />
      <div className="mid-div">
        <div className="reportsView-section">
          <StudentReport ref={printRef} admin={true} studentData={studentData} />
          <StudentReport ref={printRef1} admin={false} studentData={studentData} />
        </div>
      </div>
      <div
        className=""
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '100px',
        }}
      >
        <button
          id="print"
          className="new_btn primary"
          onClick={print}
          style={{ padding: '10px 50px', borderRadius: '8px', fontSize: '20px' }}
        >
          <span>Download Printable Copy</span>
        </button>
      </div>
    </div>
  );
};

export default RegistrationSlipPage;
