import React, { useEffect, useRef, useState } from 'react';
import AdminHeader from '../components/AdminHeader';
import { AuthProvider } from '../auth/AuthContext';
import { useParams } from 'react-router-dom';
import apis from '../apis';
import '../css/Page/NewStudentProfilePage.css';

import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import PersonalDetails from '../components/PersonalDetails';
import OtherDetails from '../components/OtherDetails';
import AcademicsDetail from '../components/AcademicsDetail';
import PaymentDetails from '../components/PaymentDetails';
import ActionCenter from '../components/ActionCenter';

const arrayBufferToBase64 = (buffer) => {
  var binary = '';
  var bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => (binary += String.fromCharCode(b)));
  return window.btoa(binary);
};

const NewStudentProfilePage = () => {
  const { adminData } = AuthProvider();
  const selectFile = useRef(null);
  const { roll, applicationid } = useParams();
  const semester = new URLSearchParams(window.location.search).get('semester');

  const [studentData, setStudentData] = useState();
  const [showProfilePreview, setShowProfilePreview] = useState(false);
  const [state, setState] = useState('personal');

  const toggleState = (state) => {
    setState(state);
  };

  useEffect(() => {
    if (adminData) {
      const fetchStudentData = async () => {
        try {
          const response = await apis.newProfile(roll, applicationid, semester);

          setStudentData(response);
        } catch (error) {
          alert(error.message);
        }
      };
      fetchStudentData();
    }
  }, [roll, applicationid, semester, adminData]);

  const handleClick = () => {
    selectFile.current?.click();
  };

  const handleChange = async (e) => {
    console.log(e.target.files[0]);

    const formData = new FormData();
    formData.append('picture', e.target.files[0]);
    formData.append('roll', roll);
    formData.append('applicationid', applicationid);

    await apis
      .updateProfilePic(formData)
      .then((data) => {
        alert('Profile Picture updated successfully');
        window.location.reload();
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  return (
    <div>
      <AdminHeader />
      {showProfilePreview && (
        <div className="profilePreview" onClick={() => setShowProfilePreview(false)}>
          <div className="imageContPreview">
            <img
              src={`data:image/jpg;base64,${arrayBufferToBase64(
                studentData?.picture?.data
              ).toString('base64')}`}
              alt="profile-pic"
            />
          </div>
        </div>
      )}
      <div className="profilePageCont">
        <div className="banner"></div>
        <div className="profileCont">
          <div className="leftProfile">
            <div className="profileImageCont">
              <div className="imageCont">
                <img
                  src={`data:image/jpg;base64,${arrayBufferToBase64(
                    studentData?.picture?.data
                  ).toString('base64')}`}
                  alt="profile-pic"
                  onClick={() => setShowProfilePreview(true)}
                />
              </div>
              {adminData?.role?.length === undefined ||
              adminData?.role?.length === null ? null : adminData?.role?.includes('Super Admin') ||
                adminData?.role?.includes('DOAA') ||
                adminData?.role?.includes('Academic Admin') ? (
                <div className="imageEdit" id="imageEdit" onClick={handleClick}>
                  <CameraAltOutlinedIcon fontSize="small" color="black" />
                  <input
                    type="file"
                    ref={selectFile}
                    onChange={handleChange}
                    htmlFor="imageEdit"
                    accept=".jpg, .jpeg, .png"
                    hidden
                  />
                </div>
              ) : null}
            </div>
            <div className="nameEmailCont">
              <div className="name1">{studentData?.name}</div>
              <div className="email">{studentData?.instituteEmailId || studentData?.email}</div>
            </div>
            <div
              className={`registrationStatus ${
                studentData?.registrationStatus === 'Registered' ? 'complete' : 'incomplete'
              }`}
            >
              {studentData?.registrationStatus.toUpperCase()}
            </div>
            <div className="basicDetails">
              <div className="details">
                <div className="label">Roll:</div>
                <div className="value">{studentData?.roll}</div>
              </div>
              <div className="details">
                <div className="label">Course:</div>
                <div className="value">{studentData?.course}</div>
              </div>
              <div className="details">
                <div className="label">Branch:</div>
                <div className="value">{studentData?.branch}</div>
              </div>
              <div className="details">
                <div className="label">Semester:</div>
                <div className="value">{studentData?.semester}</div>
              </div>
              <div className="details">
                <div className="label">Batch:</div>
                <div className="value">{studentData?.batch}</div>
              </div>
            </div>
          </div>
          <div className="rightProfileData">
            <div className="switchBar">
              <div
                className={`items ${state === 'personal' ? 'currActive' : ''}`}
                onClick={() => toggleState('personal')}
              >
                Personal Details
              </div>
              <div
                className={`items ${state === 'other' ? 'currActive' : ''}`}
                onClick={() => toggleState('other')}
              >
                Other Details
              </div>
              <div
                className={`items ${state === 'academics' ? 'currActive' : ''}`}
                onClick={() => toggleState('academics')}
              >
                Academics Details
              </div>
              {adminData?.role?.length === undefined ||
              adminData?.role?.length === null ? null : adminData?.role?.length === 1 &&
                adminData?.role?.includes('Faculty') ? null : (
                <div
                  className={`items ${state === 'paymentHostel' ? 'currActive' : ''}`}
                  onClick={() => toggleState('paymentHostel')}
                >
                  Payment and Hostel
                </div>
              )}
              {adminData?.role?.length === undefined ||
              adminData?.role?.length === null ? null : adminData?.role?.length === 1 &&
                adminData?.role?.includes('Faculty') ? null : (
                <div
                  className={`items ${state === 'actions' ? 'currActive' : ''}`}
                  onClick={() => toggleState('actions')}
                >
                  Actions
                </div>
              )}
            </div>
            {state === 'personal' ? (
              <PersonalDetails studentData={studentData} adminData={adminData} />
            ) : state === 'other' ? (
              <OtherDetails studentData={studentData} adminData={adminData} />
            ) : state === 'academics' ? (
              <AcademicsDetail studentData={studentData} />
            ) : state === 'paymentHostel' ? (
              adminData?.role?.length === 1 && adminData?.role?.includes('Faculty') ? (
                <div className="">Not Authorized to view this content</div>
              ) : (
                <PaymentDetails studentData={studentData} adminData={adminData} />
              )
            ) : state === 'actions' ? (
              adminData?.role?.length === 1 && adminData?.role?.includes('Faculty') ? (
                <div className="">Not Authorized to view this content</div>
              ) : (
                <ActionCenter
                  roll={roll}
                  applicationid={applicationid}
                  studentData={studentData}
                  semester={semester}
                />
              )
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewStudentProfilePage;
