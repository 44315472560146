import React, { useEffect } from 'react';
import { AuthProvider } from '../auth/AuthContext';
import apis from '../apis';
import AdminHeader from '../components/AdminHeader';
import GeneralFilter1 from '../common/filters/GeneralFilter1';
import Table from '../common/tables/Table';
import '../css/Page/StudentPromotionPage.css';

const StudentPromotionPage = () => {
  const { adminData } = AuthProvider();

  const [studentDatas, setStudentDatas] = React.useState([]);
  const [filteredData, setFilteredData] = React.useState([]);
  const [selectedData, setSelectedData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [promotionSemester, setPromotionSemester] = React.useState('');
  const [promotionYear, setPromotionYear] = React.useState('');
  const [demotionSemester, setDemotionSemester] = React.useState('');
  const [demotionYear, setDemotionYear] = React.useState('');
  const [promotionSession, setPromotionSession] = React.useState('');
  const [isRegistrationOpen, setIsRegistrationOpen] = React.useState(false);

  const fetchStudentDatas = async () => {
    setLoading(true);
    apis.getAllStudents().then((res) => {
      setStudentDatas(res);
      setLoading(false);
      return res.data;
    }).catch((err) => {
      console.log(err);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchStudentDatas();
  }, []);

  const [filterAttribute, setFilterAttribute] = React.useState({
    roll: '',
    course: '',
    batch: '',
    branch: '',
    semester: '',
    year: '',
  });

  const requiredFilters = ['roll', 'course', 'batch', 'branch', 'semester', 'year'];

  useEffect(() => {
    if (studentDatas.length > 0) {
      let tempData = studentDatas;
      if (filterAttribute.roll !== '') {
        tempData = tempData.filter((data) => data.roll.includes(filterAttribute.roll));
      }
      if (filterAttribute.course !== '') {
        tempData = tempData.filter((data) => data.course === filterAttribute.course);
      }
      if (filterAttribute.branch !== '') {
        tempData = tempData.filter((data) => data.branch === filterAttribute.branch);
      }
      if (filterAttribute.batch !== '') {
        tempData = tempData.filter((data) => data.batch === filterAttribute.batch);
      }
      if (filterAttribute.semester !== '') {
        tempData = tempData.filter((data) => data.semester === filterAttribute.semester);
      }
      if (filterAttribute.year !== '') {
        tempData = tempData.filter((data) => data.year === filterAttribute.year);
      }
      setFilteredData(tempData);
    }
  }, [filterAttribute, studentDatas]);

  const columns = [
    {
      Header: (
        <input
          className="checkbox"
          type="checkbox"
          checked={filteredData.length > 0 && filteredData.every((data) => data.checkbox)}
          onChange={(e) => {
            const newStudentDatas = filteredData.map((data) => {
              return {
                ...data,
                checkbox: e.target.checked,
              };
            });
            setFilteredData(newStudentDatas);
            setSelectedData(e.target.checked ? newStudentDatas.map((data) => data.id) : []);
          }}
        />
      ),
      accessor: 'checkbox',
      style: { width: '50px' },
      showContextMenu: false,
    },
    {
      Header: 'Roll No.',
      accessor: 'roll',
      style: { width: '110px', minWidth: '110px', cursor: 'pointer' },
      showContextMenu: true,
    },
    {
      Header: 'Name',
      accessor: 'name',
      style: { width: '100%', minWidth: '100%', cursor: 'pointer' },
      showContextMenu: true,
    },
    {
      Header: 'Batch',
      accessor: 'batch',
      style: { width: '90px', minWidth: '90px', cursor: 'pointer' },
      showContextMenu: true,
    },
    {
      Header: 'Program',
      accessor: 'program',
      style: { width: '85px', minWidth: '85px', cursor: 'pointer' },
      showContextMenu: true,
    },
    {
      Header: 'Semester',
      accessor: 'semester',
      style: { width: '80px', minWidth: '80px', cursor: 'pointer' },
      showContextMenu: true,
    },
    {
      Header: 'Year',
      accessor: 'year',
      style: { width: '80px', minWidth: '80px', cursor: 'pointer' },
      showContextMenu: true,
    },
  ];

  const rowData = filteredData.map((data, index) => {
    return {
      checkbox: (
        <input
          className="checkbox"
          type="checkbox"
          checked={data.checkbox}
          onChange={(e) => {
            const newStudentDatas = filteredData.map((studData) => {
              if (studData.id === data.id) {
                return {
                  ...studData,
                  checkbox: e.target.checked,
                };
              }
              return studData;
            });
            setFilteredData(newStudentDatas);
            setSelectedData(
              e.target.checked
                ? [...selectedData, data.id]
                : selectedData.filter((id) => id !== data.id)
            );
          }}
        />
      ),
      roll: data.roll,
      name: data.name,
      batch: data.batch,
      program: data.course,
      semester: data.semester,
      year: data.year,
    };
  });

  const promotetheStudents = async () => {
    setLoading2(true);
    const promotionData = {
      students: studentDatas.filter((data) => selectedData.includes(data.id)),
      promotedSemester: promotionSemester,
      promotedYear: promotionYear,
      promotedSession: promotionSession,
    };
    apis.promoteStudents(promotionData).then((res) => {
      setLoading2(false);
      alert('Students promoted successfully');
      fetchStudentDatas();
    });
  };

  const demotetheStudents = async () => {
    setLoading2(true);
    const demotionData = {
      students: studentDatas.filter((data) => selectedData.includes(data.id)),
      demotedSemester: demotionSemester,
      demotedYear: demotionYear,
    };
    console.log(demotionData);
    apis.demoteStudents(demotionData).then((res) => {
      setLoading2(false);
      alert('Students demoted successfully');
      fetchStudentDatas();
    });
  };

  const updateRegistrationOpenStatus = async () => {
    setLoading2(true);
    const registrationData = {
      students: studentDatas.filter((data) => selectedData.includes(data.id)),
      allowRegistration: isRegistrationOpen,
    };
    console.log(registrationData);
    apis.updateRegistrationOpenStatus(registrationData).then((res) => {
      setLoading2(false);
      alert('Registration status updated successfully');
      fetchStudentDatas();
    });
  };

  const openHostelMessPayments = async () => {
    const data = {
      endTime: new Date(new Date().setMonth(new Date().getMonth() + 2)).toISOString(),
      limit1: 10000,
      limit2: 20000,
      type: 'HOSTEL',
      openFor: selectedData.map((id) => studentDatas.find((data) => data.id === id).roll),
      closeFor: [],
      session: promotionSession || '2024-25',
    };
    await apis.setSchedule(data).then((res) => {
      alert('Hostel/Mess Payments opened successfully');
    });
  };

  return (
    <div>
      <AdminHeader />
      <GeneralFilter1
        filterHeading="Filter Data By"
        filters={requiredFilters}
        setFilterAttribute={setFilterAttribute}
        filterAttribute={filterAttribute}
        containerClass=""
        titleClass="newAdmissionFilterTitle"
      />
      <div className="secondMessHalf">
        <div className="messSwapTable">
          <Table columns={columns} data={rowData} loading={loading} className="" />
        </div>
        <div className="schedularPart">
          <div className="pdcont">
            <div className="pd-title">Promote Selected Students</div>
            <div className="pd-sec">
              <label htmlFor="">Choose Semester:</label>
              <select name="" id="" onChange={(e) => setPromotionSemester(e.target.value)}>
                <option value="">Semester</option>
                {filterAttribute.course === 'B.TECH.'
                  ? ['1', '2', '3', '4', '5', '6', '7', '8'].map((semester, i) => (
                      <option key={i} value={semester}>
                        {semester}
                        {semester === '1'
                          ? 'st'
                          : semester === '2'
                          ? 'nd'
                          : semester === '3'
                          ? 'rd'
                          : 'th'}
                      </option>
                    ))
                  : filterAttribute.course === 'M.TECH.'
                  ? ['1', '2', '3', '4', '5', '6'].map((semester, i) => (
                      <option key={i} value={semester}>
                        {semester}
                        {semester === '1'
                          ? 'st'
                          : semester === '2'
                          ? 'nd'
                          : semester === '3'
                          ? 'rd'
                          : 'th'}
                      </option>
                    ))
                  : filterAttribute.course === 'Ph.D.'
                  ? ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'].map(
                      (semester, i) => (
                        <option key={i} value={semester}>
                          {semester}th
                        </option>
                      )
                    )
                  : null}
              </select>
            </div>
            <div className="pd-sec">
              <label htmlFor="">Choose Year:</label>
              <select name="" id="" onChange={(e) => setPromotionYear(e.target.value)}>
                <option value="">Year</option>
                {filterAttribute.course === 'B.TECH.'
                  ? ['1', '2', '3', '4'].map((year, i) => (
                      <option key={i} value={year}>
                        {year}
                        {year === '1' ? 'st' : year === '2' ? 'nd' : year === '3' ? 'rd' : 'th'}
                      </option>
                    ))
                  : filterAttribute.course === 'M.TECH.'
                  ? ['1', '2', '3'].map((year, i) => (
                      <option key={i} value={year}>
                        {year}
                        {year === '1' ? 'st' : year === '2' ? 'nd' : year === '3' ? 'rd' : 'th'}
                      </option>
                    ))
                  : filterAttribute.course === 'Ph.D.'
                  ? ['1', '2', '3', '4', '5', '6'].map((year, i) => (
                      <option key={i} value={year}>
                        {year}th
                      </option>
                    ))
                  : null}
              </select>
            </div>
            <div className="pd-sec">
              <label htmlFor="">Enter Session(20XX-XX format only):</label>
              <input
                type="text"
                className="pd-input"
                onChange={(e) => setPromotionSession(e.target.value)}
              />
            </div>
            <button className="primary" disabled={loading2} onClick={() => promotetheStudents()}>
              Promote
            </button>
          </div>
          <div className="pdcont">
            <div className="pd-title">Demote Selected Students</div>
            <div className="pd-sec">
              <label htmlFor="">Choose Semester:</label>
              <select name="" id="" onChange={(e) => setDemotionSemester(e.target.value)}>
                <option value="">Semester</option>
                {filterAttribute.course === 'B.TECH.'
                  ? ['1', '2', '3', '4', '5', '6', '7', '8'].map((semester, i) => (
                      <option key={i} value={semester}>
                        {semester}
                        {semester === '1'
                          ? 'st'
                          : semester === '2'
                          ? 'nd'
                          : semester === '3'
                          ? 'rd'
                          : 'th'}
                      </option>
                    ))
                  : filterAttribute.course === 'M.TECH.'
                  ? ['1', '2', '3', '4', '5', '6'].map((semester, i) => (
                      <option key={i} value={semester}>
                        {semester}
                        {semester === '1'
                          ? 'st'
                          : semester === '2'
                          ? 'nd'
                          : semester === '3'
                          ? 'rd'
                          : 'th'}
                      </option>
                    ))
                  : filterAttribute.course === 'Ph.D.'
                  ? ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'].map(
                      (semester, i) => (
                        <option key={i} value={semester}>
                          {semester}th
                        </option>
                      )
                    )
                  : null}
              </select>
            </div>
            <div className="pd-sec">
              <label htmlFor="">Choose Year:</label>
              <select name="" id="" onChange={(e) => setDemotionYear(e.target.value)}>
                <option value="">Year</option>
                {filterAttribute.course === 'B.TECH.'
                  ? ['1', '2', '3', '4'].map((year, i) => (
                      <option key={i} value={year}>
                        {year}
                        {year === '1' ? 'st' : year === '2' ? 'nd' : year === '3' ? 'rd' : 'th'}
                      </option>
                    ))
                  : filterAttribute.course === 'M.TECH.'
                  ? ['1', '2', '3'].map((year, i) => (
                      <option key={i} value={year}>
                        {year}
                        {year === '1' ? 'st' : year === '2' ? 'nd' : year === '3' ? 'rd' : 'th'}
                      </option>
                    ))
                  : filterAttribute.course === 'Ph.D.'
                  ? ['1', '2', '3', '4', '5', '6'].map((year, i) => (
                      <option key={i} value={year}>
                        {year}th
                      </option>
                    ))
                  : null}
              </select>
            </div>
            <button className="primary" disabled={loading2} onClick={() => demotetheStudents()}>
              Demote
            </button>
          </div>
          <div className="pdcont">
            <div className="pd-title">Open Registration for Selected Students</div>
            <div className="pd-sec">
              <label htmlFor="">Select Status:</label>
              <select name="" id="" onChange={(e) => setIsRegistrationOpen(e.target.value)}>
                <option value="">Status</option>
                <option value={true}>Open</option>
                <option value={false}>Close</option>
              </select>
            </div>
            <button
              className="primary"
              disabled={loading2}
              onClick={() => updateRegistrationOpenStatus()}
            >
              Update
            </button>
          </div>
          <div className="pdcont">
            <div className="pd-title">Open Hostel/Mess Payments for Selected Students</div>
            <button
              className="primary"
              disabled={loading2}
              onClick={() => openHostelMessPayments()}
            >
              Open
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentPromotionPage;
