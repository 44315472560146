import * as React from 'react';
import '../css/Page/Result.css';
import Header from '../components/Header';
import bannerIcon from '../assets/heading-banner.jpg';
import ResultScoreElement from '../components/ResultScoreElement';
import apis from '../apis';
import { AuthProvider } from '../auth/AuthContext';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ButtonLoader from '../components/ButtonLoader';

const Result = () => {
  const [resultStatus, setResultStatus] = React.useState(false);

  // const [studentType, setStudentType] = React.useState("");
  // const [result, setResult] = React.useState(true);
  const [studentType, setStudentType] = React.useState('');
  const [resultData, setResultData] = React.useState([]);
  const { userData } = AuthProvider();
  const [semester, setSemester] = React.useState('0');
  const printRef = React.useRef();
  const date = new Date();

  const print = async () => {
    const canvas = await html2canvas(printRef.current);
    // const canvas1 = await html2canvas(printRef1.current);
    const imgData = canvas.toDataURL('image/png');
    // const imgData1 = canvas1.toDataURL("image/png");
    const pdf = new jsPDF('portrait');
    // pdf.addImage(imgData1, "JPEG", 2, 2, 148, 205, "adminCopy", "SLOW");
    pdf.addImage(imgData, 'JPEG', 3, 2, 205, 255, 'result', 'SLOW');
    pdf.save(`${userData?.roll}_result.pdf`);
  };

  React.useEffect(() => {
    const fetchType = async () => {
      await apis
        .specificStudent1({ roll: userData?.roll })
        .then((data) => {
          setStudentType(data[0]?.type);
        })
        .catch((err) => {
          alert(err.message);
        });
    };
    fetchType();
  }, [userData]);

  React.useEffect(() => {
    // const fetchStudentOtherDetails = async () => {
    //     await apis.specificStudent1({ roll: userData?.roll }).then((data) => {
    const fetchResults = async () => {
      semester &&
        (await apis
          .getResult(semester)
          .then((data) => {
            setResultData(data);
          })
          .catch((err) => {
            alert(err.message);
          }));
    };
    fetchResults();
  }, [semester]);

  const [btnLoadStatus, setBtnLoadStatus] = React.useState(false);

  const ShowResult = (sem) => {
    setBtnLoadStatus(true);
    setSemester(sem);
    setResultStatus(true);
    setBtnLoadStatus(false);
  };

  return (
    <>
      <Header />
      <div className="top-div">
        <div className="result-window">
          {resultStatus ? (
            <div className="result-display">
              <div className="result-display-div" ref={printRef}>
                <div className="marksheet">
                  <div className="marksheet-header">
                    <div className="Institute-banner">
                      <img src={bannerIcon} alt="banner-icon" />
                    </div>
                    <div className="marksheet-subheader">
                      <h4>Provisional Grade Card</h4>
                    </div>
                  </div>
                  <div className="marksheet-student-details">
                    <div className="student-details-top">
                      <h4>
                        Name: <span>{userData?.name}</span>
                      </h4>
                    </div>
                    <div className="student-details-bottom">
                      <div className="student-details-left">
                        <h4>
                          Roll: <span>{userData?.roll}</span>
                        </h4>
                        <h4>
                          Program: <span>{userData?.course}</span>
                        </h4>
                        <h4>
                          Branch: <span>{userData?.branch}</span>
                        </h4>
                        <h4>
                          Type: <span>{studentType}</span>
                        </h4>
                      </div>
                      <div className="student-details-right">
                        <h4>
                          Batch: <span>{userData?.batch}</span>
                        </h4>
                        <h4>
                          Academic Year: <span>{resultData[0]?.academicYear}</span>
                        </h4>
                        <h4>
                          Session:{' '}
                          <span>
                            {Number(resultData[0]?.semester) % 2 === 0 ? 'Spring' : 'Autumn'}
                          </span>
                        </h4>
                        <h4>
                          Semester: <span>{resultData[0]?.semester}</span>
                        </h4>
                      </div>
                    </div>
                    <div className="marksheet-subject-scores">
                      <div className="subject-scores-header">
                        <div className="subject-scores-header-element">
                          <h4>Code</h4>
                        </div>
                        <div className="subject-scores-header-element">
                          <h4>Course Name</h4>
                        </div>
                        <div className="subject-scores-header-element">
                          <h4>Credit</h4>
                        </div>
                        <div className="subject-scores-header-element">
                          <h4>Grade</h4>
                        </div>
                        <div className="subject-scores-header-element">
                          <h4>Grade Point</h4>
                        </div>
                        <div className="subject-scores-header-element">
                          <h4>Type</h4>
                        </div>
                      </div>
                      <div className="subject-scores-contents">
                        {resultData?.map((data) => {
                          return <ResultScoreElement data={data} />;
                        })}
                      </div>
                    </div>
                    <div className="marksheet-total-scores">
                      <div className="total-scores-top">
                        <div className="total-scores-left">
                          <h4>
                            Total Semester Credit: <span>{resultData[0]?.scredit}</span>
                          </h4>
                          <h4>
                            Total Semester Grade Points: <span>{resultData[0]?.sgp}</span>
                          </h4>
                        </div>
                        <div className="total-scores-right">
                          <h4>
                            Total Cumulative Credit: <span>{resultData[0]?.ccredit}</span>
                          </h4>
                          <h4>
                            Total Cumulative Grade Points: <span>{resultData[0]?.cgp}</span>
                          </h4>
                        </div>
                      </div>
                      <div className="total-scores-bottom">
                        <div className="total-scores-left">
                          <h4>
                            Semester Grade Points Average (SGPA): <span>{resultData[0]?.sgpa}</span>
                          </h4>
                        </div>
                        <div className="total-scores-right">
                          <h4>
                            Cumulative Grade Points Average (CGPA):{' '}
                            <span>{resultData[0]?.cgpa}</span>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="marksheet-footer">
                      <div className="total-scores-top">
                        <div className="total-scores-left">
                          <p>
                            This is a computer generated grade card published by IIIT Bhagalpur.
                            This is a provisional grade card and is subject to approval from senate.
                          </p>
                        </div>
                        <div className="total-scores-right">
                          {/* <img src={stamp} alt='stamp' /> */}
                          <h4>
                            {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="side-div">
                <div className="result-side-div">
                  <div className="result-element" onClick={() => setSemester('8')}>
                    <h4>8th Semester</h4>
                  </div>
                  <div className="result-element" onClick={() => setSemester('7')}>
                    <h4>7th Semester</h4>
                  </div>
                  <div className="result-element" onClick={() => setSemester('6')}>
                    <h4>6th Semester</h4>
                  </div>
                  <div className="result-element" onClick={() => setSemester('5')}>
                    <h4>5th Semester</h4>
                  </div>
                  <div className="result-element" onClick={() => setSemester('4')}>
                    <h4>4th Semester</h4>
                  </div>
                  <div className="result-element" onClick={() => setSemester('3')}>
                    <h4>3rd Semester</h4>
                  </div>
                  <div className="result-element" onClick={() => setSemester('2')}>
                    <h4>2nd Semester</h4>
                  </div>
                  <div className="result-element" onClick={() => setSemester('1')}>
                    <h4>1st Semester</h4>
                  </div>
                </div>
                <div className="result-side-div">
                  <div className="printing-div-title">
                    <h4>Print Options:</h4>
                  </div>
                  <div className="printing-div-item">
                    <button id="print" className="new_btn" onClick={print}>
                      <span>Download Printable Copy</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="result-menu-div">
              <div className="result-element" onClick={() => ShowResult(8)}>
                <h4>8th Semester</h4>
                <h4>
                  <span>Check now</span>
                </h4>
              </div>
              {btnLoadStatus === false ? (
                <div className="result-element" onClick={() => ShowResult(7)}>
                  <h4>7th Semester</h4>
                  <h4>
                    <span>Check now</span>
                  </h4>
                </div>
              ) : (
                <div className="result-element">
                  <ButtonLoader />
                </div>
              )}
              <div className="result-element" onClick={() => ShowResult(6)}>
                <h4>6th Semester</h4>
                <h4>
                  <span>Check now</span>
                </h4>
              </div>
              <div className="result-element" onClick={() => ShowResult(5)}>
                <h4>5th Semester</h4>
                <h4>
                  <span>Check now</span>
                </h4>
              </div>
              <div className="result-element" onClick={() => ShowResult(4)}>
                <h4>4th Semester</h4>
                <h4>
                  <span>Check now</span>
                </h4>
              </div>
              <div className="result-element" onClick={() => ShowResult(3)}>
                <h4>3rd Semester</h4>
                <h4>
                  <span>Check now</span>
                </h4>
              </div>
              <div className="result-element" onClick={() => ShowResult(2)}>
                <h4>2nd Semester</h4>
                <h4>
                  <span>Check now</span>
                </h4>
              </div>
              <div className="result-element" onClick={() => ShowResult(1)}>
                <h4>1st Semester</h4>
                <h4>
                  <span>Check now</span>
                </h4>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Result;
