import React from 'react';
import '../css/Component/Loader.css';

const Loader = () => {
  return (
    <>
      <div
        className=""
        style={{ textAlign: 'center', marginTop: '10px', color: 'gray', fontSize: '14px' }}
      >
        Have patience, we are fetching appropriate data for you. It may take few seconds to load the
        data.
      </div>
      <div className="loader-container">
        <div className="dash uno"></div>
        <div className="dash dos"></div>
        <div className="dash tres"></div>
        <div className="dash cuatro"></div>
      </div>
    </>
  );
};

export default Loader;
