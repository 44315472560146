import * as React from 'react';
import AdminHeader from '../components/AdminHeader';
import CourseRequest from '../components/CourseRequest';
import '../css/Page/CourseVerification.css';
import apis from '../apis';
import { AuthProvider } from '../auth/AuthContext';

const CourseVerification = () => {
  const [list, setList] = React.useState([]);
  const [batchList, setBatchList] = React.useState();
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(false);
  const [totalRequests, setTotalRequests] = React.useState(0);
  const limit = 10;
  const { adminData } = AuthProvider();
  const [facultyData, setFacultyData] = React.useState();
  const [search, setSearch] = React.useState({
    semester: null,
    paymentStatus: null,
    backlog: null,
    program: null,
    batch: null,
  });
  const fetchFacultyData = React.useCallback(async () => {
    adminData?.id &&
      (await apis
        .getFaculty(adminData?.id)
        .then((data) => {
          setFacultyData(data);
        })
        .catch((err) => {
          alert(err.message);
        }));
  }, [adminData]);

  const fetchData = async (pageNum) => {
    setIsLoading(true);
    try {
      const data = await apis.fetchVerifiableCourses({
        semester: search?.semester,
        batch: search?.batch,
        program: search?.program,
        page: pageNum,
        limit,
      });
      console.log(data.total);
      const uniqueRequests = Array.from(
        new Set(data.data.map((r) => `${r.studentId}-${r.semester}`))
      ).map((key) => data.data.find((r) => `${r.studentId}-${r.semester}` === key));
      setList(uniqueRequests);
      setTotalPages(data.totalPages);
      setIsLoading(false);
      setTotalRequests(data.total);
    } catch (error) {
      setIsLoading(false);
      alert(error.message);
    }
  };

  const handleVerificationComplete = async (verifiedId) => {
    const remainingItems = totalRequests - 1;
    const newTotalPages = Math.ceil(remainingItems / limit);
    setTotalRequests(remainingItems);

    // Fetch additional item to maintain 10 items
    try {
      const response = await apis.fetchVerifiableCourses({
        semester: search?.semester,
        batch: search?.batch,
        page,
        limit,
      });
      const uniqueResponse = Array.from(
        new Set(response.data.map((r) => `${r.studentId}-${r.semester}`))
      ).map((key) => response.data.find((r) => `${r.studentId}-${r.semester}` === key));
      console.log(uniqueResponse);
      setList((prev) => {
        // Remove verified request
        const filtered = prev.filter((req) => req.id !== verifiedId);

        // Add new unique request if available
        if (uniqueResponse.length > 0) {
          const newRequest = uniqueResponse[uniqueResponse.length - 1];
          if (!filtered.some((req) => req.id === newRequest.id)) {
            filtered.push(newRequest);
          }
        }

        // Ensure array has only unique items
        // const uniqueFiltered = Array.from(new Set(filtered.map(r => JSON.stringify(r))))
        //   .map(str => JSON.parse(str));

        return filtered;
      });

      setTotalPages(newTotalPages);
    } catch (err) {
      alert(err.message);
    }
  };

  const fetchBatchList = async () => {
    await apis
      .getBatchFilterList()
      .then((data) => {
        setBatchList(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  React.useEffect(() => {
    fetchBatchList();
  }, []);

  React.useEffect(() => {
    fetchData(page);
  }, [search, page]);

  React.useEffect(() => {
    fetchFacultyData();
  }, [fetchFacultyData]);

  return (
    <>
      <AdminHeader />
      <div className="top-div">
        <div className="verification-requests">
          <div className="title cv">
            <div className="title-left-div">
              <h2>
                Verification Requests:{' '}
                <span>
                  {
                    list?.filter((e) => {
                      return (
                        (facultyData?.branch?.find((e1) => e1 === e.branch) &&
                          facultyData?.batch?.find((e1) => e1 === e.batch) &&
                          facultyData?.program?.find((e1) => e1 === e.program)) ||
                        facultyData?.name === e.faculty
                      );
                    })?.length
                  }
                </span>
              </h2>
            </div>

            <div className="filter-item title-right-div">
              <h4>Filter by:</h4>

              <select
                id="program"
                value={search.program ? search.program : '0'}
                onChange={(e) =>
                  setSearch((e1) => {
                    return { ...e1, program: e.target.value };
                  })
                }
              >
                <option value="0" disabled>
                  Program
                </option>
                <option value="B.TECH.">B.Tech</option>
                <option value="M.TECH.">M.Tech</option>
                <option value="Ph.D.">PhD</option>
              </select>

              <select
                id="semesters"
                value={search.semester ? search.semester : '0'}
                onChange={(e) =>
                  setSearch((e1) => {
                    return { ...e1, semester: e.target.value };
                  })
                }
              >
                <option value="0" disabled>
                  Semester
                </option>
                <option value="1">I</option>
                <option value="2">II</option>
                <option value="3">III</option>
                <option value="4">IV</option>
                <option value="5">V</option>
                <option value="6">VI</option>
                <option value="7">VII</option>
                <option value="8">VIII</option>
              </select>

              <select
                name="batch"
                id="batch"
                value={search.batch ? search.batch : 'all'}
                onChange={(e) =>
                  setSearch((e1) => {
                    return { ...e1, batch: e.target.value };
                  })
                }
              >
                <option value="">Select Batch</option>
                {search.program ? (
                  batchList[search.program]?.map((batch) => (
                    <option key={batch} value={batch}>
                      {batch}
                    </option>
                  ))
                ) : (
                  <option value="null">Select Program First</option>
                )}
              </select>
            </div>
          </div>
          {list
            ?.filter((e) => {
              return (
                (facultyData?.branch?.find((e1) => e1 === e.branch) &&
                  facultyData?.batch?.find((e1) => e1 === e.batch) &&
                  facultyData?.program?.find((e1) => e1 === e.program)) ||
                facultyData?.name === e.faculty
              );
            })
            ?.map((item, index) => {
              return isLoading ? (
                <div key={index}>Loading...</div>
              ) : (
                <CourseRequest
                  key={index}
                  data={item}
                  onVerificationComplete={handleVerificationComplete}
                />
              );
            })}
          {!isLoading && list.length === 0 && (
            <div className="no-requests">No pending requests</div>
          )}

          {totalPages > 1 && (
            <div className="pagination">
              <button disabled={page === 1} onClick={() => setPage((p) => p - 1)}>
                {`<<`}
              </button>
              <span>
                {page} of {totalPages}
              </span>
              <button disabled={page === totalPages} onClick={() => setPage((p) => p + 1)}>
                {`>>`}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CourseVerification;
