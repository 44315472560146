import React, { useEffect, useState } from 'react';
import { AuthProvider } from '../auth/AuthContext';
import GeneralFilter1 from '../common/filters/GeneralFilter1';
import apis from '../apis';

import '../css/Component/MessManagement.css';
import Table from '../common/tables/Table';
import GenerateTimer from './GenerateTimer';
import HostelRequestElement from './HostelRequestElement';

const HostelAllotment = ({ fetchAllApplied, hostelData }) => {
  const { adminData } = AuthProvider();

  const [filterAttribute, setFilterAttribute] = useState({});
  const [allotmentData, setAllotmentData] = useState([]);
  const [selectedData, setSelectedData] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [hostel, setHostel] = React.useState();

  const getStudentOptedData = async () => {
    setLoading(true);
    const showOnlyOptedData = await hostelData.filter(
      (data) => data.opt === true && data.optTime !== null
    );
    console.log(showOnlyOptedData);
    setAllotmentData(showOnlyOptedData);
    setLoading(false);
  };

  React.useEffect(() => {
    if (hostelData) {
      getStudentOptedData();
    }
  }, [hostelData]);

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (allotmentData.length > 0) {
      let tempData = allotmentData;

      if (filterAttribute['gender']) {
        tempData = tempData.filter((data) => data['gender'] === filterAttribute['gender']);
      }
      if (filterAttribute['session']) {
        tempData = tempData.filter((data) => data['session'] === filterAttribute['session']);
      }
      if (filterAttribute['course']) {
        tempData = tempData.filter((data) => data['program'] === filterAttribute['course']);
      }
      if (filterAttribute['batch']) {
        tempData = tempData.filter((data) => data['batch'] === filterAttribute['batch']);
      }
      if (filterAttribute['semester']) {
        tempData = tempData.filter((data) => data['semester'] === filterAttribute['semester']);
      }
      if (filterAttribute['hostel']) {
        tempData = tempData.filter((data) => data['hostel'] === filterAttribute['hostel']);
      }
      if (filterAttribute['hostelStatus']) {
        tempData = tempData.filter((data) => data['status'] === filterAttribute['hostelStatus']);
      }

      setFilteredData(tempData);
    }
  }, [filterAttribute, allotmentData]);

  const requiredFilters = [
    'gender',
    'session',
    'course',
    'batch',
    'semester',
    'hostel',
    'hostelStatus',
  ];

  const columns = [
    {
      Header: (
        <input
          className="checkbox"
          type="checkbox"
          checked={filteredData.length > 0 && filteredData.every((data) => data.checkbox)}
          onChange={(e) => {
            const newStudentDatas = filteredData.map((data) => {
              return {
                ...data,
                checkbox: e.target.checked,
              };
            });
            setFilteredData(newStudentDatas);
            setSelectedData(e.target.checked ? newStudentDatas.map((data) => data.id) : []);
          }}
        />
      ),
      accessor: 'checkbox',
      style: { width: '50px' },
      showContextMenu: false,
    },
    {
      Header: 'S.No.',
      accessor: 'sno',
      style: { width: '50px', minWidth: '50px', cursor: 'pointer' },
    },
    {
      Header: 'Roll No.',
      accessor: 'roll',
    },
    {
      Header: 'Name',
      accessor: 'name',
      style: { width: '135px', minWidth: '135px', cursor: 'pointer' },
    },
    {
      Header: 'Batch',
      accessor: 'batch',
      style: { width: '65px', minWidth: '65px', cursor: 'pointer' },
    },
    {
      Header: 'Semester',
      accessor: 'semester',
      style: {
        width: '55px',
        minWidth: '55px',
        cursor: 'pointer',
        paddingLeft: '5px',
        paddingRight: '5px',
      },
    },
    {
      Header: 'Hostel',
      accessor: 'hostel',
      style: {
        width: '50px',
        minWidth: '50px',
        cursor: 'pointer',
        paddingLeft: '5px',
        paddingRight: '5px',
      },
    },
    {
      Header: 'Previous Hostel',
      accessor: 'previousHostel',
      style: {
        width: '50px',
        minWidth: '50px',
        cursor: 'pointer',
        paddingLeft: '5px',
        paddingRight: '5px',
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      style: { width: '70px', minWidth: '70px', cursor: 'pointer' },
      showContextMenu: true,
    },
    {
      Header: 'Opt Time',
      accessor: 'optTime',
      style: { width: '100px', minWidth: '100px', cursor: 'pointer' },
    },
    {
      Header: 'Allot Room',
      accessor: 'room',
      style: { width: '100%' },
      showContextMenu: false,
    },
  ];

  const rowData =
    filteredData &&
    filteredData.map((data, index) => {
      return {
        checkbox: (
          <input
            className="checkbox"
            type="checkbox"
            checked={data.checkbox}
            onChange={(e) => {
              const newStudentDatas = filteredData.map((studData) => {
                if (studData.id === data.id) {
                  return {
                    ...studData,
                    checkbox: e.target.checked,
                  };
                }
                return studData;
              });
              setFilteredData(newStudentDatas);
              setSelectedData(
                e.target.checked
                  ? [...selectedData, data.id]
                  : selectedData.filter((id) => id !== data.id)
              );
            }}
          />
        ),
        sno: index + 1,
        roll: data.roll,
        name: data.name,
        batch: data.batch,
        semester: data.semester,
        hostel: data.hostel,
        previousHostel: data.previousHostel,
        status: data.status,
        optTime: data.optTime
          ? new Date(data.optTime).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })
          : data.status === 'Alloted'
          ? 'N.A.'
          : 'Not Opted yet',
        room: (
          <div className="roomCont">
            <HostelRequestElement key={data.id} id={data.id} room={data.room} fetchAllApplied={fetchAllApplied} />
          </div>
        ),
      };
    });

  const approveSelected = async () => {
    await apis
      .approveHostelApplication({ selected: selectedData })
      .then((res) => {
        alert('Applications Approved');
        fetchAllApplied();
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const rejectSelected = async () => {
    await apis
      .rejectHostelApplication({ selected: selectedData })
      .then((res) => {
        alert('Applications Rejected');
        fetchAllApplied();
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const allotHostel = async () => {
    await apis
      .allotHostel({ selected: selectedData, hostel })
      .then((res) => {
        alert('Hostel Alloted');
        fetchAllApplied();
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  return (
    <div>
      <GeneralFilter1
        filterHeading="Filter students using"
        filters={requiredFilters}
        setFilterAttribute={setFilterAttribute}
        filterAttribute={filterAttribute}
      />
      <div className="secondMessHalf">
        <div className="messSwapTable">
          <Table columns={columns} data={rowData} loading={loading} className="" />
        </div>
        <div className="schedularPart">
          <div className="hostelTimerSection">
            <GenerateTimer schedularType={'HOSTEL'} />
          </div>
          <div className="approval-btns">
            <h3>Approve or Reject Selected</h3>
            <div className="remarks">
              <label htmlFor="remarks">Remarks:</label>
              <textarea id="remarks" rows="4" cols="50"></textarea>
            </div>
            <div className="approve-reject-btns">
              <button className="approve-btn primary" onClick={approveSelected}>
                Approve
              </button>
              <button className="reject-btn secondary" onClick={rejectSelected}>
                Reject
              </button>
            </div>
          </div>
          <div className="allotment-btns">
            <h3>Allot Hostel to Selected</h3>
            <form>
              <div className="formgroup">
                <label htmlFor="hostel" className="required">
                  Select Hostel:
                </label>
                <select
                  id="hostel"
                  required
                  value={hostel}
                  onChange={(e) => setHostel(e.target.value)}
                  style={{ maxWidth: '180px' }}
                >
                  <option value="">Select Hostel</option>
                  <option value="H1">Hostel 1</option>
                  <option value="H9">Hostel 9</option>
                  <option value="H7">Hostel 7</option>
                  <option value="GH">Girls Hostel (BCE)</option>
                  <option value="NBH">New Boys Hostel</option>
                  <option value="KBH">Kautilya Boys Hostel</option>
                  <option value="KGH">Kadambini Girls Hostel</option>
                </select>
              </div>
              <button
                className="allot-btn primary"
                onClick={allotHostel}
                style={{ marginBottom: '10px' }}
              >
                Allot to Selected
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HostelAllotment;
