import * as React from 'react';
import { useState } from 'react';
import '../css/Component/PaySection.css';
import { AuthProvider } from '../auth/AuthContext';
import apis from '../apis';
import GatewayPopUp from './GatewayPopUp';
import api from '../newRegistrationApis';

const PaySectionNew = () => {
  const { newUserData } = AuthProvider();
  const [year, setYear] = React.useState('1');
  const [semester, setSemester] = React.useState('1');
  const [feeStructure, setFeeStructure] = React.useState({});
  const [totalFee, setTotalFee] = React.useState(0);
  const [otherTotalFee, setOtherTotalFee] = React.useState(0);
  const [paymentData, setPaymentData] = React.useState();
  const [hostelStatus, setHostelStatus] = React.useState();
  const [receiptUpload, setReceiptUpload] = React.useState({
    file: null,
    amount: '',
    refNo: '',
    paymentDate: '',
  });
  const [hostelApplication, setHostelApplication] = React.useState({
    optHostel: false,
    prefHostel: '',
  });
  const [refetchHostelData, setRefetchHostelData] = React.useState(false);

  React.useEffect(() => {
    if (year === '1') setSemester('1');
    else if (year === '2') setSemester('3');
    else if (year === '3') setSemester('5');
    else setSemester('7');
  }, [year]);

  const fetchStudentFeeData = React.useCallback(async () => {
    newUserData.applicationid &&
      apis
        .specificStudent({
          applicationid: newUserData.applicationid,
          semester: newUserData.semester,
        })
        .then((result) => {
          setPaymentData(result[0]);
        })
        .catch((err) => {
          alert(err.message);
        });
  }, [newUserData]);

  const fetchHostelData = React.useCallback(async () => {
    newUserData.applicationid &&
      api
        .getNewStudHostel(
          newUserData?.applicationid,
          newUserData?.batch,
          newUserData?.program,
          newUserData?.semester
        )
        .then((result) => {
          setHostelStatus(result);
        })
        .catch((err) => {
          alert(err.message);
        });
    // eslint-disable-next-line
  }, [newUserData, refetchHostelData]);

  React.useEffect(() => {
    async function defaultFetch() {
      newUserData.year &&
        (await apis
          .feeStructure(
            newUserData.year,
            newUserData.program,
            newUserData.semester,
            newUserData.batch,
            newUserData.type
          )
          .then((result) => {
            setFeeStructure(result);
            let total =
              Number(result.tuitionfee) +
              Number(result.otherfee) +
              Number(result.admissionfee) +
              Number(result.cautionmoney);
            setTotalFee(total);
            // console.log(result);
            let otherTotal = Number(
              newUserData.gender === 'Male' ? result?.kbhfee : result?.kghfee
            );
            setOtherTotalFee(otherTotal);
          })
          .catch((err) => {
            console.log('error from error 1');
            alert(err.message);
          }));
    }
    defaultFetch();
    fetchStudentFeeData();
    fetchHostelData();
  }, [newUserData, fetchStudentFeeData, fetchHostelData]);

  async function fetchData() {
    await apis
      .feeStructure(year, newUserData.program, semester, newUserData.batch, newUserData.type)
      .then((data) => {
        setFeeStructure(data);
        let total =
          Number(data.tuitionfee) +
          Number(data.otherfee) +
          Number(data.admissionfee) +
          Number(data.cautionmoney);
        setTotalFee(total);
        console.log(data);
        let otherTotal = Number(newUserData.gender === 'Male' ? data?.nbhfee : data?.ghfee);
        setOtherTotalFee(otherTotal);
      })
      .catch((err) => {
        console.log('error from error 2');
        alert(err);
      });
  }

  const applyHostel = async () => {
    await api
      .applyNewStudHostel(
        newUserData.applicationid,
        newUserData.batch,
        newUserData.program,
        newUserData.semester,
        hostelApplication?.prefHostel
      )
      .then((result) => {
        setRefetchHostelData(!refetchHostelData);
        alert('Hostel application submitted successfully');
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const submitReceipt = async () => {
    if (receiptUpload.file === null) {
      alert('Please select a file');
      return;
    }
    if (receiptUpload.amount === '') {
      alert('Please enter amount');
      return;
    }
    if (receiptUpload.refNo === '') {
      alert('Please enter reference number');
      return;
    }
    if (receiptUpload.paymentDate === '') {
      alert('Please enter payment date');
      return;
    }
    let formData = new FormData();
    formData.append('fee', receiptUpload.file);
    formData.append('applicationid', newUserData.applicationid);
    formData.append('semester', semester);
    formData.append('amount', receiptUpload.amount);
    formData.append('refno', receiptUpload.refNo);
    formData.append('type', 'Offline');
    formData.append('date', receiptUpload.paymentDate);
    await apis
      .addReceipt(formData)
      .then((result) => {
        alert('Receipt uploaded successfully');
        window.location.reload();
      })
      .catch((err) => {
        alert(err);
      });
  };

  const [openGateway, setOpenGateway] = useState({
    open: false,
    type: '',
  });

  return (
    <>
      <GatewayPopUp
        open={openGateway.open}
        types={openGateway.type}
        userData={newUserData}
        onClose={() => setOpenGateway(false)}
      />
      <div className="paysection">
        <div className="paysection-div">
          <div className="paysection-title">
            <h2>Fee Payment</h2>
          </div>
          <div className="content-div">
            <div className="filter-items">
              <div className="filter-item">
                <h4 htmlFor="year">Select Year:</h4>
                <select
                  name="year"
                  id="year"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                >
                  <option value="1">1st Year</option>
                  <option value="2">2nd Year</option>
                  <option value="3">3rd Year</option>
                  <option value="4">4th Year</option>
                </select>
              </div>
              <div className="filter-item">
                <h4 htmlFor="semester">Select Semester:</h4>

                <select
                  name="semester"
                  id="semester"
                  value={semester}
                  onChange={(e) => setSemester(e.target.value)}
                >
                  {year === '1' && <option value="1">1st</option>}
                  {year === '1' && <option value="2">2nd</option>}
                  {year === '2' && <option value="3">3rd</option>}
                  {year === '2' && <option value="4">4th</option>}
                  {year === '3' && <option value="5">5th</option>}
                  {year === '3' && <option value="6">6th</option>}
                  {year === '4' && <option value="7">7th</option>}
                  {year === '4' && <option value="8">8th</option>}
                </select>
              </div>
              <div className="filter-item btn-div">
                <button id="search" className="new_btn" onClick={() => fetchData()}>
                  <span>Search</span>
                  <img src="/assets/search-icon.svg" alt="search-icon" />
                </button>
              </div>
            </div>
            <div className="result-items">
              <div className="fee-table">
                <div className="types">
                  <div className="type">
                    <div className="type-name">
                      <h4>Reccurning Fee</h4>
                    </div>
                    <div className="type-contents">
                      <div className="type-content">
                        <div className="content-count">
                          <h4>1.</h4>
                        </div>
                        <div className="content-name">
                          <h4>Tution Fee</h4>
                        </div>
                        <div className="content-price">
                          <h4>
                            <span>₹</span> {feeStructure.tuitionfee}
                          </h4>
                        </div>
                      </div>
                      <div className="type-content">
                        <div className="content-count">
                          <h4>2.</h4>
                        </div>
                        <div className="content-name">
                          <h4>Other Institute Fee</h4>
                        </div>
                        <div className="content-price">
                          <h4>
                            <span>₹</span> {feeStructure.otherfee}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="type">
                    <div className="type-name">
                      <h4>One Time Fee (Payable at the time of Admission only)</h4>
                    </div>
                    <div className="type-contents">
                      <div className="type-content">
                        <div className="content-count">
                          <h4>3.</h4>
                        </div>
                        <div className="content-name">
                          <h4>Admission Related Fee (Non-refundable)</h4>
                        </div>
                        <div className="content-price">
                          <h4>
                            <span>₹</span> {feeStructure.admissionfee}
                          </h4>
                        </div>
                      </div>
                      <div className="type-content">
                        <div className="content-count">
                          <h4>4.</h4>
                        </div>
                        <div className="content-name">
                          <h4>Caution Money</h4>
                        </div>
                        <div className="content-price">
                          <h4>
                            <span>₹</span> {feeStructure.cautionmoney}
                          </h4>
                        </div>
                      </div>
                      <div className="type-content">
                        <div className="content-count">
                          <h4>5.</h4>
                        </div>
                        <div className="content-name">
                          <h4>Fine</h4>
                        </div>
                        <div className="content-price">
                          <h4>
                            <span>₹</span> {0}
                          </h4>
                        </div>
                      </div>
                      <div className="type-content">
                        <div className="content-count">
                          <h4>6.</h4>
                        </div>
                        <div className="content-name">
                          <h4>Amount Paid (JOSAA / CSAB / CCMT)</h4>
                        </div>
                        <div className="content-price">
                          <h4>
                            <span>₹</span> -{paymentData?.alreadyPaid}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="type">
                    <div className="type-name total-title">
                      <h4>Total Institute Fee</h4>
                    </div>
                    <div className="type-contents type-total">
                      <div className="type-content">
                        <div className="content-price total-value">
                          <h4>
                            <span>₹</span>
                            {Number(totalFee) - Number(paymentData?.alreadyPaid)}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="payment-button">
                <div className="payment-title">
                  <h2>
                    Paid: <span>{`${Number(paymentData?.currentSemesterPaid?.institute)}`} </span>
                  </h2>
                  <h2>
                    Payable:{' '}
                    <span>{`${
                      totalFee - Number(paymentData?.currentSemesterPaid?.institute)
                    }`}</span>
                  </h2>
                </div>
                <button
                  id="pay-now"
                  className="pay_btn"
                  onClick={() =>
                    setOpenGateway({
                      open: true,
                      type: 'institute',
                    })
                  }
                >
                  Proceed to Pay<i className="fa-solid fa-circle-right"></i>
                </button>
              </div>
            </div>
            {paymentData?.course === 'B.TECH.' && (
              <div className="upload-items">
                <div className="upload-title">
                  <h4>
                    Hostel Application:{' '}
                    <span> To opt for hostel accommodation at IIIT Bhagalpur</span>
                  </h4>
                </div>

                <div className="hostelApplication">
                  {hostelStatus && hostelStatus?.status !== 'Not Applied' ? (
                    <div className="upload-title">
                      <h4 style={{ marginTop: '0px', color: '#5a6dff' }}>
                        {hostelStatus?.pre ? (
                          <span>
                            You have successfully completed hostel application process, below is
                            your status
                          </span>
                        ) : (
                          <span>
                            You have already applied for hostel accommodation, pay the required fee
                            to accet the hostel.
                          </span>
                        )}
                      </h4>
                    </div>
                  ) : (
                    <div className="upload-buttons">
                      <label htmlFor="OptHostel">Want Hostel Accommodation?:</label>
                      <select
                        id="OptHostel"
                        required
                        value={hostelApplication?.optHostel}
                        onChange={(e) => {
                          setHostelApplication((r) => {
                            return {
                              ...r,
                              optHostel: e.target.value === 'true' ? true : false,
                            };
                          });
                        }}
                      >
                        <option value="">Select the option</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                      <label htmlFor="prefHostel">Preferred Hostel:</label>
                      <select
                        id="prefHostel"
                        required
                        value={hostelApplication?.prefHostel}
                        disabled={!hostelApplication?.optHostel}
                        onChange={(e) => {
                          setHostelApplication((r) => {
                            return { ...r, prefHostel: e.target.value };
                          });
                        }}
                      >
                        <option value="">Select Hostel</option>
                        {newUserData.gender === 'Male' ? (
                          <>
                            <option value="KBH">Kautilya boys Hostel</option>
                          </>
                        ) : (
                          <>
                            <option value="KGH">Kadambini Girls Hostel</option>
                          </>
                        )}
                      </select>
                      <button
                        id="submit"
                        type="submit"
                        disabled={hostelStatus?.status === 'Pending' && true}
                        onClick={applyHostel}
                      >
                        Apply now
                      </button>
                    </div>
                  )}
                  <div id="hostel-status" className="request-details">
                    <h4>
                      Status: <span>{hostelStatus?.status}</span>
                    </h4>
                    <h4>
                      Alloted Hostel: <span>{hostelStatus?.hostel}</span>
                    </h4>
                    <h4>
                      Alloted Room: <span>{hostelStatus?.room ?? 'Will be alloted soon...'}</span>
                    </h4>
                    {/* <h4>
                    Can pay Hostel & Mess fee:{" "}
                    <span>{hostelStatus?.canPay ? "Yes" : "No"}</span>
                  </h4> */}
                  </div>
                </div>
              </div>
            )}
            {hostelStatus?.canPay && (
              <>
                <div className="result-items">
                  <div className="fee-table">
                    <div className="types">
                      <div className="type">
                        <div className="type-name">
                          <h4>Hostel Fee</h4>
                        </div>
                        <div className="type-contents">
                          <div className="type-content">
                            <div className="content-count">
                              <h4>1.</h4>
                            </div>
                            <div className="content-name">
                              <h4>Hostel Fee</h4>
                            </div>
                            <div className="content-price">
                              <h4>
                                <span>₹</span>{' '}
                                {newUserData.gender === 'Male'
                                  ? feeStructure?.kbhfee
                                  : feeStructure?.kghfee}
                              </h4>
                            </div>
                          </div>
                          <div className="type-content">
                            <div className="content-count">
                              <h4>2.</h4>
                            </div>
                            <div className="content-name">
                              <h4>Fine </h4>
                            </div>
                            <div className="content-price">
                              <h4>
                                <span>₹</span> {0}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="type">
                        <div className="type-name total-title">
                          <h4>Total (Hostel)</h4>
                        </div>
                        <div className="type-contents type-total">
                          <div className="type-content">
                            <div className="content-price total-value">
                              <h4>
                                <span>₹</span>
                                {otherTotalFee}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="payment-button">
                    <div className="payment-title">
                      <h2>
                        Paid: <span>{`${paymentData?.currentSemesterPaid?.hostel}`} </span>
                      </h2>
                      <h2>
                        Payable:{' '}
                        <span>{`${
                          (newUserData.gender === 'Male'
                            ? Number(feeStructure?.kbhfee)
                            : Number(feeStructure?.kghfee)) -
                          Number(paymentData?.currentSemesterPaid?.hostel)
                        }`}</span>
                      </h2>
                    </div>
                    <button
                      id="pay-now"
                      className="pay_btn"
                      onClick={() =>
                        setOpenGateway({
                          open: true,
                          type: 'hostel',
                        })
                      }
                    >
                      Proceed to Pay<i className="fa-solid fa-circle-right"></i>
                    </button>
                  </div>
                </div>
                <div className="result-items">
                  <div className="fee-table">
                    <div className="types">
                      <div className="type">
                        <div className="type-name">
                          <h4>Mess Fee</h4>
                        </div>
                        <div className="type-contents">
                          <div className="type-content">
                            <div className="content-count">
                              <h4>1.</h4>
                            </div>
                            <div className="content-name">
                              <h4>Mess Fee</h4>
                            </div>
                            <div className="content-price">
                              <h4>
                                <span>₹</span> {feeStructure.messfee}
                              </h4>
                            </div>
                          </div>

                          <div className="type-content">
                            <div className="content-count">
                              <h4>2.</h4>
                            </div>
                            <div className="content-name">
                              <h4>Fine</h4>
                            </div>
                            <div className="content-price">
                              <h4>
                                <span>₹</span> {0}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="type">
                        <div className="type-name total-title">
                          <h4>Total (Mess + Fine)</h4>
                        </div>
                        <div className="type-contents type-total">
                          <div className="type-content">
                            <div className="content-price total-value">
                              <h4>
                                <span>₹</span>
                                {feeStructure.messfee}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="payment-button">
                    <div className="payment-title">
                      <h2>
                        Paid: <span>{`${paymentData?.currentSemesterPaid?.mess}`} </span>
                      </h2>
                      <h2>
                        Payable:{' '}
                        <span>{`${
                          Number(feeStructure.messfee) -
                          Number(paymentData?.currentSemesterPaid?.mess)
                        }`}</span>
                      </h2>
                    </div>
                    <button
                      id="pay-now"
                      className="pay_btn"
                      onClick={() =>
                        setOpenGateway({
                          open: true,
                          type: 'mess',
                        })
                      }
                    >
                      Proceed to Pay<i className="fa-solid fa-circle-right"></i>
                    </button>
                  </div>
                </div>
              </>
            )}
            <div className="upload-items">
              <div className="upload-title">
                <h4>
                  Upload Reciept: <span>If you have already paid using Loan/Scholarship</span>
                </h4>
              </div>
              <div className="upload-buttons">
                <input
                  type="file"
                  value={receiptUpload.file?.filename}
                  accept="application/pdf,application/msword,"
                  onChange={(e) => {
                    setReceiptUpload((r) => {
                      return { ...r, file: e.target.files[0] };
                    });
                  }}
                />
                <input
                  className="text-input"
                  type="text"
                  placeholder="Amount in INR"
                  value={receiptUpload.amount}
                  onChange={(e) => {
                    setReceiptUpload((r) => {
                      return { ...r, amount: e.target.value };
                    });
                  }}
                />
                <input
                  className="text-input"
                  type="text"
                  placeholder="Enter Transaction ID"
                  value={receiptUpload.refNo}
                  onChange={(e) => {
                    setReceiptUpload((r) => {
                      return { ...r, refNo: e.target.value };
                    });
                  }}
                />
                <input
                  className="text-input"
                  type="date"
                  value={receiptUpload.paymentDate}
                  onChange={(e) => {
                    setReceiptUpload((r) => {
                      return { ...r, paymentDate: e.target.value };
                    });
                  }}
                />
                <button id="submit" type="submit" onClick={() => submitReceipt()}>
                  Submit now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaySectionNew;
