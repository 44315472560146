import axios from 'axios';
import jwtVerifyConfig from './jwtVerifyConfig';

// axios.defaults.baseURL = 'http://localhost:2000';
class JwtVerify {
  interceptorsSetup() {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        return new Promise((resolve, reject) => {
          if (error.response.status === 401) {
            localStorage.removeItem('jwt_access_token');
            localStorage.removeItem('admin_access_token');
            localStorage.removeItem('newUserToken');
            alert(error.response.data.message);
            window.location.href = '/';
            resolve(error.response);
          }
          reject(error);
        });
      }
    );
  }

  loginWithGoogle() {
    return new Promise((resolve, reject) => {
      try {
        const query = new URLSearchParams(window.location.search);
        const token = query.get('token');
        const error = query.get('error');
        if (token) {
          this.setSession(token);
          resolve(true);
        } else {
          this.setSession(null);
          throw new Error(error);
        }
      } catch (error) {
        reject(error.response.data);
      }
    });
  }

  login = (email, password) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: jwtVerifyConfig.login,
        method: 'POST',
        data: {
          email,
          password,
        },
      })
        .then((result) => {
          // console.log(result.data);
          if (result.data.status === 0) {
            this.setSession(result.data.token, 'student');
            resolve(result.data.user);
          }
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  };

  adminLogin = (email, password) => {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: jwtVerifyConfig.adminLogin,
        method: 'POST',
        data: {
          email,
          password,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((result) => {
          this.setSession(result.data.token, 'admin');
          resolve(result.data.user);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  };

  getUserData() {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: jwtVerifyConfig.userData,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: this.getAccessToken('student'),
        },
      })
        .then((result) => {
          if (result.data.status === 0) {
            resolve(result.data.data);
          }
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  }

  getAdminData() {
    return new Promise(async (resolve, reject) => {
      await axios({
        url: jwtVerifyConfig.adminData,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: this.getAccessToken('admin'),
        },
      })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  }

  setSession(token, user) {
    if (user === 'student') {
      if (token) {
        localStorage.setItem('jwt_access_token', `Bearer ${token}`);
        // axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      } else {
        localStorage.removeItem('jwt_access_token');
        // delete axios.defaults.headers.common["Authorization"];
      }
    } else if (user === 'admin') {
      if (token) {
        localStorage.setItem('admin_access_token', `Bearer ${token}`);
        // axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      } else {
        localStorage.removeItem('jwt_admin_access_token');
        // delete axios.defaults.headers.common["Authorization"];
      }
    }
  }

  getAccessToken(user) {
    if (user === 'student') {
      return localStorage.getItem('jwt_access_token');
    } else if (user === 'admin') {
      return localStorage.getItem('admin_access_token');
    } else if (user === 'newUser') {
      return localStorage.getItem('newUserToken');
    }
  }

  logoutUser(user) {
    if (user === 'student') {
      localStorage.removeItem('jwt_access_token');
    } else if (user === 'admin') {
      localStorage.removeItem('admin_access_token');
    }
  }
}

const instance = new JwtVerify();

export default instance;
