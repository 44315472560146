import React, { useEffect, useState } from 'react';
import apis from '../apis';
import AdminHeader from '../components/AdminHeader';
import '../css/Page/ReviewRating.css';
import { Link } from 'react-router-dom';

const AdminFeedbackPage = () => {
  const [filterAttribute, setFilterAttribute] = React.useState({
    session: '',
    program: '',
    branch: '',
    season: '',
    semester: '',
  });
  const [ratingDetails, setRatingDetails] = React.useState({});
  const [totalStudentCount, setTotalStudentCount] = useState(0);
  const [totalRatingCount, setTotalRatingCount] = useState(0);

  const generateSessionList = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const sessionList = [];
    for (let i = currentYear; i >= 2017; i--) {
      if (currentMonth < 6 && i === currentYear) continue;
      let nextYear = (i + 1).toString().slice(-2);
      sessionList.push(`${i}-${nextYear}`);
    }
    return sessionList;
  };

  useEffect(() => {
    const currentMonth = new Date().getMonth();
    if (currentMonth < 6) {
      setFilterAttribute({
        ...filterAttribute,
        session: generateSessionList()[0],
      });
    } else {
      setFilterAttribute({
        ...filterAttribute,
        session: generateSessionList()[1],
      });
    }
  }, []);

  const getStudentCount = async () => {
    try {
      const queryParameters = {
        course: filterAttribute?.program,
        branch: filterAttribute?.branch,
        semester: filterAttribute?.semester,
      };

      await Promise.resolve(apis.getCurrentStudentLength(queryParameters)).then((data) => {
        // console.log("Student count:", data);
        setTotalStudentCount(data);
      });
    } catch (err) {
      console.error('Error fetching student count:', err);
    }
  };

  const getRatingCount = async () => {
    try {
      const queryParameters = {
        branch: filterAttribute?.branch,
        session: filterAttribute?.session,
        semester: filterAttribute?.semester,
        course: filterAttribute?.program,
      };

      await Promise.resolve(apis.getTotalStudentReviewCount(queryParameters)).then((data) => {
        // console.log("Rating count:", data);
        setTotalRatingCount(data);
      });
    } catch (err) {
      console.error('Error fetching rating count:', err);
    }
  };

  useEffect(() => {
    getStudentCount();
    getRatingCount();
  }, [filterAttribute]);

  const fetchRatings = async () => {
    try {
      const queryParams = {
        session: filterAttribute.session,
        season: filterAttribute.season,
      };
      await Promise.resolve(apis.getRatingDetails(queryParams)).then((data) => {
        // console.log("Ratings:", data);
        setRatingDetails(data);
      });
    } catch (err) {
      console.error('Error fetching ratings:', err);
    }
  };

  useEffect(() => {
    fetchRatings();
  }, [filterAttribute]);

  const [answerIndex, setAnswerIndex] = useState(-1);

  const handleToggleAnswer = (index) => {
    if (answerIndex === index) {
      setAnswerIndex(-1);
    } else {
      setAnswerIndex(index);
    }
  };

  return (
    <div>
      <AdminHeader />
      <h2 className="ReviewHeading">Faculty's Review Status</h2>
      <div className="filterContainer7">
        <div className="filterSection7">
          <div className="filterItem">
            <p>Choose Session:</p>
            <select
              onChange={(e) => {
                setFilterAttribute((e1) => {
                  return { ...e1, session: e.target.value };
                });
              }}
            >
              <option disabled>Session</option>
              {generateSessionList().map((e, i) => {
                return (
                  <option key={i} value={e}>
                    {e}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="filterItem">
            <p>Choose Season:</p>
            <select
              onChange={(e) => {
                setFilterAttribute((e1) => {
                  return { ...e1, season: e.target.value };
                });
              }}
            >
              <option value={''}>Season</option>
              <option value="Spring">Spring</option>
              <option value="Autumn">Autumn</option>
            </select>
          </div>
        </div>
      </div>
      <div className="SummaryCont">
        <div className="data">
          <div className="section">
            <div className="title">Total Students:</div>
            <div className="value">{totalStudentCount}</div>
          </div>
          <div className="section">
            <div className="title">Review Submitted:</div>
            <div className="value">{totalRatingCount}</div>
          </div>
          <div className="section">
            <div className="title">Total Faculties:</div>
            <div className="value">{ratingDetails?.metadata?.totalFacultyCount}</div>
          </div>
          <div className="section">
            <div className="title">Best Overall Rating:</div>
            <div className="value">
              {Number(ratingDetails?.metadata?.bestOverallRating) === Number.MIN_SAFE_INTEGER
                ? 0
                : ratingDetails?.metadata?.bestOverallRating}
            </div>
          </div>
          <div className="section">
            <div className="title">Worst Overall Rating:</div>
            <div className="value">
              {Number(ratingDetails?.metadata?.worstOverallRating) === Number.MAX_SAFE_INTEGER
                ? 0
                : ratingDetails?.metadata?.worstOverallRating}
            </div>
          </div>
        </div>
      </div>
      <div className="facultyCont">
        <div className="tableCont">
          <div className="topHeader">
            <div className="facultyName">Faculty Name</div>
            <div className="overallRating">Overall Score</div>
            <div className="position">Position</div>
          </div>
          {Object.keys(ratingDetails).map((key, index) => {
            if (key === 'metadata') return;
            const faculty = ratingDetails[key];
            return (
              <div key={key} className="facultyFeedback2">
                <div onClick={() => handleToggleAnswer(index)} className="header">
                  <div
                    className={`${
                      answerIndex === index ? 'makeItBold facultyName' : 'facultyName'
                    }`}
                  >
                    {faculty.facultyName}
                  </div>
                  <div className="overallRating">{faculty.overall}</div>
                  <div className="position">{index}</div>
                </div>
                {answerIndex === index && (
                  <div className="coursesSec">
                    <table className="coursesSecTable">
                      <thead className="coursesSecHead">
                        <tr>
                          <th className="">Course Name</th>
                          <th className="" style={{ width: '60px', textAlign: 'center' }}>
                            Code
                          </th>
                          <th className="" style={{ padding: '0px 8px', textAlign: 'center' }}>
                            Program
                          </th>
                          <th className="">Branch</th>
                          <th className="" style={{ padding: '0px 8px', textAlign: 'center' }}>
                            Semester
                          </th>
                          <th className="" style={{ padding: '0px 8px', textAlign: 'center' }}>
                            Score
                          </th>
                        </tr>
                      </thead>
                      <tbody className="coursesSecBody">
                        {faculty.courses.map((course, i) => {
                          return (
                            <tr key={course.courseId} style={{ cursor: 'pointer' }}>
                              <td style={{ padding: '8px 8px' }}>
                                <Link
                                  to={`/student/admin/feedback/${key}/${course.courseId}/${filterAttribute.session}/${course.details.course}/${course.details.semester}/${course.details.branch}`}
                                  target="_blank"
                                  style={{ textDecoration: 'none', color: 'black' }}
                                >
                                  {course.details.name}
                                </Link>
                              </td>
                              <td style={{ padding: '8px 8px', textAlign: 'center' }}>
                                {course.details.code}
                              </td>
                              <td style={{ padding: '8px 8px', textAlign: 'center' }}>
                                {course.details.course}
                              </td>
                              <td style={{ padding: '8px 8px', textAlign: 'center' }}>
                                {course.details.branch}
                              </td>
                              <td style={{ padding: '8px 8px', textAlign: 'center' }}>
                                {course.details.semester}
                              </td>
                              <td style={{ padding: '8px 8px', textAlign: 'center' }}>
                                {course.overall}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        {/* <div className="filterCont"></div> */}
      </div>
    </div>
  );
};

export default AdminFeedbackPage;
