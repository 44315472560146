import * as React from 'react';
import Header from '../components/Header';
import Profile from '../components/Profile';
import Highlights from '../components/Highlights';
import Actions from '../components/Actions';
import { AuthProvider } from '../auth/AuthContext';
import '../css/Page/StudentPage.css';
// import jwtVerify from "../auth/service";

const heroSection = {
  marginTop: '60px',
  marginBottom: '60px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
// const container = {
//   width: "90%",
//   display: "flex",
//   flexDirection: "row",
//   justifyContent: "space-between",
// };
const mainDiv = {
  width: 'calc(70% - 20px)',
  height: '120vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

function StudentPage() {
  const { userData } = AuthProvider();

  return (
    <>
      <Header />
      <div className="student-page" style={heroSection}>
        <div className="sp-container">
          {userData.id && <Profile />}
          <div className="student-dashboard-div" style={mainDiv}>
            <Highlights />
            <Actions />
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentPage;
