import React from 'react';

const ResultScoreElement = ({ data }) => {
  return (
    <>
      <div className="subject-scores-body">
        <div className="subject-scores-body-element">
          <h4>{data?.courseCode}</h4>
        </div>
        <div className="subject-scores-body-element">
          <h4>{data?.courseName}</h4>
        </div>
        <div className="subject-scores-body-element">
          <h4>{data?.c}</h4>
        </div>
        <div className="subject-scores-body-element">
          <h4>{data?.grade}</h4>
        </div>
        <div className="subject-scores-body-element">
          <h4>{data?.gradePoint}</h4>
        </div>
        <div className="subject-scores-body-element">
          <h4>{data?.type}</h4>
        </div>
      </div>
    </>
  );
};

export default ResultScoreElement;
