import React, { useEffect, useState } from 'react';
import RatingTableComponent from './RatingTableComponent';
import '../css/Component/RatingDetailTables.css';
import GraphComponents from './GraphComponents';

const R_A_Questions = [
  'The course was well planned and organised.',
  'The topics provided new knowledge.',
  'Reading materials were available.',
  'Classes were held as per time table.',
  'Work reqiurements and grading system were clear form the beginning.',
];

const R_B_Questions = [
  'The fundamental concepts were prescribed clearly in the lecture.',
  'Teacher voice is and audible.',
  'Teacher blackboard (or overhead)presentation was well organised and legible.',
  'Teacher uses class time well.',
  'Teacher stresses important points in the lecture and discussions.',
];

const R_C_Questions = [
  'Teacher responded adequately to the questions asked in the class.',
  'Teacher treats students with respect.',
  'Teacher is willing to meet and help students outside the class.',
  'Teacher uses class time well.',
];

const RatingDetailTables = ({ facultyRatingDetails, totalRatingCount }) => {
  const [R_A_Percent, setR_A_Percent] = useState();
  const [R_B_Percent, setR_B_Percent] = useState();
  const [R_C_Percent, setR_C_Percent] = useState();
  const [R_A_AverageCnt, setR_A_AverageCnt] = useState([]);
  const [R_B_AverageCnt, setR_B_AverageCnt] = useState([]);
  const [R_C_AverageCnt, setR_C_AverageCnt] = useState([]);
  const [R_A_AveragePercent, setR_A_AveragePercent] = useState([]);
  const [R_B_AveragePercent, setR_B_AveragePercent] = useState([]);
  const [R_C_AveragePercent, setR_C_AveragePercent] = useState([]);
  const [overAll_AverageCnt, setOverAll_AverageCnt] = useState([]);
  const [overAll_AveragePercent, setOverAll_AveragePercent] = useState([]);
  // const [totalRatingCount, setTotalRatingCount] = useState(0);
  const [eachRatingCount, setEachRatingCount] = useState([0, 0, 0, 0, 0]);

  const calculateEachRatingCount = async () => {
    let totalCnt = Array(5).fill(0);

    for (let i = 0; i < 5; i++) {
      for (let j = 0; j < 5; j++) {
        totalCnt[i] += Number(facultyRatingDetails.R_A[j][i]);
      }
    }
    for (let i = 0; i < 5; i++) {
      for (let j = 0; j < 5; j++) {
        totalCnt[i] += Number(facultyRatingDetails.R_B[j][i]);
      }
    }
    for (let i = 0; i < 5; i++) {
      for (let j = 0; j < 4; j++) {
        totalCnt[i] += Number(facultyRatingDetails.R_C[j][i]);
      }
    }

    totalCnt.reverse();

    setEachRatingCount(totalCnt);
  };

  const calculatePercentage = async () => {
    let R_A_Total = Array(5).fill(0);
    let R_B_Total = Array(5).fill(0);
    let R_C_Total = Array(4).fill(0);

    for (let i = 0; i < 5; i++) {
      for (let j = 0; j < 5; j++) {
        R_A_Total[i] += Number(facultyRatingDetails.R_A[i][j]);
        R_B_Total[i] += Number(facultyRatingDetails.R_B[i][j]);
      }
    }

    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < 5; j++) {
        R_C_Total[i] += Number(facultyRatingDetails.R_C[i][j]);
      }
    }

    let R_A_Per = [];
    let R_B_Per = [];
    let R_C_Per = [];

    for (let i = 0; i < 5; i++) {
      let tempA = [];
      let tempB = [];
      for (let j = 0; j < 5; j++) {
        tempA.push((Number(facultyRatingDetails.R_A[i][j]) / R_A_Total[i]) * 100);
        tempB.push((Number(facultyRatingDetails.R_B[i][j]) / R_B_Total[i]) * 100);
      }
      R_A_Per.push(tempA);
      R_B_Per.push(tempB);
    }

    for (let i = 0; i < 4; i++) {
      let tempC = [];
      for (let j = 0; j < 5; j++) {
        tempC.push((Number(facultyRatingDetails.R_C[i][j]) / R_C_Total[i]) * 100);
      }
      R_C_Per.push(tempC);
    }

    setR_A_Percent(R_A_Per);
    setR_B_Percent(R_B_Per);
    setR_C_Percent(R_C_Per);
  };

  useEffect(() => {
    if (facultyRatingDetails) {
      calculatePercentage();
      calculateEachRatingCount();
    }
  }, [facultyRatingDetails, setR_A_Percent, setR_B_Percent, setR_C_Percent, setEachRatingCount]);

  const calculateAverage = async () => {
    let R_A_AverageCnt = [0, 0, 0, 0, 0];
    let R_B_AverageCnt = [0, 0, 0, 0, 0];
    let R_C_AverageCnt = [0, 0, 0, 0, 0];
    let R_A_AveragePercent = [0, 0, 0, 0, 0];
    let R_B_AveragePercent = [0, 0, 0, 0, 0];
    let R_C_AveragePercent = [0, 0, 0, 0, 0];

    for (let i = 0; i < 5; i++) {
      for (let j = 0; j < 5; j++) {
        R_A_AverageCnt[i] += Number(facultyRatingDetails.R_A[j][i]);
        R_B_AverageCnt[i] += Number(facultyRatingDetails.R_B[j][i]);
        R_A_AveragePercent[i] += R_A_Percent[j][i];
        R_B_AveragePercent[i] += R_B_Percent[j][i];
      }
      R_A_AverageCnt[i] = R_A_AverageCnt[i] / 5;
      R_B_AverageCnt[i] = R_B_AverageCnt[i] / 5;
      R_A_AveragePercent[i] = R_A_AveragePercent[i] / 5;
      R_B_AveragePercent[i] = R_B_AveragePercent[i] / 5;
    }

    for (let i = 0; i < 5; i++) {
      for (let j = 0; j < 4; j++) {
        R_C_AverageCnt[i] += Number(facultyRatingDetails.R_C[j][i]);
        R_C_AveragePercent[i] += R_C_Percent[j][i];
      }
      R_C_AverageCnt[i] = R_C_AverageCnt[i] / 4;
      R_C_AveragePercent[i] = R_C_AveragePercent[i] / 4;
    }

    setR_A_AverageCnt(R_A_AverageCnt);
    setR_B_AverageCnt(R_B_AverageCnt);
    setR_C_AverageCnt(R_C_AverageCnt);
    setR_A_AveragePercent(R_A_AveragePercent);
    setR_B_AveragePercent(R_B_AveragePercent);
    setR_C_AveragePercent(R_C_AveragePercent);
  };

  useEffect(() => {
    if (
      facultyRatingDetails &&
      facultyRatingDetails.R_A &&
      facultyRatingDetails.R_B &&
      facultyRatingDetails.R_B &&
      R_A_Percent &&
      R_A_Percent.length > 0 &&
      R_B_Percent &&
      R_B_Percent.length > 0 &&
      R_C_Percent &&
      R_C_Percent.length > 0
    ) {
      calculateAverage();
    }
  }, [
    facultyRatingDetails,
    R_A_Percent && R_A_Percent.length > 0,
    R_B_Percent && R_B_Percent.length > 0,
    R_C_Percent && R_C_Percent.length > 0,
    setR_A_AverageCnt,
    setR_B_AverageCnt,
    setR_C_AverageCnt,
    setR_A_AveragePercent,
    setR_B_AveragePercent,
    setR_C_AveragePercent,
  ]);

  const calculateOverallAverage = async () => {
    let overAll_AverageCnt = [0, 0, 0, 0, 0];
    let overAll_AveragePercent = [0, 0, 0, 0, 0];

    for (let i = 0; i < 5; i++) {
      overAll_AverageCnt[i] =
        Math.round(((R_A_AverageCnt[i] + R_B_AverageCnt[i] + R_C_AverageCnt[i]) / 3) * 100) / 100;
      overAll_AveragePercent[i] =
        Math.round(
          ((R_A_AveragePercent[i] + R_B_AveragePercent[i] + R_C_AveragePercent[i]) / 3) * 100
        ) / 100;
    }

    setOverAll_AverageCnt(overAll_AverageCnt);
    setOverAll_AveragePercent(overAll_AveragePercent);
  };

  useEffect(() => {
    if (
      R_A_AverageCnt &&
      R_A_AverageCnt.length > 0 &&
      R_B_AverageCnt &&
      R_B_AverageCnt.length > 0 &&
      R_C_AverageCnt &&
      R_C_AverageCnt.length > 0
    ) {
      calculateOverallAverage();
    }
  }, [
    R_A_AverageCnt && R_A_AverageCnt.length > 0,
    R_B_AverageCnt && R_B_AverageCnt.length > 0,
    R_C_AverageCnt && R_C_AverageCnt.length > 0,
    setOverAll_AverageCnt,
    setOverAll_AveragePercent,
  ]);

  return (
    <>
      <GraphComponents
        R_A={
          facultyRatingDetails && facultyRatingDetails?.R_A.length > 0 && facultyRatingDetails?.R_A
        }
        R_B={
          facultyRatingDetails && facultyRatingDetails?.R_B.length > 0 && facultyRatingDetails?.R_B
        }
        R_C={
          facultyRatingDetails && facultyRatingDetails?.R_C.length > 0 && facultyRatingDetails?.R_C
        }
        R_A_Percent={R_A_Percent}
        R_B_Percent={R_B_Percent}
        R_C_Percent={R_C_Percent}
        R_A_AverageCnt={R_A_AverageCnt}
        R_B_AverageCnt={R_B_AverageCnt}
        R_C_AverageCnt={R_C_AverageCnt}
        eachRatingCount={eachRatingCount}
      />
      <div className="ratingDetailsCont">
        <div className="ratingSection">
          <div className="categoryTitle">{`(A) Course Organisation`}</div>
          <RatingTableComponent
            questionList={R_A_Questions}
            ratingCountMatrix={facultyRatingDetails?.R_A}
            ratingPercentageMatrix={R_A_Percent}
            averageCount={R_A_AverageCnt}
            averagePercent={R_A_AveragePercent}
          />
        </div>
        <div className="ratingSection">
          <div className="categoryTitle">{`(B) Presentation`}</div>
          <RatingTableComponent
            questionList={R_B_Questions}
            ratingCountMatrix={facultyRatingDetails?.R_B}
            ratingPercentageMatrix={R_B_Percent}
            averageCount={R_B_AverageCnt}
            averagePercent={R_B_AveragePercent}
          />
        </div>
        <div className="ratingSection">
          <div className="categoryTitle">{`(C) Interaction and Report`}</div>
          <RatingTableComponent
            questionList={R_C_Questions}
            ratingCountMatrix={facultyRatingDetails?.R_C}
            ratingPercentageMatrix={R_C_Percent}
            averageCount={R_C_AverageCnt}
            averagePercent={R_C_AveragePercent}
          />
        </div>
        <div className="ratingSection overallsection">
          <table className="ratingDetailTable">
            <tbody className="ratingDetailBody">
              <tr>
                <td className="colHead" style={{ width: '40%', maxWidth: '50%' }}>
                  Overall Average
                </td>
                <td>
                  {overAll_AverageCnt[4]} <br />{' '}
                  {`(${Number(overAll_AveragePercent[4]).toFixed(2)}%)`}
                </td>
                <td>
                  {overAll_AverageCnt[3]} <br />{' '}
                  {`(${Number(overAll_AveragePercent[3]).toFixed(2)}%)`}
                </td>
                <td>
                  {overAll_AverageCnt[2]} <br />{' '}
                  {`(${Number(overAll_AveragePercent[2]).toFixed(2)}%)`}
                </td>
                <td>
                  {overAll_AverageCnt[1]} <br />{' '}
                  {`(${Number(overAll_AveragePercent[1]).toFixed(2)}%)`}
                </td>
                <td>
                  {overAll_AverageCnt[0]} <br />{' '}
                  {`(${Number(overAll_AveragePercent[0]).toFixed(2)}%)`}
                </td>
              </tr>
              <tr>
                <td className="colHead">Overall Score</td>
                <td colSpan="5">
                  {Math.round(
                    ((overAll_AverageCnt[4] * 5 +
                      overAll_AverageCnt[3] * 4 +
                      overAll_AverageCnt[2] * 3 +
                      overAll_AverageCnt[1] * 2 +
                      overAll_AverageCnt[0] * 1) /
                      totalRatingCount) *
                      100
                  ) / 100}{' '}
                  <br />{' '}
                  {`(${
                    Math.round(
                      ((overAll_AveragePercent[4] * 5 +
                        overAll_AveragePercent[3] * 4 +
                        overAll_AveragePercent[2] * 3 +
                        overAll_AveragePercent[1] * 2 +
                        overAll_AveragePercent[0] * 1) /
                        100) *
                        100
                    ) / 100
                  }%)`}{' '}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default RatingDetailTables;
