import * as React from 'react';
import apis from '../apis';
import { AuthProvider } from '../auth/AuthContext';

const HostelRequestElement = (props) => {
  const [room, setRoom] = React.useState(props?.room);
  const { adminData } = AuthProvider();

  const allotRoom = async () => {
    await apis
      .allotRoom({ id: props?.id, room })
      .then((res) => {
        alert('Room Alloted');
        props.fetchAllApplied();
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  return (
    <div className="roomCont">
      <input
        type="text"
        name="room"
        disabled={
          !adminData?.role?.find((e) => {
            return e === 'Warden';
          }) &&
          !adminData?.role?.find((e) => {
            return 'Super Admin';
          }) &&
          !adminData?.role?.find((e) => {
            return 'HAB';
          })
        }
        id="room"
        value={room}
        className="roomInput"
        onChange={(e) => setRoom(e.target.value)}
      />
      {(adminData?.role?.find((e) => {
          return e === 'Super Admin';
        }) ||
        adminData?.role?.find((e) => {
          return e === 'HAB';
        }))
        && <input type="submit" value="set" className="roomBtn" onClick={allotRoom} />}
    </div>
  );
};

export default HostelRequestElement;
