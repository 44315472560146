import * as React from 'react';
import '../css/Page/HostelManagement.css';
import '../common/css/CommonStyles.css';
import AdminHeader from '../components/AdminHeader';
import HostelRequestElement from '../components/HostelRequestElement';
import apis from '../apis';
// import HostelReqHistoryElement from "../components/HostelReqHistoryElement";
import HostelProvider, { useHostelContext } from '../contexts/HostelProvider';
import { AuthProvider } from '../auth/AuthContext';
import Loader from '../components/Loader';
import Table from '../common/tables/Table';
import MessManagement from '../components/MessManagement';
import HostelAllotment from '../components/HostelAllotment';
import GeneralFilter1 from '../common/filters/GeneralFilter1';

const HostelManagementTable = ({ fetchAllApplied, hostelData, sessionList }) => {
  const { adminData } = AuthProvider();
  const [selectedData, setSelectedData] = React.useState([]);
  const [hostel, setHostel] = React.useState();
  const [mess, setMess] = React.useState();
  const [roomRange, setRoomRange] = React.useState({ start: '', end: '' });
  const [page, setPage] = React.useState(-1);
  const [loadingStatus, setLoadingStatus] = React.useState(true);
  const [filterAttribute, setFilterAttribute] = React.useState({});
  const [loadingStates, setLoadingStates] = React.useState({
    approveLoading: false,
    rejectLoading: false,
    allotHostelLoading: false,
    allotMessLoading: false,
    allotRoomLoading: false,
  });

  const { newHostelData, setNewHostelData } = useHostelContext();
  const [paymentData, setPaymentData] = React.useState();

  React.useEffect(() => {
    setNewHostelData(hostelData);
  }, [hostelData]);

  React.useEffect(() => {
    const fetchPaymentData = async () => {
      setLoadingStatus(true);
      await apis
        .getStudentFeesByHostel(
          filterAttribute?.hostel !== '' ? filterAttribute?.hostel : 'NA',
          filterAttribute?.semester !== '' ? filterAttribute.semester : 'NA'
        )
        .then((data) => {
          setPaymentData(data);
          setLoadingStatus(false);
        })
        .catch((err) => {
          alert(err.message);
        });
    };
    fetchPaymentData();
  }, [filterAttribute]);

  React.useEffect(() => {
    if (paymentData?.length > 0) {
      setPage(0);
    }
  }, [paymentData, filterAttribute?.hostel]);

  // React.useEffect(() => {
  //   if (hostelData) {
  //     setNewHostelData(
  //       adminData?.role?.find((e) => e === "Warden")
  //         ? hostelData.filter((e) => {
  //             return e.hostel === adminData?.wardenHostel;
  //           })
  //         : hostelData
  //     );
  //   }
  //   setSearch((e) => {
  //     return {
  //       ...e,
  //       hostel: adminData?.role?.find((e) => e === "Warden")
  //         ? adminData?.wardenHostel
  //         : "",
  //     };
  //   });
  // }, [hostelData, adminData]);

  const requiredFilters = [
    'gender',
    'session',
    'course',
    'batch',
    'semester',
    'hostel',
    'previousHostel',
    'hostelStatus',
  ];
  const [filteredData, setFilteredData] = React.useState([]);

  React.useEffect(() => {
    if (hostelData && hostelData.length > 0) {
      let tempData = hostelData;

      if (filterAttribute['gender']) {
        tempData = tempData.filter((data) => data['gender'] === filterAttribute['gender']);
      }
      if (filterAttribute['session']) {
        tempData = tempData.filter((data) => data['session'] === filterAttribute['session']);
      }
      if (filterAttribute['course']) {
        tempData = tempData.filter((data) => data['program'] === filterAttribute['course']);
      }
      if (filterAttribute['batch']) {
        tempData = tempData.filter((data) => data['batch'] === filterAttribute['batch']);
      }
      if (filterAttribute['semester']) {
        tempData = tempData.filter((data) => data['semester'] === filterAttribute['semester']);
      }
      if (filterAttribute['hostel']) {
        tempData = tempData.filter((data) => data['hostel'] === filterAttribute['hostel']);
      }
      if (filterAttribute['previousHostel']) {
        tempData = tempData.filter(
          (data) => data['previousHostel'] === filterAttribute['previousHostel']
        );
      }
      if (filterAttribute['hostelStatus']) {
        tempData = tempData.filter((data) => data['status'] === filterAttribute['hostelStatus']);
      }

      setFilteredData(tempData);
    }
  }, [filterAttribute, hostelData]);

  // React.useEffect(() => {
  //   setNewHostelData(newHostelData)
  // }, [newHostelData])

  const approveSelected = async (e) => {
    e.preventDefault();
    setLoadingStates({ ...loadingStates, approveLoading: true });
    await apis
      .approveHostelApplication({ selected: selectedData })
      .then((res) => {
        setLoadingStates({ ...loadingStates, approveLoading: false });
        alert('Applications Approved');
        fetchAllApplied();
      })
      .catch((err) => {
        setLoadingStates({ ...loadingStates, approveLoading: false });
        alert(err.message);
      });
  };

  const rejectSelected = async (e) => {
    e.preventDefault();
    setLoadingStates({ ...loadingStates, rejectLoading: true });
    await apis
      .rejectHostelApplication({ selected: selectedData })
      .then((res) => {
        setLoadingStates({ ...loadingStates, rejectLoading: false });
        alert('Applications Rejected');
        fetchAllApplied();
      })
      .catch((err) => {
        setLoadingStates({ ...loadingStates, rejectLoading: false });
        alert(err.message);
      });
  };

  const allotHostel = async (e) => {
    e.preventDefault();
    setLoadingStates({ ...loadingStates, allotHostelLoading: true });
    await apis
      .allotHostel({ selected: selectedData, hostel })
      .then((res) => {
        setLoadingStates({ ...loadingStates, allotHostelLoading: false });
        alert('Hostel Alloted');
        fetchAllApplied();
      })
      .catch((err) => {
        setLoadingStates({ ...loadingStates, allotHostelLoading: false });
        alert(err.message);
      });
  };

  const allotMess = async (e) => {
    e.preventDefault();
    setLoadingStates({ ...loadingStates, allotMessLoading: true });
    await apis
      .allotMess({ selected: selectedData, mess })
      .then((res) => {
        setLoadingStates({ ...loadingStates, allotMessLoading: false });
        alert('Mess Alloted');
        fetchAllApplied();
      })
      .catch((err) => {
        setLoadingStates({ ...loadingStates, allotMessLoading: false });
        alert(err.message);
      });
  };

  const allotRoomsToStudents = async (e) => {
    e.preventDefault();
    setLoadingStates({ ...loadingStates, allotRoomLoading: true });
    await apis
      .allotRoomToStudents({
        gender: filterAttribute.gender,
        session: filterAttribute.session,
        batch: filterAttribute.batch,
        program: filterAttribute.course,
        semester: filterAttribute.semester,
        startRoom: parseInt(roomRange.start, 10),
        endRoom: parseInt(roomRange.end, 10),
        hostel: filterAttribute.hostel,
      })
      .then((res) => {
        setLoadingStates({ ...loadingStates, allotRoomLoading: false });
        alert(res.message);
        fetchAllApplied();
      })
      .catch((err) => {
        setLoadingStates({ ...loadingStates, allotRoomLoading: false });
        alert(err.message);
      });
  };

  const columns = [
    {
      Header: (
        <input
          className="checkbox"
          type="checkbox"
          checked={filteredData.length > 0 && filteredData.every((data) => data.checkbox)}
          onChange={(e) => {
            const newStudentDatas = filteredData.map((data) => {
              return {
                ...data,
                checkbox: e.target.checked,
              };
            });
            setFilteredData(newStudentDatas);
            setSelectedData(e.target.checked ? newStudentDatas.map((data) => data.id) : []);
          }}
        />
      ),
      accessor: 'checkbox',
      style: { width: '50px' },
      showContextMenu: false,
    },
    {
      Header: 'S.N.',
      accessor: 'sno',
      style: { width: '48px', minWidth: '48px', cursor: 'pointer' },
      showContextMenu: true,
    },
    {
      Header: 'Name',
      accessor: 'name',
      style: { width: '150px', cursor: 'pointer' },
      showContextMenu: true,
    },
    {
      Header: 'Roll/App. ID',
      accessor: 'roll',
      style: { width: '110px', minWidth: '110px', cursor: 'pointer' },
      showContextMenu: true,
    },
    {
      Header: 'Gender',
      accessor: 'gender',
      style: { width: '70px', minWidth: '70px', cursor: 'pointer' },
      showContextMenu: true,
    },
    {
      Header: 'Batch',
      accessor: 'batch',
      style: { width: '60px', minWidth: '60px', cursor: 'pointer' },
      showContextMenu: true,
    },
    {
      Header: 'Semester',
      accessor: 'semester',
      style: { width: '88px', minWidth: '88px', cursor: 'pointer' },
      showContextMenu: true,
    },
    {
      Header: 'Program',
      accessor: 'program',
      style: { width: '85px', minWidth: '85px', cursor: 'pointer' },
      showContextMenu: true,
    },
    {
      Header: 'Hostel',
      accessor: 'hostel',
      style: { width: '80px', minWidth: '80px', cursor: 'pointer' },
      showContextMenu: true,
    },
    {
      Header: 'Previous Hostel',
      accessor: 'previousHostel',
      style: { width: '110px', minWidth: '110px', cursor: 'pointer' },
      showContextMenu: true,
    },
    {
      Header: 'Mess',
      accessor: 'mess',
      style: { width: '80px', minWidth: '80px', cursor: 'pointer' },
      showContextMenu: true,
    },
    {
      Header: 'Status',
      accessor: 'status',
      style: { width: '70px', minWidth: '70px', cursor: 'pointer' },
      showContextMenu: true,
    },
    {
      Header: 'Allot Room',
      accessor: 'room',
      style: { width: '100%' },
      showContextMenu: false,
    },
  ];

  // const filteredData = filterData(newHostelData, option);

  const hosteldata =
    filteredData &&
    filteredData.map((data, index) => ({
      checkbox: (
        <input
          className="checkbox"
          type="checkbox"
          checked={data.checkbox}
          onChange={(e) => {
            const newStudentDatas = filteredData.map((studData) => {
              if (studData.id === data.id) {
                return {
                  ...studData,
                  checkbox: e.target.checked,
                };
              }
              return studData;
            });
            setFilteredData(newStudentDatas);
            setSelectedData(
              e.target.checked
                ? [...selectedData, data.id]
                : selectedData.filter((id) => id !== data.id)
            );
          }}
        />
      ),
      sno: index + 1,
      name: data.name,
      roll: data.roll ?? data.applicationid,
      gender: data.gender,
      batch: data.batch,
      semester: data.semester,
      program: data.program,
      hostel: data.hostel,
      previousHostel: data.previousHostel,
      mess: data.mess,
      status: data.status,
      room: (
        <div className="roomCont">
          {/* <input
          type="text"
          name="room"
          id="room"
          value={data.room}
          className="roomInput"
          disabled={!["Warden", "Super Admin", "HAB"].some(role => adminData?.role?.includes(role))}
          onChange={(e) => {
            const newRoom = e.target.value;
            const newData = [...newHostelData];
            newData[index].room = newRoom;
            setNewHostelData(newData);
          }}
        />
        {["Warden", "Super Admin"].some(role => adminData?.role?.includes(role)) &&
          <input
            type="submit"
            value="set"
            className="roomBtn"
            onClick={() => {
              apis.allotRoom({ id: data.id, room: data.room })
                .then(() => alert("Room Allotted"))
                .catch((err) => alert(err.message));
            }}
          />
        } */}
          <HostelRequestElement key={data.id} id={data.id} room={data.room} fetchAllApplied={fetchAllApplied} />
        </div>
      ),
    }));

  return (
    <>
      <div className="hostel-management-body">
        <GeneralFilter1
          filterHeading="Filter students using"
          filters={requiredFilters}
          setFilterAttribute={setFilterAttribute}
          filterAttribute={filterAttribute}
        />
        <div className="seat-allotment">
          <div
            className="allotment-requests"
            style={{
              width:
                adminData?.role?.find((e) => {
                  return e === 'Warden';
                }) &&
                adminData?.role?.find((e) => {
                  return 'Super Admin';
                }) &&
                '100%',
            }}
          >
            {/* <div className="request-list" >
              <div className="request-list-header">
                <div className="request-list-header-item checkbox-container">
                  {(adminData?.role?.find((e) => { return e === "HAB" }) || adminData.role?.find((e) => { return e === "Super Admin" })) && <input
                    className="checkbox"
                    type="checkbox"
                    checked={selectAll}
                    onChange={(e) => setSelectAll(e.target.checked)}
                  />
                  }
                </div>
                <div
                  className="request-list-header-item"
                >
                  <h4>S.N.</h4>
                </div>
                <div
                  className="request-list-header-item"
                >
                  <h4>Name</h4>
                </div>
                <div
                  className="request-list-header-item"
                  onClick={() => setOption("Roll")}
                  style={{ cursor: "pointer" }}
                >
                  <h4>Roll/App. ID</h4>
                </div>
                <div
                  className="request-list-header-item"
                  onClick={() => setOption("Gender")}
                  style={{ cursor: "pointer" }}
                >
                  <h4>Gender</h4>
                </div>
                <div
                  className="request-list-header-item"
                  onClick={() => setOption("Batch")}
                  style={{ cursor: "pointer" }}
                >
                  <h4>Batch</h4>
                </div>
                <div
                  className="request-list-header-item"
                  onClick={() => setOption("Semester")}
                  style={{ cursor: "pointer" }}
                >
                  <h4>Semester</h4>
                </div>
                <div
                  className="request-list-header-item"
                  onClick={() => setOption("Program")}
                  style={{ cursor: "pointer" }}
                >
                  <h4>Program</h4>
                </div>
                <div
                  className="request-list-header-item"
                  onClick={() => setOption("Preference")}
                  style={{ cursor: "pointer" }}
                >
                  <h4>Hostel</h4>
                </div>
                <div
                  className="request-list-header-item"
                  onClick={() => setOption("Preference")}
                  style={{ cursor: "pointer" }}
                >
                  <h4>Previous Hostel</h4>
                </div>
                <div
                  className="request-list-header-item"
                  onClick={() => setOption("Status")}
                  style={{ cursor: "pointer" }}
                >
                  <h4>Status</h4>
                </div>
                <div
                  className="request-list-header-item"
                >
                  <h4>Allot Room</h4>
                </div>
              </div>
              <div className="request-list-body">
                {filterData(newHostelData, option)?.map((data, index) => {
                  return (
                    <HostelRequestElement
                      key={data.id}
                      sno={index + 1}
                      id={data.id}
                      name={data.name}
                      roll={data.roll ?? data.applicationid}
                      gender={data.gender}
                      batch={data.batch}
                      semester={data.semester}
                      program={data.program}
                      hostel={data.hostel}
                      previousHostel={data.previousHostel}
                      status={data.status}
                      room={data.room}
                    />
                  );
                })}
              </div>
            </div> */}
            <div className="request-list">
              <Table
                columns={columns}
                data={hosteldata}
                loading={false}
                className="ratingDetailTable"
                bodyClassName="request-list-body ratingDetailBody"
              />
            </div>
          </div>
          {(adminData?.role?.find((e) => {
            return e === 'HAB';
          }) ||
            adminData.role?.find((e) => {
              return e === 'Super Admin';
            })) && (
            <div className="allotment-actions">
              {/* <div className="hostelTimerSection">
                <GenerateTimer schedularType={"HOSTEL"} />
              </div> */}
              <div className="approval-btns">
                <h3>Approve or Reject Selected</h3>
                <div className="remarks">
                  <label htmlFor="remarks">Remarks:</label>
                  <textarea id="remarks" rows="4" cols="50"></textarea>
                </div>
                <div className="approve-reject-btns">
                  <button
                    className="approve-btn primary"
                    disabled={selectedData.length === 0 || loadingStates.approveLoading}
                    onClick={(e) => approveSelected(e)}
                    style={{
                      cursor: `${loadingStates.approveLoading ? 'not-allowed' : 'pointer'}`,
                      opacity: `${loadingStates.approveLoading ? '0.5' : '1'}`,
                    }}
                  >
                    Approve
                  </button>
                  <button
                    className="reject-btn secondary"
                    disabled={selectedData.length === 0 || loadingStates.rejectLoading}
                    onClick={(e) => rejectSelected(e)}
                    style={{
                      cursor: `${loadingStates.rejectLoading ? 'not-allowed' : 'pointer'}`,
                      opacity: `${loadingStates.rejectLoading ? '0.5' : '1'}`,
                    }}
                  >
                    Reject
                  </button>
                </div>
              </div>
              <div className="allotment-btns">
                <h3>Allot Hostel to Selected</h3>
                <form>
                  <div className="formgroup">
                    <label htmlFor="hostel" className="required">
                      Select Hostel:
                    </label>
                    <select
                      id="hostel"
                      required
                      value={hostel}
                      onChange={(e) => setHostel(e.target.value)}
                      style={{ maxWidth: '180px' }}
                    >
                      <option value="">Select Hostel</option>
                      <option value="H1">Hostel 1</option>
                      <option value="H9">Hostel 9</option>
                      <option value="H7">Hostel 7</option>
                      <option value="GH">Girls Hostel (BCE)</option>
                      <option value="NBH">New Boys Hostel</option>
                      <option value="KBH">Kautilya Boys Hostel</option>
                      <option value="KGH">Kadambini Girls Hostel</option>
                    </select>
                  </div>
                  <button
                    className="allot-btn primary"
                    onClick={(e) => allotHostel(e)}
                    disabled={selectedData.length === 0 || loadingStates.allotHostelLoading}
                    style={{
                      marginBottom: '10px',
                      cursor: `${loadingStates.allotHostelLoading ? 'not-allowed' : 'pointer'}`,
                      opacity: `${loadingStates.allotHostelLoading ? '0.5' : '1'}`,
                    }}
                  >
                    Allot to Selected
                  </button>
                </form>
              </div>
              <div className="allotment-btns">
                <h3>Allot Mess to Selected</h3>
                <form>
                  <div className="formgroup">
                    <label htmlFor="hostel" className="required">
                      Select Mess Service:
                    </label>
                    <select
                      id="mess"
                      required
                      value={mess}
                      onChange={(e) => setMess(e.target.value)}
                      style={{ maxWidth: '180px' }}
                    >
                      <option value="">Select Mess Service</option>
                      <option value="KBH_MESS">Sai Hospitality And Services(NBH/H1)</option>
                      <option value="VSL_MESS">Sugandhim – Food & Catering(VCL)</option>
                    </select>
                  </div>
                  <button
                    className="allot-btn primary"
                    onClick={(e) => allotMess(e)}
                    disabled={selectedData.length === 0 || loadingStates.allotMessLoading}
                    style={{
                      marginBottom: '10px',
                      cursor: `${loadingStates.allotMessLoading ? 'not-allowed' : 'pointer'}`,
                      opacity: `${loadingStates.allotMessLoading ? '0.5' : '1'}`,
                    }}
                  >
                    Allot to Selected
                  </button>
                </form>
              </div>
              <div className="allotment-btns">
                <h3>Allot Rooms to Students</h3>
                <form>
                  <div
                    className="formgroup"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start',
                      gap: '10px',
                    }}
                  >
                    <label htmlFor="hostel" className="required">
                      Room Range:
                    </label>
                    <input
                      type="number"
                      placeholder="Start"
                      value={roomRange.start}
                      onChange={(e) => setRoomRange({ ...roomRange, start: e.target.value })}
                      style={{
                        maxWidth: '100px',
                        width: '90px',
                        padding: '5px 10px',
                      }}
                      className="input_field"
                    />
                    <input
                      type="number"
                      placeholder="End"
                      value={roomRange.end}
                      onChange={(e) => setRoomRange({ ...roomRange, end: e.target.value })}
                      style={{
                        maxWidth: '100px',
                        width: '90px',
                        padding: '5px 10px',
                      }}
                      className="input_field"
                    />
                  </div>
                  <button
                    className="allot-btn primary"
                    onClick={(e) => allotRoomsToStudents(e)}
                    disabled={loadingStates.allotRoomLoading}
                    style={{ marginBottom: '10px' }}
                  >
                    Allot
                  </button>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
      {!adminData?.role?.find((e) => e === 'Warden') && (
        <div className="bottom-div">
          <div className="payHistory-section">
            <div className="payHistory-div">
              <div className="title-div cv">
                <div className="title-left-div">
                  <h2>Payment History:</h2>
                </div>

                {/* <div className="filter-item title-right-div">
                  <h4>From: </h4>
                  <input class="date-input" type="date" value={dateRange[0]} onChange={
                    (e) => {
                      setDateRange([e.target.value, dateRange[1]])
                    }
                  } />
                  <h4 style={{ marginLeft: "5%" }}>To: </h4>
                  <input class="date-input" type="date"
                    value={dateRange[1]} onChange={
                      (e) => {
                        setDateRange([dateRange[0], e.target.value])
                      }
                    }
                  />
                </div> */}
              </div>
              <div className="content-div">
                <div className="result-items history">
                  <div className="result-heading">
                    <div className="result-heading-item index-column">
                      <h4>S.No</h4>
                    </div>
                    <div className="result-heading-item">
                      <h4>Roll No.</h4>
                    </div>
                    <div className="result-heading-item">
                      <h4>Hostel Fee Status</h4>
                    </div>
                    <div className="result-heading-item">
                      <h4>Mess Fee Status</h4>
                    </div>
                    <div className="result-heading-item">
                      <h4>Batch</h4>
                    </div>
                    <div className="result-heading-item">
                      <h4>Program</h4>
                    </div>
                  </div>
                  {loadingStatus ? (
                    <Loader />
                  ) : (
                    <div className="result-contents">
                      {paymentData
                        ?.filter((e, i) => (i >= page * 50 && i < page * 50 + 50 ? true : false))
                        .map((payment, index) => {
                          return (
                            <PaymentDetail
                              index={index + page * 50 + 1}
                              payment={payment}
                              key={payment.id}
                              admin={true}
                            />
                          );
                        })}
                    </div>
                  )}
                  <div className="pagination">
                    <button className="new_btn" onClick={() => setPage(page > 0 ? page - 1 : page)}>
                      <i className="fa-solid fa-arrow-left"></i>
                    </button>
                    <div className="page-number">
                      <p>
                        {page + 1} / {Math.ceil(paymentData?.length / 50)}
                      </p>
                    </div>
                    <button
                      className="new_btn"
                      onClick={() => setPage(page + 1 < paymentData?.length / 50 ? page + 1 : page)}
                    >
                      <i className="fa-solid fa-arrow-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const DownloadCsv = () => {
  // const { searchResult } = useSearch();
  //
  const { newHostelData } = useHostelContext();
  const { adminData } = AuthProvider();
  async function downloadCsv() {
    const headers = [
      {
        label: 'Roll',
        value: 'roll',
      },
      {
        label: 'Name',
        value: 'name',
      },
      {
        label: 'Gender',
        value: 'gender',
      },
      {
        label: 'Program',
        value: 'program',
      },
      {
        label: 'Batch',
        value: 'batch',
      },
      {
        label: 'Status',
        value: 'status',
      },
      {
        label: 'Alloted Hostel',
        value: 'hostel',
      },
      {
        label: 'Alloted room',
        value: 'room',
      },
    ];
    await apis
      .downloadCsv(
        adminData?.role?.find((e) => e === 'Warden')
          ? newHostelData?.filter((e) => {
              return e.hostel === adminData?.wardenHostel;
            })
          : newHostelData,
        headers,
        'hostelData.csv'
      )
      .then((data) => {
        let url = window.URL.createObjectURL(data);
        window.open(url, +'_blank');
        alert('Downloaded');
      })
      .catch((err) => {
        alert(err.message);
      });
  }
  return (
    <div className="result-footer">
      <div className="result-footer-title">
        <h2>Export Hostel Allotment Records</h2>
        <p>(Get an offline .csv file of above data)</p>
      </div>
      <div className="result-footer-button">
        <button onClick={() => downloadCsv()}>
          Start exporting now as .csv file <i className="fa-solid fa-circle-arrow-right"></i>
        </button>
      </div>
    </div>
  );
};

const PaymentDetail = ({ payment, index }) => {
  return (
    <div className="result-content">
      <div className="result-content-item index-column">
        <h4>{index}. </h4>
      </div>
      <div className="result-content-item">
        <h4>{payment.roll ?? payment?.applicationid}</h4>
      </div>
      <div className="result-content-item">
        <h4>₹ {payment.paymentStatusSplit.hostel}</h4>
      </div>
      <div className="result-content-item">
        <h4>₹ {payment.paymentStatusSplit.mess}</h4>
      </div>
      <div className="result-content-item">
        <h4>{payment.batch}</h4>
      </div>
      <div className="result-content-item TID">
        <h4>{payment.course}</h4>
      </div>
    </div>
  );
};

function CountDisplay() {
  const { newHostelData } = useHostelContext();

  return (
    <div className="allotment-highlights">
      <div className="allotment-highlight-group">
        <div className="allotment-highlight">
          <h1>{newHostelData?.length}</h1>
          <h4>Total</h4>
        </div>
        <div className="allotment-highlight">
          <h1>
            {
              newHostelData?.filter((data) => {
                return data.status === 'Pending';
              }).length
            }
          </h1>
          <h4>Pending</h4>
        </div>
        <div className="allotment-highlight">
          <h1>
            {
              newHostelData?.filter((data) => {
                return data.status === 'Approved';
              }).length
            }
          </h1>
          <h4>Approved</h4>
        </div>
        <div className="allotment-highlight">
          <h1>
            {
              newHostelData?.filter((data) => {
                return data.status === 'Rejected';
              }).length
            }
          </h1>
          <h4>Rejected</h4>
        </div>
        <div className="allotment-highlight">
          <h1>
            {
              newHostelData?.filter((data) => {
                return data.status === 'Alloted';
              }).length
            }
          </h1>
          <h4>Alloted</h4>
        </div>
      </div>
      <div className="allotment-highlight-group">
        <div className="allotment-highlight allotted-element">
          <h1>
            {
              newHostelData?.filter((data) => {
                return data.hostel === 'H1';
              }).length
            }
          </h1>
          <h4>H1</h4>
        </div>
        <div className="allotment-highlight allotted-element">
          <h1>
            {
              newHostelData?.filter((data) => {
                return data.hostel === 'H9';
              }).length
            }
          </h1>
          <h4>H9</h4>
        </div>
        <div className="allotment-highlight allotted-element">
          <h1>
            {
              newHostelData?.filter((data) => {
                return data.hostel === 'H7';
              }).length
            }
          </h1>
          <h4>H7</h4>
        </div>
        <div className="allotment-highlight allotted-element">
          <h1>
            {
              newHostelData?.filter((data) => {
                return data.hostel === 'GH';
              }).length
            }
          </h1>
          <h4>GH</h4>
        </div>
        {/* <div className="allotment-highlight allotted-element">
        <h1>
          {
            newHostelData?.filter((data) => {
              return data.hostel === "NBH";
            }).length
          }
        </h1>
        <h4>NBH</h4>
      </div> */}
        <div className="allotment-highlight allotted-element">
          <h1>
            {
              newHostelData?.filter((data) => {
                let yearFromSession = data.session.split('-')[0];
                if (Number(yearFromSession) >= 2024) {
                  return data.hostel === 'KBH';
                } else {
                  return data.hostel === 'NBH';
                }
              }).length
            }
          </h1>
          <h4>KBH</h4>
        </div>
        <div className="allotment-highlight allotted-element">
          <h1>
            {
              newHostelData?.filter((data) => {
                return data.hostel === 'KGH';
              }).length
            }
          </h1>
          <h4>KGH</h4>
        </div>
      </div>
    </div>
  );
}

const HostelManagement = () => {
  const [hostelData, setHostelData] = React.useState();
  const [page, setPage] = React.useState(-1);
  const [loadingStatus, setLoadingStatus] = React.useState(true);
  const [sessionList, setSessionList] = React.useState([]);
  const [feeDetails, setFeeDetails] = React.useState();
  const { adminData } = AuthProvider();
  const fetchAllApplied = async () => {
    await apis
      .getSessionFilterList()
      .then(async (sessionList) => {
        setSessionList(sessionList);
        const currSession = sessionList[0];
        await apis
          .getAllHostelApplications(currSession)
          .then((res) => {
            setHostelData(res);
          })
          .catch((err) => {
            alert(err.message);
          });
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const fetchStudentFeesByHostel = React.useCallback(async () => {
    setLoadingStatus(true);
    adminData?.wardenHostel &&
      (await apis
        .getStudentFeesByHostel(adminData?.wardenHostel)
        .then((data) => {
          setFeeDetails(data);
          setLoadingStatus(false);
        })
        .catch((err) => {
          alert(err.message);
        }));
  }, [adminData]);

  React.useEffect(() => {
    fetchStudentFeesByHostel();
    fetchAllApplied();
  }, [fetchStudentFeesByHostel]);

  React.useEffect(() => {
    if (feeDetails?.length > 0) {
      setPage(0);
    }
  }, [feeDetails, adminData?.wardenHostel]);

  return (
    <>
      <AdminHeader />
      <HostelProvider>
        <div className="top-div">
          <CountDisplay />
        </div>
        <div className="mid-div">
          <div className="hostel-management">
            <div className="title cv">
              <div className="title-left-div">
                <h2>Hostel Allotment:</h2>
              </div>

              <div className="title-right-div">
                <div className="btn-options"></div>
              </div>
            </div>

            <HostelAllotment fetchAllApplied={fetchAllApplied} hostelData={hostelData} />
          </div>
        </div>
        <div className="mid-div">
          <div className="hostel-management">
            <div className="title cv">
              <div className="title-left-div">
                <h2>Hostel management:</h2>
              </div>

              <div className="title-right-div">
                <div className="btn-options"></div>
              </div>
            </div>

            <HostelManagementTable
              fetchAllApplied={fetchAllApplied}
              hostelData={hostelData}
              sessionList={sessionList}
            />
          </div>
        </div>
        <div className="mid-div">
          <div className="hostel-management">
            <div className="title cv">
              <div className="title-left-div">
                <h2>Mess management:</h2>
              </div>

              <div className="title-right-div">
                <div className="btn-options"></div>
              </div>
            </div>

            <MessManagement />
            {/* <HostelManagementTable hostelData={hostelData} sessionList={sessionList} /> */}
          </div>
        </div>
        <div className="mid-div" style={{ marginBottom: '0px' }}>
          <DownloadCsv />
        </div>

        {adminData?.role?.find((e) => e === 'Warden') && (
          <div className="bottom-div">
            <div className="payHistory-section">
              <div className="payHistory-div">
                <div className="title-div cv">
                  <div className="title-left-div">
                    <h2>Payment History:</h2>
                  </div>

                  {/* <div className="filter-item title-right-div">
                  <h4>From: </h4>
                  <input class="date-input" type="date" value={dateRange[0]} onChange={
                    (e) => {
                      setDateRange([e.target.value, dateRange[1]])
                    }
                  } />
                  <h4 style={{ marginLeft: "5%" }}>To: </h4>
                  <input class="date-input" type="date"
                    value={dateRange[1]} onChange={
                      (e) => {
                        setDateRange([dateRange[0], e.target.value])
                      }
                    }
                  />
                </div> */}
                </div>
                <div className="content-div">
                  <div className="result-items history">
                    <div className="result-heading">
                      <div className="result-heading-item index-column">
                        <h4>S.No</h4>
                      </div>
                      <div className="result-heading-item">
                        <h4>Roll No.</h4>
                      </div>
                      <div className="result-heading-item">
                        <h4>Hostel Fee</h4>
                      </div>
                      <div className="result-heading-item">
                        <h4>Mess Fee</h4>
                      </div>
                      <div className="result-heading-item">
                        <h4>Batch</h4>
                      </div>
                      <div className="result-heading-item">
                        <h4>Program</h4>
                      </div>
                    </div>
                    {loadingStatus ? (
                      <Loader />
                    ) : (
                      <div className="result-contents">
                        {feeDetails
                          ?.filter((e, i) => (i >= page * 50 && i < page * 50 + 50 ? true : false))
                          .map((payment, index) => {
                            return (
                              <PaymentDetail
                                index={index + page * 50 + 1}
                                payment={payment}
                                key={payment.id}
                                admin={true}
                              />
                            );
                          })}
                      </div>
                    )}
                    <div className="pagination">
                      <button
                        className="new_btn"
                        onClick={() => setPage(page > 0 ? page - 1 : page)}
                      >
                        <i className="fa-solid fa-arrow-left"></i>
                      </button>
                      <div className="page-number">
                        <p>
                          {page + 1} / {Math.ceil(feeDetails?.length / 50)}
                        </p>
                      </div>
                      <button
                        className="new_btn"
                        onClick={() =>
                          setPage(page + 1 < feeDetails?.length / 50 ? page + 1 : page)
                        }
                      >
                        <i className="fa-solid fa-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </HostelProvider>
    </>
  );
};

export default HostelManagement;
