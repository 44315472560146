import React from 'react';
import '../css/Component/Header.css';
import { useNavigate } from 'react-router-dom';
import jwtVerify from '../auth/service';
import { AuthProvider } from '../auth/AuthContext';

function AdminHeader() {
  const navigate = useNavigate();
  const logout = () => {
    jwtVerify.logoutUser('admin');
    navigate('/');
  };

  const Dashboard = () => {
    navigate('/student/admin/dashboard');
  };
  const ManageAdmins = () => {
    navigate('/student/admin/manage-admins');
  };

  const { adminData } = AuthProvider();
  // console.log(adminData);
  return (
    <nav className="navbar">
      <div className="container">
        <div className="nav-logo" onClick={() => navigate('/student/admin')}>
          <img src="/assets/inst-logo.svg" alt="logo" />
          <img src="/assets/admin-logo-name.svg" alt="name" />
        </div>
        <div className="role-type">
          {adminData &&
            adminData.role &&
            adminData.role.length > 0 &&
            (adminData.role.length > 1 ? (
              <marquee>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {adminData?.role.map((role, index) => {
                    return (
                      <h4 key={index} style={{ marginRight: '10px' }}>
                        {role}
                      </h4>
                    );
                  })}
                </div>
              </marquee>
            ) : (
              adminData?.role.map((role, index) => {
                return <h4 key={index}>{role}</h4>;
              })
            ))}
        </div>
        <div className="nav-right">
          <div className="nav-help">
            {(adminData?.role?.includes('Super Admin') && adminData?.name === "Developer Account") ||
            adminData?.role?.includes('Complaint-Electrical') ||
            adminData?.role?.includes('Complaint-Internet') ||
            adminData?.role?.includes('Complaint-Civil') ||
            adminData?.role?.includes('Complaint-Others') ? (
              <button onClick={Dashboard}>Complaint Dashboard</button>
            ) : null}
            {(adminData?.role?.find((e) => {
              return e === 'Super Admin';
            }) ||
              adminData?.role?.find((e) => {
                return e === 'DOAA';
              })) && <button onClick={ManageAdmins}>Manage Admins</button>}
          </div>
          <div className="nav-logout">
            <button id="logout" className="primary" onClick={() => logout()}>
              <span>Logout</span>
              <img src="/assets/logout.svg" alt="logout" />
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default AdminHeader;
