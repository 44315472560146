import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import { AuthProvider } from '../auth/AuthContext';
import apis from '../apis';
import '../css/Page/StudentFeedback.css';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { FiChevronDown } from 'react-icons/fi';
import { Rating } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

const questions = [
  'The course was well planned and organised.',
  'The topics provided new knowledge.',
  'Reading materials were available.',
  'Classes were held as per time table.',
  'Work reqiurements and grading system were clear form the beginning.',
  'The fundamental concepts were prescribed clearly in the lecture.',
  'Teacher voice is and audible.',
  'Teacher blackboard (or overhead)presentation was well organised and legible.',
  'Teacher uses class time well.',
  'Teacher stresses important points in the lecture and discussions.',
  'Teacher responded adequately to the questions asked in the class.',
  'Teacher treats students with respect.',
  'Teacher is willing to meet and help students outside the class.',
  'Teacher uses class time well.',
];

const StudentFeedback = () => {
  const { userData } = AuthProvider();
  const navigate = useNavigate();
  // console.log(userData);
  const [prevSession, setPrevSession] = useState('');
  const [courseTeacher, setCourseTeacher] = useState();
  const [totalTeacherCount, setTotalTeacherCount] = useState(0);
  const [totalSaveCount, setTotalSaveCount] = useState(0);
  const [saveCntArray, setSaveCntArray] = useState();
  const [loading, setLoading] = useState(false);

  const generateCurrentSession = () => {
    const batch = userData?.batch;

    const firstHalf = batch.slice(0, 4);
    const secondHalf = `20${batch.slice(5, 7)}`;
    const sessions = [];
    for (let i = parseInt(firstHalf); i < parseInt(secondHalf); i++) {
      const lastTwo = (i + 1).toString().slice(2, 4);
      sessions.push(`${i}-${lastTwo}`);
    }
    setPrevSession('2024-25'); // TPDO: make it dynamic
  };

  useEffect(() => {
    // generateCurrentSession();
    if (Object.keys(userData).length !== 0) {
      generateCurrentSession();
    }
  }, [userData]);

  // const checkReviewStatus = async () => {
  //   const queryData = {
  //     student_roll: userData.roll,
  //     semester: Number(userData?.semester) - 1,
  //     session: prevSession,
  //   };

  //   setLoading(true);
  //   if(userData?.semester === "1") {
  //     setLoading(false);
  //     navigate("/student/register");
  //   }
  //   else{
  //     const status = await apis.isReviewSubmitted(queryData);
  //     console.log(status);
  //     if (!status) {
  //       setLoading(false);
  //     } else {
  //       navigate("/student/register");
  //       setLoading(false);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (Object.keys(userData).length !== 0) {
  //     checkReviewStatus();
  //   }
  // }, [userData]);

  // const getFeedbackPageStatus = async () => {
  //   try {
  //     setLoading(true);
  //     const queryParameters = {
  //       student_roll: userData?.roll,
  //       semester: Number(userData?.semester),
  //       branch: userData?.branch,
  //       course: userData?.course,
  //       session: "",
  //     };

  //     Promise.resolve(apis.isReviewSubmitted(queryParameters)).then((status) => {
  //       setLoading(false);
  //       if(status){
  //         navigate("/student/");
  //       }
  //     });

  //   } catch (error) {
  //     console.log("Error in getting feedback page status");
  //     setLoading(false);
  //   }
  // };

  // React.useEffect(() => {
  //   getFeedbackPageStatus();
  // }, []);

  const getAllCoursesWithTeachers = async () => {
    const queryData = {
      session: prevSession,
      semester:
        userData?.course !== 'Ph.D.'
          ? (Number(userData?.semester) - 1).toString()
          : Number(userData?.semester).toString(),
      branch: userData?.branch?.replace(/&/g, '%26'),
      program: userData?.course,
    };
    await Promise.resolve(apis.getCoursesWithFaculty(queryData)).then((data) => {
      setLoading(false);
      let cnt = 0;
      let modifiedData = data.map((course) => {
        let faculties = course.faculties.map((faculty) => {
          return {
            ...faculty,
            ratings: {
              ...faculty.ratings,
              questions: Array(14).fill(0),
              counter: cnt++,
            },
          };
        });
        return {
          ...course,
          faculties: faculties,
        };
      });
      setCourseTeacher(modifiedData);
      let teacherCnt = 0;
      let temp = [];
      data.forEach((course) => {
        temp.push(Array(course.faculties.length).fill(Array(14).fill(0)));
        teacherCnt += course.faculties.length;
      });
      setSaveCntArray(temp);
      setTotalTeacherCount(teacherCnt);
    });
  };

  useEffect(() => {
    setLoading(true);
    if (Object.keys(userData).length !== 0) {
      getAllCoursesWithTeachers();
    }
  }, [prevSession, userData, setCourseTeacher]);

  const [answerIndex, setAnswerIndex] = useState(-1);
  const [reviewTableIndex, setReviewTableIndex] = useState(`-1_-1`);

  const handleToggleAnswer = (index) => {
    if (answerIndex === index) {
      setAnswerIndex(-1);
    } else {
      setAnswerIndex(index);
    }
  };

  const handleToggleReviewTable = (ansInd, revInd) => {
    if (reviewTableIndex === `${ansInd}_${revInd}`) {
      setReviewTableIndex(`-1_-1`);
    } else {
      setReviewTableIndex(`${ansInd}_${revInd}`);
    }
  };

  const updateRatingValue = (index, index2, index3, value) => {
    setCourseTeacher((prev) => {
      let temp = [...prev];
      temp[index].faculties[index2].ratings.questions[index3] = Number(value);
      if (index3 >= 0 && index3 <= 4)
        temp[index].faculties[index2].ratings.R_A[index3] = Number(value);
      else if (index3 >= 5 && index3 <= 9)
        temp[index].faculties[index2].ratings.R_B[index3 - 5] = Number(value);
      else if (index3 >= 10 && index3 <= 13)
        temp[index].faculties[index2].ratings.R_C[index3 - 10] = Number(value);

      return temp;
    });
  };

  const handleSave = async (index, index2) => {
    setCourseTeacher((prev) => {
      let temp = [...prev];
      temp[index].faculties[index2].submitted = true;
      return temp;
    });
    setReviewTableIndex(`-1_-1`);
  };

  //call the handleSave function on onClick on useEffect

  const isAllReviewSubmitted = () => {
    let count = 0;
    courseTeacher?.forEach((course) => {
      course?.faculties?.forEach((faculty) => {
        if (faculty.submitted === true) {
          count++;
        }
      });
    });

    return count;
  };

  useEffect(() => {
    if (courseTeacher) {
      setTotalSaveCount(isAllReviewSubmitted());
    }
  }, [courseTeacher, setCourseTeacher, setTotalSaveCount]);

  const handleSaveCount = () => {
    let arr = [];
    for (let i = 0; i < courseTeacher.length; i++) {
      for (let j = 0; j < courseTeacher[i].faculties.length; j++) {
        arr.push(courseTeacher[i].faculties[j].ratings.questions);
      }
    }

    return arr;
  };

  useEffect(() => {
    if (courseTeacher) {
      setSaveCntArray(handleSaveCount());
      // console.log(handleSaveCount());
    }
  }, [courseTeacher, setSaveCntArray]);

  const finalReviewSubmit = async () => {
    const studentData = {
      student_roll: userData?.roll,
      semester:
        userData.course !== 'Ph.D.' ? Number(userData?.semester) - 1 : Number(userData?.semester),
      session: prevSession,
      branch: userData?.branch,
      batch: userData?.batch,
      course: userData?.course,
      ratingSumbitted: true,
    };

    setLoading(true);
    await Promise.all(
      courseTeacher.map(async (course) => {
        await Promise.all(
          course.faculties.map(async (faculty) => {
            const data = {
              studentRoll: userData?.roll,
              semester:
                userData.course !== 'Ph.D.'
                  ? Number(userData?.semester) - 1
                  : Number(userData?.semester),
              session: prevSession,
              program: userData?.course,
              branch: userData?.branch,
              batch: userData?.batch,
              facultyId: faculty.id,
              courseId: course.courseData.id,
              R_A: faculty.ratings.R_A,
              R_B: faculty.ratings.R_B,
              R_C: faculty.ratings.R_C,
            };

            await Promise.resolve(apis.addDataToRatingsTable(data));
          })
        );
      })
    )
      .then(async () => {
        await Promise.resolve(apis.studentReviewSubmit(studentData))
          .then((ans) => {
            // console.log(ans);
            alert('Feedback Submitted Successfully');
            setLoading(false);
            navigate('/student');
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      {loading && (
        <div className="loadingContainer2">
          <CircularProgress size={60} thickness={5} />
        </div>
      )}
      <Header />
      <p className="headNote">
        Please fill this Faculty Feedback form before moving to Registration Process.
      </p>
      <div className="title cv titleCont">
        <div className="">
          <h2 className="feedbackHeading">Faculty Feedback:</h2>
          <p className="subNote">
            <span>*</span>Kindly give your valuable feedback. This feedback will allow us to analyze
            teacher status and provide quality eduction to you. Kindly fill this form sincearly.
          </p>
        </div>
      </div>
      <div className="courseContainer2">
        {courseTeacher &&
          courseTeacher.length > 0 &&
          courseTeacher.map((course, index) => (
            <div key={index} className="courseCard">
              <div onClick={() => handleToggleAnswer(index)} className="courseCardTop">
                <div className="courseCardTitle">
                  {course?.courseData?.name} {`(${course?.courseData?.code})`}{' '}
                </div>
                <div onClick={() => handleToggleAnswer(index)} className="">
                  <AddCircleOutlineIcon />
                </div>
              </div>
              {answerIndex === index && (
                <div className="courseCardBottom">
                  {course?.faculties.map((faculty, index2) => (
                    <div
                      key={index2}
                      className={`${
                        courseTeacher[index].faculties[index2].submitted ? 'reveiwSubmitted' : ''
                      } facultyCard`}
                    >
                      <div
                        onClick={() => handleToggleReviewTable(index, index2)}
                        className={`facultyCardTop`}
                      >
                        <div className="facultyCardTitle">{faculty?.name}</div>
                        <div onClick={() => handleToggleReviewTable(index, index2)} className="">
                          <FiChevronDown />
                        </div>
                      </div>
                      {reviewTableIndex === `${index}_${index2}` && (
                        <div className="questionContainer">
                          <table className="ratingTable">
                            <tbody>
                              {questions.map((question, index3) => (
                                <tr key={index3}>
                                  <td className="questionPart">{question}</td>
                                  <td className="ratingPart">
                                    <Rating
                                      name="simple-controlled"
                                      onChange={(e) =>
                                        updateRatingValue(index, index2, index3, e.target.value)
                                      }
                                      readOnly={
                                        courseTeacher[index].faculties[index2].submitted
                                          ? true
                                          : false
                                      }
                                      value={
                                        index3 >= 0 && index3 <= 4
                                          ? courseTeacher[index].faculties[index2].ratings.R_A[
                                              index3
                                            ]
                                          : index3 >= 5 && index3 <= 9
                                          ? courseTeacher[index].faculties[index2].ratings.R_B[
                                              index3 - 5
                                            ]
                                          : index3 >= 10 && index3 <= 13
                                          ? courseTeacher[index].faculties[index2].ratings.R_C[
                                              index3 - 10
                                            ]
                                          : 0
                                      }
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>

                          <p
                            className={`${
                              saveCntArray[
                                courseTeacher[index].faculties[index2]?.ratings?.counter
                              ]?.some((value) => value === 0)
                                ? ''
                                : 'remove'
                            } warningNote`}
                          >
                            Please complete all the reviews before submission
                          </p>
                          <div
                            className={`${
                              courseTeacher[index].faculties[index2].submitted ? 'done' : ''
                            }`}
                          >
                            <button
                              disabled={saveCntArray[
                                courseTeacher[index].faculties[index2]?.ratings?.counter
                              ]?.some((value) => value === 0)}
                              onClick={() => handleSave(index, index2)}
                              className={`${
                                saveCntArray[
                                  courseTeacher[index].faculties[index2]?.ratings?.counter
                                ]?.some((value) => value === 0)
                                  ? 'disabledBtn'
                                  : ''
                              } primary finalSub`}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
      </div>
      <div className="bottomCont">
        <p className={`${totalSaveCount === totalTeacherCount ? 'remove' : ''} warningNote`}>
          Please complete all the reviews before submission
        </p>
        <button
          onClick={() => finalReviewSubmit()}
          disabled={totalSaveCount === totalTeacherCount ? false : true}
          className={`${
            totalSaveCount === totalTeacherCount ? '' : 'disabledBtn'
          } primary finalSub`}
        >
          Submit
        </button>
      </div>
    </>
  );
};

export default StudentFeedback;
