import * as React from 'react';
import '../css/Component/FeeTable.css';
import apis from '../apis';

const FeeTable = (props) => {
  const [feeStructure, setFeeStructure] = React.useState({});
  const [hostelStatus, setHostelStatus] = React.useState();
  const [totalFee, setTotalFee] = React.useState(0);

  const allotHostelFee = () => {
    let hostelFee = 0,
      messFee = 0;
    if (hostelStatus?.hostel === 'H1') {
      hostelFee += Number(feeStructure?.h1fee);
      messFee += Number(feeStructure?.messfee);
    } else if (hostelStatus?.hostel === 'H9') {
      hostelFee += Number(feeStructure?.h9fee);
      messFee += Number(feeStructure?.messfee);
    } else if (hostelStatus?.hostel === 'H7') {
      hostelFee += Number(feeStructure?.h7fee);
      messFee += Number(feeStructure?.messfee);
    } else if (hostelStatus?.hostel === 'NBH') {
      hostelFee += Number(feeStructure?.nbhfee);
      messFee += Number(feeStructure?.messfee);
    } else if (hostelStatus?.hostel === 'GH') {
      hostelFee += Number(feeStructure?.ghfee);
      messFee += Number(feeStructure?.messfee);
    } else if (hostelStatus?.hostel === 'KBH') {
      hostelFee += Number(feeStructure?.kbhfee);
      messFee += Number(feeStructure?.messfee);
    } else if (hostelStatus?.hostel === 'KGH') {
      hostelFee += Number(feeStructure?.kghfee);
      messFee += Number(feeStructure?.messfee);
    }
    return { hostelFee, messFee };
  };

  React.useEffect(() => {
    const getHostelStatus = async () => {
      await apis
        .hostelStatusAdmin(props?.student?.roll, props?.student?.semester)
        .then((data) => {
          console.log(data);
          setHostelStatus(data);
        })
        .catch((err) => {
          alert(err.message);
        });
    };
    const getFeeStructure = async () => {
      props?.student?.type &&
        (await apis
          .feeStructure(
            props?.student?.year,
            props?.student?.course,
            props?.student?.semester,
            props?.student?.batch,
            props?.student?.type
          )
          .then((data) => {
            setFeeStructure(data);
            setTotalFee(
              Number(data.tuitionfee) +
                Number(data.otherfee) +
                Number(data.convocationfee) +
                Number(data.cautionmoney) +
                Number(data.admissionfee)
            );
          })
          .catch((err) => {
            alert(err.message);
          }));
    };
    getHostelStatus();
    getFeeStructure();
  }, [props]);

  // React.useEffect(() => {

  // }, [props.student]);

  return (
    <>
      <div className="fee-section-table">
        <div className="fee-types">
          <div className="fee-type">
            <div className="fee-type-name">
              <h4>Reccurning Fee</h4>
            </div>
            <div className="fee-type-contents">
              <div className="fee-type-content">
                <div className="fee-content-count">
                  <h4>1.</h4>
                </div>
                <div className="fee-content-name">
                  <h4>Tution Fee</h4>
                </div>
                <div className="fee-content-price">
                  <h4>
                    <span>₹</span> {feeStructure.tuitionfee}
                  </h4>
                </div>
              </div>
              <div className="fee-type-content">
                <div className="fee-content-count">
                  <h4>2.</h4>
                </div>
                <div className="fee-content-name">
                  <h4>Other Institute Fee</h4>
                </div>
                <div className="fee-content-price">
                  <h4>
                    <span>₹</span> {feeStructure.otherfee}
                  </h4>
                </div>
              </div>
              <div className="fee-type-content">
                <div className="fee-content-count">
                  <h4>3.</h4>
                </div>
                <div className="fee-content-name">
                  <h4>Convocation Fee</h4>
                </div>
                <div className="fee-content-price">
                  <h4>
                    <span>₹</span> {feeStructure.convocationfee}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="fee-type">
            <div className="fee-type-name">
              <h4>One Time Fee</h4>
            </div>
            <div className="fee-type-contents">
              <div className="fee-type-content">
                <div className="fee-content-count">
                  <h4>3.</h4>
                </div>
                <div className="fee-content-name">
                  <h4>Admission Related Fee</h4>
                </div>
                <div className="fee-content-price">
                  <h4>
                    <span>₹</span> {feeStructure.admissionfee}
                  </h4>
                </div>
              </div>
              <div className="fee-type-content">
                <div className="content-count">
                  <h4>4.</h4>
                </div>
                <div className="fee-content-name">
                  <h4>Caution Money</h4>
                </div>
                <div className="fee-content-price">
                  <h4>
                    <span>₹</span> {feeStructure.cautionmoney}
                  </h4>
                </div>
              </div>
            </div>
          </div> */}
          <div className="fee-type">
            <div className="fee-type-name">
              <h4>Other Fee</h4>
            </div>
            <div className="fee-type-contents">
              <div className="fee-type-content">
                <div className="fee-content-count">
                  <h4>3.</h4>
                </div>
                <div className="fee-content-name">
                  <h4>Hostel Fee</h4>
                </div>
                <div className="fee-content-price">
                  <h4>
                    <span>₹</span> {Number(allotHostelFee().hostelFee)}
                  </h4>
                </div>
              </div>
              <div className="fee-type-content">
                <div className="fee-content-count">
                  <h4>4.</h4>
                </div>
                <div className="fee-content-name">
                  <h4>Mess Fee</h4>
                </div>
                <div className="fee-content-price">
                  <h4>
                    <span>₹</span> {Number(allotHostelFee().messFee)}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="fee-type">
            <div className="fee-type-name fee-total-title">
              <h4>Total Fee</h4>
            </div>
            <div className="fee-type-contents fee-type-total">
              <div className="fee-type-content">
                <div className="fee-content-price fee-total-value">
                  <h4>
                    <span>₹ </span>
                    {totalFee +
                      Number(allotHostelFee().hostelFee) +
                      Number(allotHostelFee().messFee)}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeeTable;
